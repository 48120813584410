import { BoothXError } from "../models/BoothXError";
import { IDocumentView, IGetDocumentDownloadUrlResponse } from "../models/Document";
import { BACKEND_URL_V1, deletee, get, post } from "../utils/Api";

interface IGetDocumentsResponse {
    documents: IDocumentView[];
}

interface ICreateDocumentResponse {
    document: IDocumentView;
}

export const getDocuments = async (): Promise<IDocumentView[] | BoothXError> => {
    try {
        const response = await get<IGetDocumentsResponse>(`${BACKEND_URL_V1}/documents`);
        if (response.parsedBody?.documents) {
            return response.parsedBody.documents;
        }

        const errorMessage = response.serverError?.error.message ?? "Event collaterals / documents could not be retrieved";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}

export const getDocumentDownloadUrl = async (documentId: string): Promise<string | BoothXError> => {
    try {
        const response = await get<IGetDocumentDownloadUrlResponse>(`${BACKEND_URL_V1}/documents/${documentId}/downloadUrl`);
        if (response.parsedBody?.url) {
            return response.parsedBody.url;
        }

        const errorMessage = response.serverError?.error.message ?? "Event collaterals / documents could not be retrieved";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}

export const deleteDocument = async (documentId: string): Promise<null | BoothXError> => {
    try {
        const response = await deletee(`${BACKEND_URL_V1}/documents/${documentId}`);
        if (Math.floor(response.status / 100) === 2) {
            return null;
        }

        const errorMessage = response.serverError?.error.message ?? "Collateral / document could not be deleted";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}

export const uploadDocument = async (file: File): Promise<IDocumentView | BoothXError> => {
    try {
        const formData = new FormData();
        formData.append("file", file);
        const response = await post<ICreateDocumentResponse>(`${BACKEND_URL_V1}/documents`, formData, { method: "post", body: formData });
        if (response.parsedBody) {
            return response.parsedBody.document;
        }

        const errorMessage = response.serverError?.error.message ?? "Collateral / document could not be uploaded";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}
