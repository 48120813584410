import React, { useContext, useState } from 'react';
import { Typography, Box, TextField, Button, Container, Grid } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Loading } from '../loading/Loading';
import { BoothXError } from '../../models/BoothXError';
import { ToastContext } from '../../contexts/ToastContext';
import { IAddUserRequest, IUserProfileView } from '../../models/User';
import { updateSelf } from '../../api/UsersApi';


interface IProps {
    userProfile: IUserProfileView
}

const UpdateUserForm: React.FC<IProps> = (props) => {
    const { userProfile } = props;

    const [isEditable, setIsEditable] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { showToast } = useContext(ToastContext);

    const { register, handleSubmit, formState: { errors }, clearErrors, setValue } = useForm<IAddUserRequest>();

    const onSubmit: SubmitHandler<IAddUserRequest> = async (userData) => {
        setIsEditable(false);

        setIsLoading(true);
        const response = await updateSelf(userData);
        setIsLoading(false);

        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            setIsLoading(false);
            return;
        }

        showToast("Successfully updated user details", "success");
    };

    const handleSaveClick = () => {
        handleSubmit(onSubmit)();
    }

    const handleEditClick = () => {
        setIsEditable(true);
    }

    return (
        <Container
            component="main"
            maxWidth="sm"
        >
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Grid item xl={6} md={6} lg={6} sm={12} xs={12} sx={{ padding: 2 }}>
                    <Box component="div"
                        sx={{
                            mb: 3,
                            display: "flex",
                            justifyContent: "space-between",
                        }} >
                        <Typography
                            component="h1"
                            variant="h6"
                            sx={{
                                textAlign: "left",
                                fontWeight: "600",
                            }}
                        >
                            Profile Details
                        </Typography>

                        <Button
                            type="button"
                            variant={isEditable ? "contained" : "outlined"}
                            sx={{
                                width: "6rem"
                            }}
                            disabled={isLoading}
                            onClick={isEditable ? handleSaveClick : handleEditClick}
                        >
                            <Loading isLoading={isLoading} text={isEditable ? "Save" : "Edit"} />
                        </Button>
                    </Box>

                    <Box>
                        <Typography
                            component="div"
                            sx={{
                                textAlign: "left",
                                fontWeight: "600",
                                mb: 1
                            }}
                        >
                            First Name
                        </Typography>
                        <TextField
                            {...register("firstName", { required: true })}
                            margin="none"
                            fullWidth
                            defaultValue={userProfile.firstName}
                            autoComplete="off"
                            size="small"
                            InputProps={{
                                readOnly: isEditable ? false : true,
                            }}
                            sx={{
                                margin: "none",
                                padding: "none",
                                mb: 2,
                                backgroundColor: isEditable ? '#fff' : undefined
                            }}
                            error={errors.firstName === undefined ? false : true}
                            helperText={errors.firstName === undefined ? "" : "First name is required"}
                            onChange={(event) => {
                                clearErrors("firstName");
                                setValue("firstName", event.target.value);
                            }}
                        />
                        <Typography
                            component="div"
                            sx={{
                                textAlign: "left",
                                fontWeight: "600",
                                mb: 1
                            }}
                        >
                            Last Name
                        </Typography>
                        <TextField
                            {...register("lastName", { required: true })}
                            variant="outlined"
                            margin="none"
                            fullWidth
                            defaultValue={userProfile.lastName}
                            autoComplete="off"
                            size="small"
                            InputProps={{
                                readOnly: isEditable ? false : true,
                            }}
                            sx={{
                                margin: "none",
                                padding: "none",
                                mb: 2,
                                backgroundColor: isEditable ? '#fff' : undefined
                            }}
                            error={errors.lastName === undefined ? false : true}
                            helperText={errors.lastName === undefined ? "" : "Last name is required"}
                            onChange={(event) => {
                                clearErrors("lastName");
                                setValue("lastName", event.target.value);
                            }}
                        />
                        <Typography
                            component="div"
                            sx={{
                                textAlign: "left",
                                fontWeight: "600",
                                mb: 1
                            }}
                        >
                            Email Id
                        </Typography>
                        <TextField
                            variant="outlined"
                            margin="none"
                            fullWidth
                            value={userProfile.email}
                            autoComplete="off"
                            size="small"
                            InputProps={{
                                readOnly: true
                            }}
                            sx={{
                                margin: "none",
                                padding: "none",
                                mb: 2
                            }}
                        />
                    </Box>
                </Grid>
            </Box>
        </Container>
    );
}

export default UpdateUserForm;
