import { useCallback, useContext, useState } from "react";
import GenericDialog from "../genericDialog/GenericDialog";
import { ToastContext } from "../../contexts/ToastContext";
import { BoothXError } from "../../models/BoothXError";
import { IDocumentView } from "../../models/Document";
import { deleteDocument } from "../../api/DocumentApis";

interface IProps {
    document: IDocumentView;
    onClose: (didDeleteDocument: boolean) => void;
}

const DeleteDocumentConfirmationDialog: React.FC<IProps> = (props) => {
    const { document, onClose } = props;
    const [isLoading, setIsLoading] = useState(false);
    const { showToast } = useContext(ToastContext);

    const onDeleteDocument = useCallback(async () => {
        setIsLoading(true);
        const response = await deleteDocument(document.id);
        setIsLoading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        showToast("Collateral / Document deleted successfully", "success");
        onClose(true);
    }, [showToast, onClose, document.id]);

    return (
        <GenericDialog
            isOpen={true}
            onClose={() => {
                onClose(false);
            }}
            title={"Delete Collateral / Document"}
            primaryButtonText={"Delete"}
            primaryButtonAction={onDeleteDocument}
            primaryButtonLoading={isLoading}
        >
            Are you sure you want to delete {document.name}?
        </GenericDialog>
    )
}

export default DeleteDocumentConfirmationDialog;
