import { Dashboard, Logout, Event, GroupsOutlined, ArticleOutlined, AttachEmailOutlined, AccountBoxOutlined, WalletOutlined } from "@mui/icons-material";
import { Capability } from "./Capability";

export interface IPage {
    icon?: React.ElementType;
    title?: string;
    href: string;
    children?: IPage[];
    capabilities: Capability[];
}

export const allPages: IPage[] = [
    {
        icon: Dashboard,
        title: "Dashboard",
        href: "/",
        capabilities: []
    },
    {
        icon: Event,
        title: "Events",
        href: "/events",
        capabilities: [
            Capability.ALL,
            Capability.MANAGE_EVENT,
            Capability.MANAGE_ALL_EVENTS,
            Capability.VIEW_EVENT,
            Capability.MANAGE_LEADS,
            Capability.VIEW_LEADS,
            Capability.MANAGE_EXPENSES,
            Capability.VIEW_EXPENSES
        ],
        children: [
            {
                href: "/events/:eventId",
                capabilities: [] // children pages do not need any capabilites to be viewed
            },
            {
                href: "/events/:eventId/edit",
                capabilities: [] // children pages do not need any capabilites to be viewed
            },
            {
                href: "/events/create",
                capabilities: [] // children pages do not need any capabilites to be viewed
            }
        ]
    },
    {
        icon: ArticleOutlined,
        title: "Collaterals",
        href: "/collaterals",
        capabilities: [
            Capability.ALL,
            Capability.MANAGE_DOCUMENTS,
            Capability.VIEW_DOCUMENTS
        ]
    },
    {
        icon: AttachEmailOutlined,
        title: "Emails",
        href: "/emails",
        capabilities: [
            Capability.ALL,
            Capability.MANAGE_EMAIL_TEMPLATES,
            Capability.VIEW_EMAIL_TEMPLATES
        ]
    },
    {
        icon: GroupsOutlined,
        title: "Users",
        href: "/users",
        capabilities: [
            Capability.ALL,
            Capability.VIEW_USERS,
            Capability.USER_MANAGEMENT
        ]
    },
    {
        icon: AccountBoxOutlined,
        title: "Profile",
        href: "/profile",
        capabilities: []
    },
    {
        icon: WalletOutlined,
        title: "Billing",
        href: "/billing",
        capabilities: []
    },
    {
        icon: Logout,
        title: "Logout",
        href: "/logout",
        capabilities: []
    }
];

export function getPagesForCapabilites(capabilities: Capability[]) {
    return allPages.filter((page) => page.capabilities.length === 0 || page.capabilities.some((capability) => capabilities.includes(capability)));
}
