import { Chip } from "@mui/material";
import { EventObjective, IEventView } from "../models/Event";
import { DateTime } from "luxon";

export function getDisplayAddress(event: IEventView) {
    return `${event.address ? `${event.address},` : ""} ${event.city}, ${event.state}, ${event.country}`;
}

export const getStatusChip = (event: IEventView) => {
    const chipStyle = { ml: 1, width: 100, fontWeight: "bold" };
    if (event.isDraftEvent) {
        return <Chip sx={chipStyle} label="Draft" color="info" variant='outlined' />;
    }
    const now = DateTime.now();
    const startDate = DateTime.fromSeconds(event.startDateInUTC);
    const endDate = DateTime.fromSeconds(event.endDateInUTC);

    if (now.diff(startDate).as("milliseconds") > 0 && now.diff(endDate).as("milliseconds") <= 0) {
        return <Chip sx={{
            ...chipStyle,
            backgroundColor: "#27A71B"
        }} label="Active" color="success" />;
    } else if (startDate.diff(now).as("milliseconds") > 0) {
        return <Chip sx={chipStyle} label="Upcoming" color="warning" />;
    }
};

export function displayNameForObjective(objective: EventObjective) {
    switch (objective) {
        case EventObjective.LEAD_GENERATION: return "Lead Generation";
        case EventObjective.BRAND_AWARENESS: return "Brand Awareness"
        case EventObjective.PRODUCT_LAUNCH: return "Product Launch";
        case EventObjective.NETWORKING: return "Networking";
        case EventObjective.PARTNERSHIPS: return "Partnerships";
        case EventObjective.OTHER: return "Other";
    }
}
