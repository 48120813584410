import { useCallback, useContext, useState } from "react";
import GenericDialog from "../genericDialog/GenericDialog";
import { ToastContext } from "../../contexts/ToastContext";
import { BoothXError } from "../../models/BoothXError";
import { IExpenseView } from "../../models/Event";
import { deleteExpense } from "../../api/EventsApi";
import { displayAmountWithCurrency } from "../../utils/Utils";

interface IProps {
    expense: IExpenseView;
    onClose: (didDeleteDocument: boolean) => void;
}

const DeleteExpenseConfirmationDialog: React.FC<IProps> = (props) => {
    const { expense, onClose } = props;
    const [isLoading, setIsLoading] = useState(false);
    const { showToast } = useContext(ToastContext);

    const handleDeleteExpense = useCallback(async () => {
        setIsLoading(true);
        const response = await deleteExpense(expense.eventId, expense.id);
        setIsLoading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            onClose(false);
            return;
        }

        showToast("Expense has been deleted successfully", "success")
        onClose(true);
    }, [showToast, onClose, expense.id, expense.eventId]);

    return (
        <GenericDialog
            isOpen={true}
            onClose={() => {
                onClose(false);
            }}
            title={"Delete Expense"}
            primaryButtonText={"Delete"}
            primaryButtonAction={handleDeleteExpense}
            primaryButtonLoading={isLoading}
        >
            {`Are you sure you want to delete expense of amount ${displayAmountWithCurrency(expense.amount, expense.currency)}?`}
        </GenericDialog>
    )
}

export default DeleteExpenseConfirmationDialog;
