import { Grid } from "@mui/material";
import React from "react";
import PricingPlan from "../../components/pricingPlan/PricingPlan";

const CreateEventPage: React.FC = React.memo(() => {
    return (
        <Grid
            container
            spacing={0}
            padding={0}
            sx={{ padding: 3 }}
        >
            <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                <PricingPlan />
            </Grid>
        </Grid>
    );
});

export default CreateEventPage;
