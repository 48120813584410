import { Environment } from "../Environment";
import stageConfig from "./stage.json";
import prodConfig from "./prod.json";
import emailVariablesJson from "./emailVariables.json";

export function jsonConfig(environment: Environment) {
    switch (environment) {
        case Environment.DEV:
        case Environment.STAGE:
        case Environment.TEST:
            return stageConfig;

        case Environment.PROD:
            return prodConfig;

        default:
            throw new Error("Invalid Environment " + environment);
    }
}

export function emailTemplateConfig() {
    return emailVariablesJson;
}
