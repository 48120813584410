import { DateTime } from "luxon";
import { BoothXError } from "../models/BoothXError";
import { EventObjective, IAddExpenseRequest, IAddTaskRequest, IEventView, ILeadView, ITaskView, IUpdateTaskRequest, TaskStatus } from "../models/Event";
import { IExpenseView } from "../models/Event";
import { BACKEND_URL_V1, deletee, get, post } from "../utils/Api";
import { downloadFile } from "../utils/FileUtils";
import { IGetDocumentDownloadUrlResponse } from "../models/Document";

export interface IEventDocument {
    documentId: string;
    isSelectedByDefault: boolean;
}

export interface ICreateEventRequest {
    name: string;
    description: string;
    startDateInUTC: DateTime | null;
    endDateInUTC: DateTime | null;
    address?: string;
    city: string;
    state: string;
    pincode?: string;
    country: string;
    currencies: string[];
    boothRepUserIds: string[];
    applicableDocuments?: IEventDocument[];
    emailTemplateId?: string;
    boothNo?: string;
    objective?: EventObjective;
    giftVoucherCatalogIds?: string[];
}

interface IGetEventsResponse {
    events: IEventView[];
}

interface IGetEventResponse {
    event: IEventView;
}

interface IGetLeadsResponse {
    leads: ILeadView[];
    leadsExceedingPlan: ILeadView[];
}

interface IGetExpensesResponse {
    expenses: IExpenseView[];
    expensesExceedingPlan: IExpenseView[];
}

interface IGetTasksResponse {
    eventTasks: ITaskView[];
}

interface IGetExpenseAttachementUrlResponse {
    url: string;
}

interface IAddExpenseResponse {
    expense: IExpenseView;
}

interface IAddTaskResponse {
    taskView: ITaskView;
}

export interface IGetEventInsightsResponse {
    datesToEventInsightsMap: IDatesToEventInsightsMap,
    expensesPerCategory?: Map<string, number>;
    isSingleDayEvent: boolean,
    lastUpdated: number;
    homeCurrency: string;
}

export interface IDatesToEventInsightsMap {
    [date: string]: IEventInsightsView,
}

export interface IEventInsightsView {
    numberOfLeads: number,
    totalExpenses: number,
    duplicateLeadsCount: number
}

export const getEvents = async (): Promise<IEventView[] | BoothXError> => {
    try {
        const response = await get<IGetEventsResponse>(`${BACKEND_URL_V1}/events`);
        if (response.parsedBody?.events) {
            return response.parsedBody.events;
        }

        const errorMessage = response.serverError?.error.message ?? "Events could not be retrieved";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
};

export const getEvent = async (eventId: String): Promise<IEventView | BoothXError> => {
    try {
        const response = await get<IGetEventResponse>(`${BACKEND_URL_V1}/events/${eventId}`);
        if (response.parsedBody?.event) {
            return response.parsedBody.event;
        }

        const errorMessage = response.serverError?.error.message ?? "Events could not be retrieved";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
};


export const getLeads = async (eventId: string): Promise<IGetLeadsResponse | BoothXError> => {
    try {
        const response = await get<IGetLeadsResponse>(`${BACKEND_URL_V1}/events/${eventId}/leads`);
        if (response.parsedBody) {
            return response.parsedBody;
        }

        const errorMessage = response.serverError?.error.message ?? "Leads could not be retrieved";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
};

export const getExpenses = async (eventId: string): Promise<IGetExpensesResponse | BoothXError> => {
    try {
        const response = await get<IGetExpensesResponse>(`${BACKEND_URL_V1}/events/${eventId}/expenses`);
        if (response.parsedBody) {
            return response.parsedBody;
        }

        const errorMessage = response.serverError?.error.message ?? "Expenses could not be retrieved";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
};

export const getExpensesReport = async (eventId: string): Promise<BoothXError | null> => {
    try {
        const response = await get(`${BACKEND_URL_V1}/events/${eventId}/expensesReport`, false);

        if (Math.floor(response.status / 100) === 2) {
            downloadFile(
                response.headers,
                await response.blob(),
                "Event_Expenses_Report.xlsx"
            );
            return null;
        }

        const errorMessage = response.serverError?.error.message ?? "Expenses report could not be downloaded";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
};

export const getExpenseAttachementUrl = async (eventId: string, expenseId: string): Promise<string | BoothXError> => {
    try {
        const response = await get<IGetExpenseAttachementUrlResponse>(`${BACKEND_URL_V1}/events/${eventId}/expenses/${expenseId}/attachment`);
        if (response.parsedBody?.url) {
            return response.parsedBody.url;
        }

        const errorMessage = response.serverError?.error.message ?? "Expense attachment could not be retrieved";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}

export const getLeadsReport = async (eventId: string): Promise<BoothXError | null> => {
    try {
        const response = await get(`${BACKEND_URL_V1}/events/${eventId}/leadsReport`, false);

        if (Math.floor(response.status / 100) === 2) {
            downloadFile(
                response.headers,
                await response.blob(),
                "Event_Leads_Report.xlsx"
            );
            return null;
        }

        const errorMessage = response.serverError?.error.message ?? "Leads report could not be downloaded";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
};

export const createEvent = async (event: ICreateEventRequest): Promise<BoothXError | null> => {
    try {
        const response = await post(`${BACKEND_URL_V1}/events`, event);
        if (Math.floor(response.status / 100) === 2) {
            return null;
        }

        const errorMessage = response.serverError?.error.message ?? "Event could not be created";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}

export const updateEvent = async (eventId: string, event: ICreateEventRequest): Promise<BoothXError | null> => {
    try {
        const response = await post(`${BACKEND_URL_V1}/events/${eventId}`, event);
        if (Math.floor(response.status / 100) === 2) {
            return null;
        }

        const errorMessage = response.serverError?.error.message ?? "Event could not be updated";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}

export const addExpense = async (eventId: string, expense: IAddExpenseRequest): Promise<BoothXError | IExpenseView> => {
    try {
        const response = await post<IAddExpenseResponse>(`${BACKEND_URL_V1}/events/${eventId}/expenses`, expense);
        if (response.parsedBody) {
            return response.parsedBody.expense;
        }

        const errorMessage = response.serverError?.error.message ?? "Expense could not be added";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}

export const deleteExpense = async (eventId: string, expenseId: string): Promise<BoothXError | null> => {
    try {
        const response = await deletee(`${BACKEND_URL_V1}/events/${eventId}/expenses/${expenseId}`);
        if (Math.floor(response.status / 100) === 2) {
            return null;
        }

        const errorMessage = response.serverError?.error.message ?? "Expense could not be deleted";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}

export const getTasks = async (eventId: string): Promise<ITaskView[] | BoothXError> => {
    try {
        const response = await get<IGetTasksResponse>(`${BACKEND_URL_V1}/events/${eventId}/tasks`);
        if (response.parsedBody) {
            return response.parsedBody.eventTasks;
        }

        const errorMessage = response.serverError?.error.message ?? "Tasks could not be retrieved";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}

export const addTask = async (eventId: string, task: IAddTaskRequest, file?: File): Promise<BoothXError | ITaskView> => {
    try {
        const formData = new FormData();
        if (file) {
            formData.append("uploadedFile", file);
        }

        Object.entries(task).forEach(([key, value]) => formData.append(key, value));
        const response = await post<IAddTaskResponse>(`${BACKEND_URL_V1}/events/${eventId}/tasks`, null, { method: "post", body: formData });

        if (response.parsedBody) {
            return response.parsedBody.taskView;
        }

        const errorMessage = response.serverError?.error.message ?? "Task could not be added";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}

export const updateTask = async (eventId: string, taskId: string, request: IUpdateTaskRequest, file?: File): Promise<BoothXError | ITaskView> => {
    try {
        const formData = new FormData();
        if (file) {
            formData.append("uploadedFile", file);
        }

        Object.entries(request).forEach(([key, value]) => formData.append(key, value));
        const response = await post<IAddTaskResponse>(`${BACKEND_URL_V1}/events/${eventId}/tasks/${taskId}`, null, { method: "post", body: formData });
        if (response.parsedBody) {
            return response.parsedBody.taskView;
        }

        const errorMessage = response.serverError?.error.message ?? "Task could not be update";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}

export const getEventTaskDocumentUrl = async (eventId: string, taskId: string): Promise<string | BoothXError> => {
    try {
        const response = await get<IGetDocumentDownloadUrlResponse>(`${BACKEND_URL_V1}/events/${eventId}/tasks/${taskId}/downloadUrl`);
        if (response.parsedBody?.url) {
            return response.parsedBody.url;
        }

        const errorMessage = response.serverError?.error.message ?? "Event task document could not be retrieved";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}

export const updateTaskStatus = async (eventId: string, taskId: string, status: TaskStatus): Promise<null | BoothXError> => {
    try {
        const response = await post(`${BACKEND_URL_V1}/events/${eventId}/tasks/${taskId}/status/${status}`);
        if (response.status === 200) {
            return null;
        }

        const errorMessage = response.serverError?.error.message ?? "Task could not be updated";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
};

export const deleteTask = async (eventId: string, taskId: string): Promise<BoothXError | null> => {
    try {
        const response = await deletee(`${BACKEND_URL_V1}/events/${eventId}/tasks/${taskId}`);
        if (Math.floor(response.status / 100) === 2) {
            return null;
        }

        const errorMessage = response.serverError?.error.message ?? "Task could not be deleted";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}

export const getEventInsights = async (eventId: string): Promise<BoothXError | IGetEventInsightsResponse> => {
    try {
        const response = await get<IGetEventInsightsResponse>(`${BACKEND_URL_V1}/events/${eventId}/insights`);
        if (response.parsedBody) {
            return response.parsedBody;
        }
        const errorMessage = response.serverError?.error.message ?? "Insights could not be retrieved";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}
