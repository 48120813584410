import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useTheme, Breakpoint } from "@mui/material";
import { ReactNode } from "react";
import { Loading } from "../loading/Loading";
import useMediaQuery from '@mui/material/useMediaQuery';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    headerBackgroundColor?: string;
    children: string | ReactNode;
    primaryButtonText: string;
    primaryButtonAction: () => void;
    primaryButtonLoading?: boolean;
    secondaryButtonText?: string;
    primaryButtonDisabled?: boolean;
    width?: Breakpoint;
}

const GenericDialog: React.FC<IProps> = (props) => {
    const {
        isOpen,
        onClose,
        title,
        primaryButtonAction,
        children,
        primaryButtonText,
        primaryButtonLoading = false,
        primaryButtonDisabled = false,
        secondaryButtonText,
        width
    } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Dialog
            open={isOpen}
            fullWidth={fullScreen || width !== undefined}
            maxWidth={width}
            onClose={() => {
                if (!primaryButtonLoading) {
                    onClose();
                }
            }}>
            <DialogTitle sx={{ textAlign: "center" }}>{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    disabled={primaryButtonLoading}
                    sx={{ margin: 1 }}
                >
                    {secondaryButtonText ?? "Cancel"}
                </Button>
                <Button
                    variant="contained"
                    onClick={primaryButtonAction}
                    disabled={primaryButtonLoading || primaryButtonDisabled}
                    sx={{ minWidth: "100px", margin: 1 }}
                >
                    <Loading isLoading={primaryButtonLoading} text={primaryButtonText} />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default GenericDialog;
