import { ThemeProvider, createTheme } from "@mui/material";
import { themeConfig } from "../configs/ConfigProvider";
import React, { createContext } from "react";

const config = themeConfig();

const theme = createTheme({
  palette: {
    primary: {
      main: config.primaryColor,
    },
  },
});

export const BoothXThemeContext = createContext<ReturnType<typeof themeConfig>>(
  undefined!
);

export const BoothXThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <BoothXThemeContext.Provider value={config}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </BoothXThemeContext.Provider>
  );
};
