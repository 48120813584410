import React, { useContext, useEffect, useState } from 'react';
import { Typography, Box, TextField, Button, Container, Grid } from '@mui/material';
import styles from './CompanySignupForm.module.css';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Loading } from '../loading/Loading';
import { ICompanySignupRequest, signupCompany } from '../../api/Signup';
import { BoothXError } from '../../models/BoothXError';
import { ToastContext } from '../../contexts/ToastContext';
import { getSettings } from '../../api/UsersApi';
import { useNavigate } from 'react-router-dom';
import { Auth } from '../../utils/Auth';

const classes = { root: styles.input };

const CompanySignupForm: React.FC = React.memo(() => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    const { showToast } = useContext(ToastContext);
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, clearErrors, setValue } = useForm<ICompanySignupRequest>();

    const onSubmit: SubmitHandler<ICompanySignupRequest> = async (companyData) => {
        setIsLoading(true);
        const response = await signupCompany(companyData);
        setIsLoading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        setIsLoading(true);
        await Auth.getInstance().refreshToken();
        const getSettingsResponse = await getSettings();
        setIsLoading(false);
        if (getSettingsResponse instanceof BoothXError) {
            showToast(getSettingsResponse.getErrorMessage(), "error");
            return;
        }

        setIsLoggedIn(true);
    };

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/');
        }
    }, [isLoggedIn, navigate]);

    return (
        <Container
            component="main"
            maxWidth="md">
            <Typography
                component="h1"
                variant="h5"
                sx={{ mb: 3 }}
            >
                Let's set up your company
            </Typography>
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                    <Grid item xl={6} md={6} lg={6} sm={12} xs={12}>
                        <TextField
                            {...register("companyName", { required: true })}
                            variant="filled"
                            InputProps={{
                                disableUnderline: true,
                                classes: classes
                            }}
                            margin="dense"
                            fullWidth
                            label="Company name"
                            name="companyName"
                            autoFocus
                            error={errors.companyName === undefined ? false : true}
                            helperText={errors.companyName === undefined ? "" : "Company name is required"}
                            onChange={(event) => {
                                clearErrors("companyName");
                                setValue("companyName", event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xl={6} md={6} lg={6} sm={12} xs={12}>
                        <TextField
                            {...register("companyWebsite", { required: true })}
                            variant="filled"
                            InputProps={{
                                disableUnderline: true,
                                classes: classes
                            }}
                            margin="dense"
                            fullWidth
                            label="Website"
                            name="companyWebsite"
                            error={errors.companyWebsite === undefined ? false : true}
                            helperText={errors.companyWebsite === undefined ? "" : "Website is required"}
                            onChange={(event) => {
                                clearErrors("companyWebsite");
                                setValue("companyWebsite", event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xl={6} md={6} lg={6} sm={12} xs={12}>
                        <TextField
                            {...register("companyAddress", { required: true })}
                            variant="filled"
                            InputProps={{
                                disableUnderline: true,
                                classes: classes
                            }}
                            margin="dense"
                            fullWidth
                            label="Address"
                            name="companyAddress"
                            error={errors.companyAddress === undefined ? false : true}
                            helperText={errors.companyAddress === undefined ? "" : "Address is required"}
                            onChange={(event) => {
                                clearErrors("companyAddress");
                                setValue("companyAddress", event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xl={6} md={6} lg={6} sm={12} xs={12}>
                        <TextField
                            {...register("companyPincode", { required: true })}
                            variant="filled"
                            InputProps={{
                                disableUnderline: true,
                                classes: classes
                            }}
                            margin="dense"
                            fullWidth
                            label="Pincode"
                            name="companyPincode"
                            error={errors.companyPincode === undefined ? false : true}
                            helperText={errors.companyPincode === undefined ? "" : "Pincode is required"}
                            onChange={(event) => {
                                clearErrors("companyPincode");
                                setValue("companyPincode", event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xl={6} md={6} lg={6} sm={12} xs={12}>
                        <TextField
                            {...register("companyState", { required: true })}
                            variant="filled"
                            InputProps={{
                                disableUnderline: true,
                                classes: classes
                            }}
                            margin="dense"
                            fullWidth
                            label="State"
                            name="companyState"
                            error={errors.companyState === undefined ? false : true}
                            helperText={errors.companyState === undefined ? "" : "State is required"}
                            onChange={(event) => {
                                clearErrors("companyState");
                                setValue("companyState", event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xl={6} md={6} lg={6} sm={12} xs={12}>
                        <TextField
                            {...register("companyCountry", { required: true })}
                            variant="filled"
                            InputProps={{
                                disableUnderline: true,
                                classes: classes
                            }}
                            margin="dense"
                            fullWidth
                            label="Country"
                            name="companyCountry"
                            error={errors.companyCountry === undefined ? false : true}
                            helperText={errors.companyCountry === undefined ? "" : "Country is required"}
                            onChange={(event) => {
                                clearErrors("companyCountry");
                                setValue("companyCountry", event.target.value);
                            }}
                        />
                    </Grid>
                </Grid>

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, maxWidth: 300 }}
                    disabled={isLoading}
                >
                    <Loading isLoading={isLoading} text="Next" />
                </Button>
            </Box>
        </Container>
    );
});

export default CompanySignupForm;
