
import { BoothXError } from "../models/BoothXError";
import { IAddUserRequest, IGetSettingsResponse, IUserProfileView } from "../models/User";
import { BACKEND_URL_V1, deletee, get, post } from "../utils/Api";
import { Auth } from "../utils/Auth";

interface IGetUsersResponse {
  users: IUserProfileView[];
}

interface IGetUserResponse {
  user: IUserProfileView;
}

export const getSettings = async (): Promise<IGetSettingsResponse | BoothXError> => {
  try {
    const response = await get<IGetSettingsResponse>(`${BACKEND_URL_V1}/users/me/settings`);
    if (response.parsedBody) {
      // for each get setting call, save the latest in auth session
      Auth.getInstance().saveUserSettings(response.parsedBody);
      return response.parsedBody;
    }

    const errorMessage = response.serverError?.error.message ?? "User settings could not be retrieved";
    return new BoothXError(errorMessage);
  } catch (err) {
    console.log(err);
    return new BoothXError("Something went wrong");
  }
};

export const getUsers = async (): Promise<IUserProfileView[] | BoothXError> => {
  try {
    const response = await get<IGetUsersResponse>(`${BACKEND_URL_V1}/users`);
    if (response.parsedBody?.users) {
      return response.parsedBody.users;
    }

    const errorMessage = response.serverError?.error.message ?? "Users could not be retrieved";
    return new BoothXError(errorMessage);
  } catch (err) {
    console.log(err);
    return new BoothXError("Something went wrong");
  }
}

export const addUser = async (request: IAddUserRequest): Promise<IUserProfileView | BoothXError> => {
  try {
    const response = await post<IGetUserResponse>(`${BACKEND_URL_V1}/users`, request);
    if (response.parsedBody) {
      return response.parsedBody.user;
    }

    const errorMessage = response.serverError?.error.message ?? "User could not be added";
    return new BoothXError(errorMessage);
  } catch (err) {
    console.log(err);
    return new BoothXError("Something went wrong");
  }
}

export const updateUser = async (userId: string, request: IAddUserRequest): Promise<IUserProfileView | BoothXError> => {
  try {
    const response = await post<IGetUserResponse>(`${BACKEND_URL_V1}/users/${userId}`, request);
    if (response.parsedBody) {
      return response.parsedBody.user;
    }

    const errorMessage = response.serverError?.error.message ?? "User could not be updated";
    return new BoothXError(errorMessage);
  } catch (err) {
    console.log(err);
    return new BoothXError("Something went wrong");
  }
}

export const updateSelf = async (request: IAddUserRequest): Promise<IUserProfileView | BoothXError> => {
  try {
    const response = await post<IGetUserResponse>(`${BACKEND_URL_V1}/users/me`, request);
    if (response.parsedBody) {
      return response.parsedBody.user;
    }

    const errorMessage = response.serverError?.error.message ?? "User could not be updated";
    return new BoothXError(errorMessage);
  } catch (err) {
    console.log(err);
    return new BoothXError("Something went wrong");
  }
}

export const deleteUser = async (userId: string): Promise<null | BoothXError> => {
  try {
    const response = await deletee(`${BACKEND_URL_V1}/users/${userId}`);
    if (Math.floor(response.status / 100) === 2) {
      return null;
    }

    const errorMessage = response.serverError?.error.message ?? "User could not be deleted";
    return new BoothXError(errorMessage);
  } catch (err) {
    console.log(err);
    return new BoothXError("Something went wrong");
  }
}
