import { BoothXError } from "../models/BoothXError";
import { IGetGiftVouchersConfigResponse } from "../models/GiftVouchers";
import { BACKEND_URL_V1, get } from "../utils/Api";

export const getGiftVouchersConfig = async (): Promise<BoothXError | IGetGiftVouchersConfigResponse> => {
    try {
        const response = await get<IGetGiftVouchersConfigResponse>(`${BACKEND_URL_V1}/giftVouchersConfig`);
        if (response.parsedBody) {
            return response.parsedBody;
        }

        const errorMessage = response.serverError?.error.message ?? "Gift Vouchers could not be retrieved";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }

}

