import { jsonConfig } from "./configs/ConfigProvider";
import { Environment } from "./Environment";

export class Config {
  private static instance: Config;
  private config: ReturnType<typeof jsonConfig>;
  private environment: Environment;

  private constructor() {
    const reactEnv = process.env.REACT_APP_ENV as "dev" | "test" | "stage" | "prod" | undefined;
    switch (reactEnv) {
      case "dev":
        this.environment = Environment.DEV;
        break;
      case "test":
        this.environment = Environment.TEST;
        break;
      case "stage":
        this.environment = Environment.STAGE;
        break;
      case "prod":
        this.environment = Environment.PROD;
        break;
      default:
        this.environment = Environment.DEV;
        break;
    }

    this.config = jsonConfig(this.environment);
  }

  public static getInstance() {
    if (!Config.instance) {
      Config.instance = new Config();
    }
    return Config.instance;
  }

  public getHost() {
    return this.config.server.host;
  }

  public getFirebaseConfig() {
    return this.config.firebase;
  }

  public themeConfig() {
    return {
      primaryColor: "#000000",
      primaryTextColor: "#ffffff",
      selectedColor: "#d5671a",
      logoDark: "/assets/logo.png",
      logoLight: "/assets/logoLight.png",
      favIcon: "/assets/favicon.ico",
      excelIcon: "/assets/excel.png"
    }
  }

  public isTestEnvironment() {
    return this.environment === Environment.TEST;
  }

  public getSupportedCountries() {
    // Order is important, do not change
    return [
      {
        countryCode: "IN",
        prefix: "+91",
      }
    ];
  }
}
