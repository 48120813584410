import { useState } from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector, Tooltip } from "recharts";
import style from "./GenericPieChart.module.css";
import { IKeyValuePair, chartColors } from "../../models/Chart";

export interface IGenericPieChartProps<T extends IKeyValuePair> {
    data: T[];
    minHeight?: number;
    minWidth?: number;
    colors?: string[];
    pieDisplayLabelFormatter?: (data: T) => string;
    lineDisplayLabelFormatter?: (data: T) => string;
    toolTipLabelFormatter?: (data: T) => string;
    hoverIndex?: number;
    setHoverIndex?: (index?: number) => void;
    toolTipEnabled?: boolean
    legendLabelKey?: string;
    showLegend?: boolean;
}

function GenericPieChart<T extends IKeyValuePair>(props: IGenericPieChartProps<T>) {
    const {
        data,
        hoverIndex,
        colors,
        minHeight = 200,
        minWidth = 200,
        setHoverIndex,
        toolTipLabelFormatter = (data) => data.value,
        pieDisplayLabelFormatter,
        lineDisplayLabelFormatter,
        toolTipEnabled = true,
        showLegend = false,
        legendLabelKey
    } = props;
    const [pieChartActiveIndex, setPieChartActiveIndex] = useState<number>();

    const innerRadius = 30;

    const pieColors = colors ?? chartColors;
    const RADIAN = Math.PI / 180;

    const renderCustomizedLabel = (props: {
        x: number,
        cx: number,
        cy: number,
        midAngle: number,
        innerRadius: number,
        outerRadius: number,
        percent: number,
        index: number,
        key: string,
        [other: string]: any
    }) => {
        const { cx, cy, midAngle, x, outerRadius, innerRadius, percent, payload } = props;
        const radius1 = innerRadius + (outerRadius - innerRadius) * 0.3;
        const xAxis1 = cx + radius1 * Math.cos(-midAngle * RADIAN);
        const yAxis1 = cy + radius1 * Math.sin(-midAngle * RADIAN);

        const radius2 = 25 + innerRadius + (outerRadius - innerRadius);
        const xAxis2 = cx + radius2 * Math.cos(-midAngle * RADIAN);
        const yAxis2 = cy + radius2 * Math.sin(-midAngle * RADIAN);

        return (
            <>
                {percent !== 0 && (
                    <>
                        <text
                            x={xAxis1}
                            y={yAxis1}
                            textAnchor={x > cx ? "start" : "end"}
                            dominantBaseline="central"
                            fill="white"
                        >
                            {pieDisplayLabelFormatter ? pieDisplayLabelFormatter(payload.payload as T) : `${(percent * 100).toFixed(0)}%`}
                        </text>
                        <text
                            x={xAxis2}
                            y={yAxis2}
                            textAnchor={x > cx ? "start" : "end"}
                            dominantBaseline="central"
                        >
                            <tspan fontWeight="bold" fontSize={10}>
                                {lineDisplayLabelFormatter ? lineDisplayLabelFormatter(payload.payload as T) : `${props.key}`}
                            </tspan>
                        </text>
                    </>
                )}
            </>
        );
    };

    const renderActiveShape = (props: any) => {
        const {
            cx,
            cy,
            innerRadius,
            outerRadius,
            startAngle,
            endAngle,
            fill
        } = props;

        return (
            <>
                <g>
                    <Sector
                        cx={cx}
                        cy={cy}
                        innerRadius={innerRadius}
                        outerRadius={outerRadius}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        fill={fill}
                    />
                    <Sector
                        cx={cx}
                        cy={cy}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        innerRadius={outerRadius + 1}
                        outerRadius={outerRadius + 12}
                        fill={fill}
                        opacity={0.3}
                    />
                </g>
            </>
        );
    };

    const CustomTooltip = (data: any) => {
        if (pieChartActiveIndex === undefined && hoverIndex === undefined) {
            return null;
        }

        if (data.active && data.payload) {
            return (
                <div className={style.GenericPieChart__toolTipContainer}>
                    <div
                        className={style.GenericPieChart__toolTipContainerColor}
                        style={{ backgroundColor: data.payload[0].payload.fill }}
                    />

                    {toolTipLabelFormatter(data.payload[0].payload.payload)}
                </div>
            );
        }

        return null;
    };

    return (
        <ResponsiveContainer width="100%" minHeight={minHeight} minWidth={minWidth}>
            <PieChart>
                <Pie
                    isAnimationActive={false}
                    activeIndex={hoverIndex ?? pieChartActiveIndex}
                    activeShape={renderActiveShape}
                    data={data}
                    innerRadius={innerRadius}
                    labelLine={true}
                    paddingAngle={0}
                    label={renderCustomizedLabel}
                    dataKey={"value"}
                    nameKey={legendLabelKey}
                    onMouseEnter={(_, index) => {
                        if (setHoverIndex) {
                            setHoverIndex(index);
                        }

                        setPieChartActiveIndex(index);
                    }}
                    onMouseOut={() => {
                        if (setHoverIndex) {
                            setHoverIndex(undefined);
                        }

                        setPieChartActiveIndex(undefined);
                    }}
                >
                    {data.map((_, index) => <Cell key={`cell-${index}`} fill={pieColors[index % pieColors.length]} />)}
                </Pie>

                {toolTipEnabled && <Tooltip content={CustomTooltip} wrapperStyle={{ left: 10, top: 10, padding: "0px" }} />}
                {showLegend && <Legend layout="vertical" align="right" verticalAlign="middle" />}
            </PieChart>
        </ResponsiveContainer>
    );
}

export default GenericPieChart;
