import { Box, Button, Checkbox, Chip, Container, Divider, FormControlLabel, Grid, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ICreateEventRequest, IEventDocument, createEvent, updateEvent } from "../../api/EventsApi";
import styles from './EventForm.module.css';
import { Loading } from "../loading/Loading";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IUserProfileView } from "../../models/User";
import { getUsers } from "../../api/UsersApi";
import { ToastContext } from "../../contexts/ToastContext";
import { BoothXError } from "../../models/BoothXError";
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { IDocumentView } from "../../models/Document";
import { getDocuments, uploadDocument } from "../../api/DocumentApis";
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import { useDropzone } from 'react-dropzone';
import AddOrUpdateUserDialog from "../addOrEditUserDialog/AddOrUpdateUserDialog";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { bytesToSize, getSymbolForCurrency, getSupportedCurrencyCodes, displayAmountWithCurrency } from "../../utils/Utils";
import { Link, useNavigate } from "react-router-dom";
import { EventObjective, IEventView } from "../../models/Event";
import { DateTime } from "luxon";
import { IEmailTemplateView } from "../../models/EmailTemplate";
import { getEmailTemplates } from "../../api/EmailTemplateApis";
import AddOrUpdateEmailTemplateDialog from "../addOrUpdateEmailTemplateDialog/AddOrUpdateEmailTemplateDialog";
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import { canUserAddOrUpdateDocuments, canUserAddOrUpdateEmailTemplates, canUserAddOrUpdateUsers, canUserManageEvent } from "../../utils/AuthUtils";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { displayNameForObjective } from "../../utils/EventUtils";
import GenericTable from "../genericTable/GenericTable";
import { getGiftVouchersConfig } from "../../api/GiftVoucherConfigApis";
import { IGiftVoucherView } from "../../models/GiftVouchers";
import { ICompanyWallet } from "../../models/Company";
import { getCompanyWallet } from "../../api/CompanyApi";
import RefreshIcon from '@mui/icons-material/Refresh';

const classes = { root: styles.input };
const bytes25MB = 25 * 1024 * 1024;
const bytes20MB = 20 * 1024 * 1024;

interface ICurrency {
    currencyCode: string;
    description: string;
}

const supportedCurrencyMap = getSupportedCurrencyCodes.map(currencyCode => ({ currencyCode: currencyCode, description: `${currencyCode} (${getSymbolForCurrency(currencyCode)})` } as ICurrency));

interface IProps {
    event?: IEventView;
}

function getDefaultEventValues(event?: IEventView): ICreateEventRequest | undefined {
    if (!event || event.isDraftEvent) {
        return undefined;
    }

    return {
        name: event.name,
        description: event.description,
        startDateInUTC: DateTime.fromSeconds(event.startDateInUTC),
        endDateInUTC: DateTime.fromSeconds(event.endDateInUTC),
        address: event.address,
        city: event.city,
        state: event.state,
        pincode: event.pincode,
        country: event.country,
        currencies: event.currencies,
        boothRepUserIds: event.boothRepUsers.map(user => user.id),
        applicableDocuments: event.applicableDocuments.map(doc => {
            return { documentId: doc.id, isSelectedByDefault: doc.isSelectedByDefault }
        }),
        emailTemplateId: event.emailTemplate?.id,
        objective: event.objective,
        boothNo: event.boothNo
    }
}

const EventForm: React.FC<IProps> = React.memo((props) => {
    const { event } = props;
    const isEditEnabled = canUserManageEvent();
    const isAddUserEnabled = canUserAddOrUpdateUsers();
    const isAddDocumentsEnabled = canUserAddOrUpdateDocuments();
    const isAddEmailTemplatesEnabled = canUserAddOrUpdateEmailTemplates();

    const defaultValues = useMemo(() => getDefaultEventValues(event), [event]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedCurrencies, setSelectedCurrencies] = useState<string[]>(defaultValues?.currencies ?? []);

    const [users, setUsers] = useState<IUserProfileView[]>([]);
    const [selectedUserIds, setSelectedUserIds] = useState<string[]>(defaultValues?.boothRepUserIds ?? []);
    const [isFetchingUsers, setIsFetchingUsers] = useState<boolean>(false);
    const [showAddUserDialog, setShowAddUserDialog] = useState<boolean>(false);

    const [documents, setDocuments] = useState<IDocumentView[]>([]);
    const [selectedDocuments, setSelectedDocuments] = useState<IEventDocument[] | undefined>(defaultValues?.applicableDocuments);
    const [isFetchingDocuments, setIsFetchingDocuments] = useState<boolean>(false);
    const [isUploadingDocument, setIsUploadingDocument] = useState<boolean>(false);

    const [emailTemplates, setEmailTemplates] = useState<IEmailTemplateView[]>([]);
    const [selectedEmailTemplateId, setSelectedEmailTemplateId] = useState<string>();
    const [isFetchingEmailTemplates, setIsFetchingEmailTemplates] = useState<boolean>(false);
    const [showAddEmailTemplateDialog, setShowAddEmailTemplateDialog] = useState<boolean>(false);
    const [giftVoucherConfig, setGiftVoucherConfig] = useState<IGiftVoucherView[] | undefined>(undefined);
    const [selectedGiftVoucherIds, setSelectedGiftVoucherIds] = useState<string[]>(defaultValues?.giftVoucherCatalogIds ?? []);
    const [isFetchingGiftVoucherConfig, setIsFetchingGiftVoucherConfig] = useState<boolean>(false);

    const [isFetchingWallet, setIsFetchingWallet] = useState<boolean>(false);
    const [wallet, setWallet] = useState<ICompanyWallet | undefined>(undefined);

    const { register, handleSubmit, formState: { errors }, clearErrors, control, setValue, watch } = useForm<ICreateEventRequest>();
    const { showToast } = useContext(ToastContext);

    const { open } = useDropzone({
        accept: {
            'application/pdf': ['.pdf'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'video/mp4': ['.mp4'],
            'video/quicktime': ['.mov'],
        },
        onDropAccepted: (acceptedFiles) => handleAddDocument(acceptedFiles[0]),
        onDropRejected: () => showToast("File size cannot be more than 24 MB", "error"),
        multiple: false,
        maxSize: 24 * 1024 * 1024,
    });

    const fetchUsers = useCallback(async () => {
        setIsFetchingUsers(true);
        const response = await getUsers();
        setIsFetchingUsers(false);
        if (response instanceof BoothXError) {
            showToast(response.message, "error");
            return;
        }

        const sortedUsers = response.sort((a, b) => a.displayName.localeCompare(b.displayName));
        setUsers(sortedUsers);
    }, [showToast]);

    const fetchWallet = useCallback(async () => {
        if (event?.companyId === undefined) {
            return;
        }
        setIsFetchingWallet(true);
        const response = await getCompanyWallet(event?.companyId);
        setIsFetchingWallet(false);
        if (response instanceof BoothXError) {
            showToast(response.message, "error");
            return;
        }

        setWallet(response);
    }, [showToast, event]);

    const fetchDocuments = useCallback(async () => {
        setIsFetchingDocuments(true);
        const response = await getDocuments();
        setIsFetchingDocuments(false);
        if (response instanceof BoothXError) {
            showToast(response.message, "error");
            return;
        }

        const sortedDocuments = response.sort((a, b) => a.name.localeCompare(b.name));
        setDocuments(sortedDocuments);
    }, [showToast]);

    const fetchEmailTemplates = useCallback(async () => {
        setIsFetchingEmailTemplates(true);
        const response = await getEmailTemplates();
        setIsFetchingEmailTemplates(false);
        if (response instanceof BoothXError) {
            showToast(response.message, "error");
            return;
        }

        const sortedEmailTemplates = response.sort((a, b) => a.name.localeCompare(b.name));
        setEmailTemplates(sortedEmailTemplates);
    }, [showToast]);

    const fetchGiftVoucherConfig = useCallback(async () => {
        setIsFetchingGiftVoucherConfig(true);
        const response = await getGiftVouchersConfig();
        setIsFetchingGiftVoucherConfig(false);
        if (response instanceof BoothXError) {
            showToast(response.message, "error");
            return;
        }

        setGiftVoucherConfig(response.giftVouchers);
    }, [showToast]);

    const navigate = useNavigate();
    const onSubmit: SubmitHandler<ICreateEventRequest> = async (data) => {
        setIsLoading(true);

        if (data.applicableDocuments) {
            data.applicableDocuments = data.applicableDocuments.filter(document => typeof document.documentId === "string");
        }

        if (typeof data.boothRepUserIds === "string") {
            data.boothRepUserIds = [data.boothRepUserIds];
        }

        if (typeof data.giftVoucherCatalogIds === "string") {
            data.giftVoucherCatalogIds = [data.giftVoucherCatalogIds];
        }

        if (typeof data.currencies === "string") {
            const currencies = (data.currencies as string).split(",");
            data.currencies = currencies;
        }

        let response: null | BoothXError;

        if (event) {
            response = await updateEvent(event.id, data);
        } else {
            response = await createEvent(data);
        }
        setIsLoading(false);

        if (response instanceof BoothXError) {
            showToast(response.message, "error");
            return;
        }

        if (event) {
            showToast("Event updated successfully", "success");
            if (event.isDraftEvent) {
                navigate('/events');
            }
            return;
        } else {
            showToast("Event created successfully", "success");
            navigate('/events');
        }
    };

    useEffect(() => {
        if (isEditEnabled) {
            fetchUsers();
        } else {
            if (event) {
                setUsers(event.boothRepUsers);
            }
        }
    }, [fetchUsers, isEditEnabled, event]);

    useEffect(() => {
        if (isEditEnabled) {
            fetchDocuments();
        } else {
            if (event) {
                setDocuments(event.applicableDocuments);
            }
        }
    }, [fetchDocuments, isEditEnabled, event]);

    useEffect(() => {
        if (isEditEnabled) {
            fetchEmailTemplates();
        } else {
            if (event && event.emailTemplate) {
                setEmailTemplates([event.emailTemplate]);
            }
        }
    }, [fetchEmailTemplates, isEditEnabled, event]);

    useEffect(() => {
        fetchWallet();
        if (isEditEnabled) {
            fetchGiftVoucherConfig();
        }
        // if event already has any gift vouchers, then set them as selected
        if (event && event.applicableGiftVouchers && event.applicableGiftVouchers.length > 0) {
            const vouchers = event.applicableGiftVouchers.map(voucher => voucher.id);
            setSelectedGiftVoucherIds(vouchers);
        }

    }, [fetchGiftVoucherConfig, fetchWallet, isEditEnabled, event]);

    const handleAddDocument = async (file: File) => {
        setIsUploadingDocument(true);
        const response = await uploadDocument(file);
        setIsUploadingDocument(false);
        if (response instanceof BoothXError) {
            showToast(response.message, "error");
            return;
        }

        fetchDocuments();
        setSelectedDocuments([...selectedDocuments ?? [], { documentId: response.id, isSelectedByDefault: false }]);
    }

    const handleAddUserDialogClose = (didAddUser: boolean, user?: IUserProfileView) => {
        setShowAddUserDialog(false);
        if (didAddUser) {
            fetchUsers();
            if (user) {
                setSelectedUserIds([...selectedUserIds, user.id]);
            }
        }
    }

    const handleAddEmailTemplateDialogClose = (didAddEmailTemplate: boolean, emailTemplate?: IEmailTemplateView) => {
        setShowAddEmailTemplateDialog(false);
        if (didAddEmailTemplate) {
            fetchEmailTemplates();
            if (emailTemplate) {
                setValue("emailTemplateId", emailTemplate.id);
                setSelectedEmailTemplateId(emailTemplate.id);
            }
        }
    }

    const totalSelectedDocumentsSize = documents
        .filter(document => selectedDocuments && selectedDocuments.some(selectedDocument => selectedDocument.documentId === document.id))
        .map(document => document.sizeInBytes)
        .reduce((a, b) => a + b, 0);

    return (
        <Box component="div">
            {showAddUserDialog && <AddOrUpdateUserDialog onClose={handleAddUserDialogClose} />}
            {showAddEmailTemplateDialog && <AddOrUpdateEmailTemplateDialog onClose={handleAddEmailTemplateDialogClose} />}
            <Container
                component={"div"}
                disableGutters
                maxWidth={false}
                sx={{
                    display: "flex",
                    justifyContent: "flex-end"
                }}
            >
                <Button
                    aria-label="add"
                    variant="text"
                    onClick={() => { navigate(-1) }}
                    sx={{ fontWeight: "bold", textTransform: "none" }}
                >
                    <ArrowBackIosNewRoundedIcon /> Back
                </Button>
            </Container>
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}>
                <Typography
                    component="div"
                    sx={{
                        textAlign: "left",
                        fontWeight: "600",
                        mt: event === undefined ? 3 : 0,
                        mb: 1
                    }}
                >
                    Event Name
                </Typography>
                <TextField
                    {...register("name", { required: true })}
                    variant="outlined"
                    InputProps={{
                        classes: classes,
                        readOnly: !isEditEnabled
                    }}
                    margin="none"
                    fullWidth
                    name="name"
                    placeholder="E.g. ABC expo 2023"
                    autoComplete="off"
                    error={errors.name === undefined ? false : true}
                    helperText={errors.name === undefined ? "" : "Event name is required"}
                    defaultValue={defaultValues?.name}
                    onChange={(event) => {
                        clearErrors("name");
                        setValue("name", event.target.value);
                    }}
                />
                <Typography component="div" sx={{ textAlign: "left", fontWeight: "600", mt: 2, mb: 1 }}>
                    Event Description
                </Typography>
                <TextField
                    {...register("description", { required: true })}
                    variant="outlined"
                    InputProps={{
                        classes: classes,
                        readOnly: !isEditEnabled
                    }}
                    multiline
                    rows={3}
                    margin="none"
                    fullWidth
                    name="description"
                    placeholder="What is the event about?"
                    autoComplete="off"
                    error={errors.description === undefined ? false : true}
                    helperText={errors.description === undefined ? "" : "Event description is required"}
                    defaultValue={defaultValues?.description}
                    onChange={(event) => {
                        clearErrors("description");
                        setValue("description", event.target.value);
                    }}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography component="div" sx={{ textAlign: "left", fontWeight: "600", mt: 2, mb: 1 }}>
                            Start Date
                        </Typography>
                        <Controller
                            name="startDateInUTC"
                            control={control}
                            rules={{ required: true }}
                            defaultValue={defaultValues?.startDateInUTC || null}
                            render={({ field }) =>
                                <MobileDatePicker
                                    {...field}
                                    closeOnSelect
                                    disablePast
                                    disabled={!isEditEnabled}
                                    format="dd-MMM-yyyy"
                                    maxDate={watch("endDateInUTC") || undefined}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            InputProps: {
                                                classes: classes
                                            },
                                            helperText: errors.startDateInUTC === undefined ? "" : "Start date is required",
                                            error: errors.startDateInUTC === undefined ? false : true
                                        }
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="div" sx={{ textAlign: "left", fontWeight: "600", mt: 2, mb: 1 }}>
                            End Date
                        </Typography>
                        <Controller
                            name="endDateInUTC"
                            control={control}
                            rules={{ required: true }}
                            defaultValue={defaultValues?.endDateInUTC || null}
                            render={({ field }) =>
                                <MobileDatePicker
                                    {...field}
                                    closeOnSelect
                                    disablePast
                                    disabled={!isEditEnabled}
                                    format="dd-MMM-yyyy"
                                    minDate={watch("startDateInUTC") || undefined}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            InputProps: {
                                                classes: classes
                                            },
                                            helperText: errors.endDateInUTC === undefined ? "" : "End date is required",
                                            error: errors.endDateInUTC === undefined ? false : true
                                        }
                                    }}
                                />
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <Typography component="div" sx={{ textAlign: "left", fontWeight: "600", mt: 2 }}>
                            Event Location
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            {...register("city", { required: true })}
                            variant="outlined"
                            InputProps={{
                                classes: classes,
                                readOnly: !isEditEnabled
                            }}
                            margin="none"
                            fullWidth
                            name="city"
                            placeholder="City"
                            autoComplete="off"
                            defaultValue={defaultValues?.city}
                            error={errors.city === undefined ? false : true}
                            helperText={errors.city === undefined ? "" : "City is required"}
                            onChange={(event) => {
                                clearErrors("city");
                                setValue("city", event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            {...register("state", { required: true })}
                            variant="outlined"
                            InputProps={{
                                classes: classes,
                                readOnly: !isEditEnabled
                            }}
                            margin="none"
                            fullWidth
                            name="state"
                            placeholder="State"
                            autoComplete="off"
                            defaultValue={defaultValues?.state}
                            error={errors.state === undefined ? false : true}
                            helperText={errors.state === undefined ? "" : "state is required"}
                            onChange={(event) => {
                                clearErrors("state");
                                setValue("state", event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            {...register("country", { required: true })}
                            variant="outlined"
                            InputProps={{
                                classes: classes,
                                readOnly: !isEditEnabled
                            }}
                            margin="none"
                            fullWidth
                            name="country"
                            placeholder="Country"
                            autoComplete="off"
                            defaultValue={defaultValues?.country}
                            error={errors.country === undefined ? false : true}
                            helperText={errors.country === undefined ? "" : "Country is required"}
                            onChange={(event) => {
                                clearErrors("country");
                                setValue("country", event.target.value);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography component="div" sx={{ textAlign: "left", fontWeight: "600", mt: 2, mb: 1 }}>
                            Booth Number
                            <Typography component="span" sx={{ textAlign: "left", fontSize: 12, ml: 1 }}>(optional)</Typography>
                        </Typography>
                        <TextField
                            {...register("boothNo")}
                            variant="outlined"
                            InputProps={{
                                classes: classes,
                                readOnly: !isEditEnabled
                            }}
                            margin="none"
                            fullWidth
                            name="boothNo"
                            placeholder="Booth Number"
                            autoComplete="off"
                            defaultValue={defaultValues?.boothNo}
                            onChange={(event) => {
                                clearErrors("boothNo");
                                setValue("boothNo", event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="div" sx={{ textAlign: "left", fontWeight: "600", mt: 2, mb: 1 }}>
                            Objective
                            <Typography component="span" sx={{ textAlign: "left", fontSize: 12, ml: 1 }}>(optional)</Typography>
                        </Typography>
                        <TextField
                            {...register("objective")}
                            select
                            variant="outlined"
                            InputProps={{
                                classes: classes,
                                readOnly: !isEditEnabled,
                                sx: {
                                    textAlign: "left"
                                }
                            }}
                            margin="none"
                            fullWidth
                            name="objective"
                            autoComplete="off"
                            defaultValue={event?.objective}
                        >
                            {Object.values(EventObjective).map((objective) => (
                                <MenuItem key={objective} value={objective}>
                                    {displayNameForObjective(objective)}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                {(event === undefined || event.disableExpenses === undefined || event.disableExpenses === false) && (
                    <>
                        <Typography
                            component="div"
                            sx={{
                                textAlign: "left",
                                fontWeight: "600",
                                mt: 2,
                                mb: errors.currencies ? 0 : 1,
                                display: "flex",
                                justifyContent: "start"
                            }}>
                            All Expense Currencies&nbsp;
                            <Tooltip
                                placement="right"
                                title={"All currencies that your employees will be able to use to submit expenses for this event."}
                                arrow
                            >
                                <InfoOutlinedIcon fontSize="small" />
                            </Tooltip>
                        </Typography>
                        {errors.currencies &&
                            <Typography
                                component="div"
                                sx={{
                                    textAlign: "left",
                                    fontSize: 12,
                                    color: "#d32f2f",
                                    fontWeight: "normal",
                                    mb: 1
                                }}>
                                Please select at least one currency
                            </Typography>
                        }
                        <Select
                            {...register("currencies", { required: true })}
                            multiple
                            displayEmpty
                            disabled={!isEditEnabled}
                            value={selectedCurrencies}
                            autoComplete="off"
                            defaultValue={defaultValues?.currencies || []}
                            onChange={(event) => {
                                clearErrors("currencies");
                                const value = event.target.value;
                                const currencies = typeof value === "string" ? value.split(",") : value;
                                setSelectedCurrencies(currencies);
                                setValue("currencies", currencies);
                            }}
                            error={errors.currencies === undefined ? false : true}
                            input={<OutlinedInput fullWidth sx={{ textAlign: "left" }} classes={classes} />}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <em style={{ color: "grey" }}>Select Currencies</em>;
                                }

                                const currencies = supportedCurrencyMap.filter(currency => selected.includes(currency.currencyCode));
                                return (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {currencies.map(currency => <Chip key={currency.currencyCode} label={currency.description} />)}
                                    </Box>
                                )
                            }}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem disabled value="">
                                <em>Select Currencies</em>
                            </MenuItem>
                            {supportedCurrencyMap.map((currency) => (
                                <MenuItem
                                    key={currency.currencyCode}
                                    value={currency.currencyCode}
                                >
                                    <FormControlLabel
                                        checked={selectedCurrencies.indexOf(currency.currencyCode) !== -1}
                                        control={<Checkbox size="small" />}
                                        label={currency.description}
                                        componentsProps={{
                                            typography: {
                                                variant: "caption"
                                            }
                                        }}
                                    />
                                </MenuItem>
                            ))}
                        </Select>
                    </>
                )}
                <Typography
                    component="div"
                    sx={{
                        textAlign: "left",
                        fontWeight: "600",
                        mt: 2,
                        mb: errors.boothRepUserIds ? 0 : 1,
                        display: "flex",
                        justifyContent: "start"
                    }}>
                    Select Booth Reps&nbsp;
                    <Tooltip
                        placement="right"
                        title={"These users will see this event on the boothX mobile app"}
                        arrow
                    >
                        <InfoOutlinedIcon fontSize="small" />
                    </Tooltip>
                </Typography>
                {errors.boothRepUserIds &&
                    <Typography
                        component="div"
                        sx={{
                            textAlign: "left",
                            fontSize: 12,
                            color: "#d32f2f",
                            fontWeight: "normal",
                            mb: 1
                        }}>
                        Please select at least one booth rep
                    </Typography>
                }
                <Grid container sx={{
                    backgroundColor: "#ffffff",
                    padding: "10px",
                    borderRadius: "5px",
                }}>
                    {isFetchingUsers && <Loading isLoading={true} />}
                    {!isFetchingUsers && users.length === 0 && <div style={{ color: "grey" }}>No users found</div>}
                    {users.length > 0 && users.map((user) => {
                        return (
                            <Grid
                                item
                                xs={12}
                                sm={4}
                                key={user.id}
                                sx={{
                                    display: "flex",
                                    justifyContent: "left"
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...register("boothRepUserIds", { required: true })}
                                            name="boothRepUserIds"
                                            checked={selectedUserIds?.includes(user.id)}
                                            value={user.id}
                                            disabled={!isEditEnabled}
                                            onChange={(event) => {
                                                let newSelectedBoothRepUserIds: string[];
                                                if (event.target.checked) {
                                                    newSelectedBoothRepUserIds = [...selectedUserIds, user.id];
                                                } else {
                                                    newSelectedBoothRepUserIds = selectedUserIds.filter(userId => userId !== user.id);
                                                }
                                                setSelectedUserIds(newSelectedBoothRepUserIds);
                                                setValue("boothRepUserIds", newSelectedBoothRepUserIds);
                                                clearErrors("boothRepUserIds");
                                            }}
                                        />
                                    }
                                    label={`${user.displayName}`}
                                />
                            </Grid>
                        )
                    })}

                    {isAddUserEnabled && (
                        <Grid item xs={12} sm={12}>
                            <Divider />
                            <Button
                                variant="text"
                                sx={{ mt: 1, textTransform: "none", fontWeight: "600" }}
                                onClick={() => setShowAddUserDialog(true)}
                                startIcon={<GroupAddOutlinedIcon sx={{ height: 24, width: 24 }} />}
                            >
                                Add User
                            </Button>
                        </Grid>
                    )}
                </Grid>

                <Box component="div" sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                    mb: (errors.applicableDocuments && selectedDocuments?.length === 0) ? 0 : 1
                }}>
                    <Typography
                        component="div"
                        sx={{
                            textAlign: "left",
                            fontWeight: "600",
                            display: "flex",
                            justifyContent: "start"
                        }}>
                        Select Collaterals / Documents&nbsp;
                        <Tooltip
                            placement="right"
                            title={"These collaterals / documents can be sent as email attachements to leads"}
                            arrow
                        >
                            <InfoOutlinedIcon fontSize="small" />
                        </Tooltip>
                    </Typography>
                    <Typography component="span" sx={{ color: "#000000", fontSize: "14px", fontStyle: "italic", fontWeight: "500" }}>
                        Total attachment size:&nbsp;
                        <Typography
                            component="span"
                            sx={{
                                color: `${bytes25MB < totalSelectedDocumentsSize ? "#f05b57" : `${bytes20MB < totalSelectedDocumentsSize ? "#f09e57" : "#000000"}`}`,
                                fontSize: "14px"
                            }}>
                            {bytesToSize(totalSelectedDocumentsSize)}
                        </Typography>
                    </Typography>
                </Box>
                {errors.applicableDocuments && selectedDocuments?.length === 0 &&
                    <Typography
                        component="div"
                        sx={{
                            textAlign: "left",
                            fontSize: 12,
                            color: "#d32f2f",
                            fontWeight: "normal",
                            mb: 1
                        }}>
                        Please select at least one collateral / document
                    </Typography>
                }
                <Grid container sx={{
                    backgroundColor: "#ffffff",
                    padding: "10px",
                    borderRadius: "5px",
                }}>
                    {!isFetchingDocuments && documents.length === 0 && <div style={{ color: "grey" }}>No collaterals / documents found</div>}
                    <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                        <GenericTable
                            data={documents}
                            alignContent="center"
                            headers={[
                                { header: "Name" },
                                { header: "Size" },
                                { header: "Select For Event" },
                                { header: "Send By Default" },
                            ]}
                            isLoading={isFetchingDocuments}
                            dataRenderer={(documentRow, column, row) => {
                                switch (column) {
                                    case 0:
                                        return documentRow.name;
                                    case 1:
                                        return bytesToSize(documentRow.sizeInBytes);
                                    case 2:
                                        return (
                                            <Checkbox
                                                size="small"
                                                {...register(`applicableDocuments.${row}.documentId`)}
                                                name={`applicableDocuments.${row}.documentId`}
                                                checked={selectedDocuments ? selectedDocuments.some(document => document.documentId === documentRow.id) : undefined}
                                                value={documentRow.id}
                                                disabled={!isEditEnabled}
                                                onClick={() => {
                                                    let newSelectedDocuments: IEventDocument[];
                                                    if (!selectedDocuments) {
                                                        newSelectedDocuments = [{ documentId: documentRow.id, isSelectedByDefault: false }];
                                                    } else {
                                                        if (selectedDocuments.some(document => document.documentId === documentRow.id)) {
                                                            newSelectedDocuments = selectedDocuments.filter((document => document.documentId !== documentRow.id));
                                                        } else {
                                                            newSelectedDocuments = [...selectedDocuments, { documentId: documentRow.id, isSelectedByDefault: false }];
                                                        }
                                                    }

                                                    setSelectedDocuments(newSelectedDocuments);
                                                }}
                                            />
                                        );
                                    case 3:
                                        return (
                                            <Checkbox
                                                size="small"
                                                {...register(`applicableDocuments.${row}.isSelectedByDefault`)}
                                                name={`applicableDocuments.${row}.isSelectedByDefault`}
                                                checked={selectedDocuments ? selectedDocuments.some(document => document.documentId === documentRow.id && document.isSelectedByDefault) : undefined}
                                                disabled={!isEditEnabled || (!(selectedDocuments?.some(document => document.documentId === documentRow.id) ?? false))}
                                                onClick={() => {
                                                    const isChecked = selectedDocuments?.some(document => document.documentId === documentRow.id && document.isSelectedByDefault) ?? false;
                                                    const newSelectedDocuments = selectedDocuments?.map(document => {
                                                        const isSelectedDocument = document.documentId === documentRow.id;
                                                        if (!isSelectedDocument) {
                                                            return document;
                                                        }

                                                        return { ...document, isSelectedByDefault: !isChecked };
                                                    }) ?? [];
                                                    setSelectedDocuments(newSelectedDocuments);
                                                }}
                                            />
                                        );
                                }
                            }}
                        />
                    </Grid>

                    {isAddDocumentsEnabled && (
                        <Grid item xs={12} sm={12}>
                            <Divider />
                            <Button
                                variant="text"
                                sx={{ mt: 1, textTransform: "none", fontWeight: "600" }}
                                onClick={open}
                                startIcon={<PostAddOutlinedIcon sx={{ height: 24, width: 24 }} />}
                                disabled={isUploadingDocument}
                            >
                                <Loading isLoading={isUploadingDocument} text="Add Collateral / Document" />
                            </Button>
                        </Grid>
                    )}
                </Grid>

                <Typography
                    component="div"
                    sx={{
                        textAlign: "left",
                        fontWeight: "600",
                        mt: 2,
                        mb: errors.emailTemplateId ? 0 : 1,
                        display: "flex",
                        justifyContent: "start"
                    }}>
                    Select Email Template&nbsp;
                    <Tooltip
                        placement="right"
                        title={"This template will be used while sending the email to leads"}
                        arrow
                    >
                        <InfoOutlinedIcon fontSize="small" />
                    </Tooltip>
                </Typography>
                {errors.emailTemplateId &&
                    <Typography
                        component="div"
                        sx={{
                            textAlign: "left",
                            fontSize: 12,
                            color: "#d32f2f",
                            fontWeight: "normal",
                            mb: 1
                        }}>
                        Please select at least one email template
                    </Typography>
                }
                <Grid
                    container
                    sx={{
                        backgroundColor: "#ffffff",
                        padding: "10px",
                        borderRadius: "5px",
                    }}>
                    {isFetchingEmailTemplates && <Loading isLoading={true} />}
                    {!isFetchingEmailTemplates && emailTemplates.length === 0 && <div style={{ color: "grey" }}>No email templates found</div>}
                    {emailTemplates.length > 0 && (
                        <RadioGroup
                            row
                            defaultValue={defaultValues?.emailTemplateId}
                            sx={{ width: "100%" }}
                        >
                            {emailTemplates.map((emailTemplate) =>
                                <FormControlLabel
                                    {...register("emailTemplateId")}
                                    name="emailTemplateId"
                                    key={emailTemplate.id}
                                    control={<Radio disabled={!isEditEnabled} />}
                                    label={`${emailTemplate.name}`}
                                    value={emailTemplate.id}
                                    sx={{ width: "32%" }}
                                    checked={selectedEmailTemplateId ? selectedEmailTemplateId === emailTemplate.id : undefined}
                                />
                            )}
                        </RadioGroup>
                    )}

                    {isAddEmailTemplatesEnabled && (
                        <Grid item xs={12} sm={12}>
                            <Divider />
                            <Button
                                variant="text"
                                sx={{ mt: 1, textTransform: "none", fontWeight: "600" }}
                                onClick={() => setShowAddEmailTemplateDialog(true)}
                                startIcon={<AddCommentOutlinedIcon sx={{ height: 24, width: 24 }} />}
                            >
                                Add Email Template
                            </Button>
                        </Grid>
                    )}
                </Grid>

                <Typography
                    component="div"
                    sx={{
                        textAlign: "left",
                        fontWeight: "600",
                        mt: 2,
                        mb: errors.giftVoucherCatalogIds ? 0 : 1,
                        display: "flex",
                        justifyContent: "start"
                    }}>
                    Select Gift Vouchers to offer&nbsp;
                    <Tooltip
                        placement="right"
                        title={"Booth reps will be able to pick gift vouchers from this list to offer to leads"}
                        arrow
                    >
                        <InfoOutlinedIcon fontSize="small" />
                    </Tooltip>
                </Typography>
                {errors.giftVoucherCatalogIds &&
                    <Typography
                        component="div"
                        sx={{
                            textAlign: "left",
                            fontSize: 12,
                            color: "#d32f2f",
                            fontWeight: "normal",
                            mb: 1
                        }}>
                        Please select gift vouchers from this list
                    </Typography>
                }
                <Grid container sx={{
                    backgroundColor: "#ffffff",
                    padding: "10px",
                    borderRadius: "5px",
                }}>
                    <>
                        {isFetchingGiftVoucherConfig && <Loading isLoading={true} />}
                        {!isFetchingGiftVoucherConfig && giftVoucherConfig && giftVoucherConfig.length === 0 && <div style={{ color: "grey" }}>No applicable gift vouchers found</div>}
                        {giftVoucherConfig && giftVoucherConfig.length > 0 && giftVoucherConfig.map((voucher) => {
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    key={voucher.id}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "left"
                                    }}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...register("giftVoucherCatalogIds", { required: true })}
                                                name="giftVoucherCatalogIds"
                                                checked={selectedGiftVoucherIds.includes(voucher.id)}
                                                value={voucher.id}
                                                disabled={!isEditEnabled}
                                                onChange={(event) => {
                                                    let newSelectedGiftVoucherIds: string[];
                                                    if (event.target.checked) {
                                                        newSelectedGiftVoucherIds = [...selectedGiftVoucherIds, voucher.id];
                                                    } else {
                                                        newSelectedGiftVoucherIds = selectedGiftVoucherIds.filter(voucherId => voucherId !== voucher.id);
                                                    }
                                                    setSelectedGiftVoucherIds(newSelectedGiftVoucherIds);
                                                    setValue("giftVoucherCatalogIds", newSelectedGiftVoucherIds);
                                                    clearErrors("giftVoucherCatalogIds");
                                                }}
                                            />
                                        }
                                        label={`${voucher.name} (${voucher.currencyCode} ${voucher.denomination})`}
                                    />
                                </Grid>
                            )
                        })}
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            sx={{
                                display: "flex",
                                justifyContent: "right",
                                alignItems: "center"
                            }}
                        >
                            <>
                                <Button>
                                    <RefreshIcon onClick={() => fetchWallet()} />
                                </Button>
                                <Typography
                                    component="div"
                                    sx={{
                                        color: "#000000",
                                        fontWeight: "bold",
                                    }}>
                                    {isFetchingWallet && <Loading isLoading={true} />}
                                    {!isFetchingWallet && wallet && wallet.balance > 0 && <div>Current Wallet Balance : {displayAmountWithCurrency(wallet.balance, wallet.baseCurrencyCode)} </div>}
                                    {!isFetchingWallet && (wallet === undefined || wallet.balance === 0) && <div>Current Wallet Balance : 0</div>}
                                </Typography>
                            </>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            sx={{
                                display: "flex",
                                justifyContent: "right"
                            }}
                        >
                            <Link to='/billing' target="_blank" style={{ color: '#000' }}>
                                <b> + Add Money to wallet</b>
                            </Link>
                        </Grid>
                    </>
                </Grid>

                {isEditEnabled && (
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3 }}
                        disabled={isLoading}
                    >
                        <Loading isLoading={isLoading} text={event ? "Save event" : "Submit Event"} />
                    </Button>
                )}
            </Box>
        </Box>
    );
});

export default EventForm;
