import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../components/loading/Loading";
import { getBillingSessionStatus } from "../../api/Billing";
import { BoothXError } from "../../models/BoothXError";
import { PaymentSessionStatus, PaymentSessionTargetAction } from "../../models/Billing";
import useInterval from "../../hooks/UseInterval";
import { getEvents } from "../../api/EventsApi";
import useEventsCache from "../../hooks/EventsHook";


const PaymentSessionPage: React.FC = React.memo(() => {

    const { paymentSessionId, status } = useParams();
    
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const action = queryParams.get('action');

    const [isCancelOperation, setIsCancelOperation] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const { populateEventsCache } = useEventsCache();
    
    useEffect(() => {
        if (status === "cancel") {
            setIsCancelOperation(true);
            setTimeout(() => {
                navigate(action === PaymentSessionTargetAction.CREATE_EVENT
                    ? "/events/create"
                    : "/billing"
                );
            }, 2000); // 2 seconds delay, so that the user can see the message
        }
    }, [status, navigate, action]);

    const syncEvents = async () => {
        setIsLoading(true);
        const response = await getEvents();
        setIsLoading(false);
        if (response instanceof BoothXError) {
            return;
        }
        await populateEventsCache(response);
    };

    const INTERVAL_IN_MS = 5 * 1000; // 5 seconds
    var counter = 0;

    useInterval(async () => {
        counter++;
        const response = await getBillingSessionStatus(paymentSessionId!)
        if (response instanceof BoothXError) {
            return;
        }
        
        if (response.paymentSession.paymentSessionTargetAction === PaymentSessionTargetAction.ADD_FUNDS) {
            navigate(`/billing`);
            return;
        }

        if (response.paymentSession.status === PaymentSessionStatus.COMPLETED) {
            // sync events in cache before proceeding to the page, so it gets a cache hit
            await syncEvents();
            navigate(`/events/${response.paymentSession.targetEventId}`);
            return;
        }

        if (counter > 10 || response.paymentSession.status === PaymentSessionStatus.CANCELLED) {
            navigate(`/events/create`);
            return;
        }

    }, isCancelOperation ? null : INTERVAL_IN_MS);


    const statusTextMessage = () => {
        return isCancelOperation ? `Taking you back to ${action === PaymentSessionTargetAction.CREATE_EVENT ? "pricing" : "rewards"}  page...` : "Verifying the payment. Please do not navigate away from this page.";
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}
        >
            <Grid item xs={3}>
                <div>
                    <Typography
                        component="h1"
                        variant="h4"
                        sx={{
                            textAlign: "left",
                            fontWeight: "600",
                            display: { xs: "none", sm: "block" } // hide on mobile
                        }}
                    >
                        {statusTextMessage()}
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={3}>
                <div>
                    <Loading
                        isLoading={isLoading}
                        size={50}
                    />
                </div>
            </Grid>
        </Grid>

    );
});

export default PaymentSessionPage;
