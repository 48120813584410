
import { EventPlanType, IBillingItem, IBillingPlanConfig, IPaymentSession } from "../models/Billing";
import { BoothXError } from "../models/BoothXError";
import { BACKEND_URL_V1, get, post } from "../utils/Api";

interface IGetBillingPlansResponse {
    billingPlanConfig: IBillingPlanConfig;
}

interface ICreateBillingSessionResponse {
    paymentSession: IPaymentSession;
}

interface IGetBillingSessionStatusResponse {
    paymentSession: IPaymentSession;
}

interface IGetBillingHistoryResponse {
    billingItems: IBillingItem[];
}

export const getBillingPlanConfig = async (): Promise<IGetBillingPlansResponse | BoothXError> => {
    try {
        const response = await get<IGetBillingPlansResponse>(`${BACKEND_URL_V1}/billing/me/plans`);
        if (response.parsedBody) {
            return response.parsedBody;
        }
        const errorMessage = response.serverError?.error.message ?? "Plans could not be retrieved";
        return new BoothXError(errorMessage);
    }
    catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}

export const createBillingSession = async (eventPlanType: EventPlanType): Promise<ICreateBillingSessionResponse | BoothXError> => {
    try {
        const response = await post<ICreateBillingSessionResponse>(`${BACKEND_URL_V1}/billing/createBillingSession`,
            { planType: eventPlanType }
        );
        if (response.parsedBody) {
            return response.parsedBody;
        }
        const errorMessage = response.serverError?.error.message ?? "Could not create billing session";
        return new BoothXError(errorMessage);
    }
    catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}


export const getBillingSessionStatus = async (sessionId: string): Promise<IGetBillingSessionStatusResponse | BoothXError> => {
    try {
        const response = await get<IGetBillingSessionStatusResponse>(`${BACKEND_URL_V1}/billing/paymentSession/${sessionId}`);
        if (response.parsedBody) {
            return response.parsedBody;
        }
        const errorMessage = response.serverError?.error.message ?? "Payment session could not be retrieved";
        return new BoothXError(errorMessage);
    }
    catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}

export const createAddFundsSession = async (amount: number): Promise<ICreateBillingSessionResponse | BoothXError> => {
    try {
        const response = await post<ICreateBillingSessionResponse>(`${BACKEND_URL_V1}/billing/createAddFundsSession`, { amount: amount });
        if (response.parsedBody) {
            return response.parsedBody;
        }
        const errorMessage = response.serverError?.error.message ?? "Could not create add funds session";
        return new BoothXError(errorMessage);
    }
    catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}

export const getBillingHistory = async (): Promise<IGetBillingHistoryResponse | BoothXError> => {
    try {
        const response = await get<IGetBillingHistoryResponse>(`${BACKEND_URL_V1}/billing/history`);
        if (response.parsedBody) {
            return response.parsedBody;
        }
        const errorMessage = response.serverError?.error.message ?? "Billing history could not be retrieved";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}
