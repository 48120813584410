import { useCallback, useContext, useState } from "react";
import { IAddTaskRequest, IEventView, ITaskView } from "../../models/Event";
import GenericDialog from "../genericDialog/GenericDialog";
import { ToastContext } from "../../contexts/ToastContext";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { addTask, updateTask } from "../../api/EventsApi";
import { BoothXError } from "../../models/BoothXError";
import { Box, Button, Grid, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import styles from './AddOrUpdateTaskDialog.module.css';
import { MobileDatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { useDropzone } from "react-dropzone";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
    event: IEventView;
    parentTask?: ITaskView;
    isSubTask: boolean;
    task?: ITaskView;
    onClose: (didAddTask: boolean, task?: ITaskView) => void;
}

const classes = { root: styles.input };

const AddOrUpdateTaskDialog: React.FC<IProps> = (props) => {

    const { event, onClose, isSubTask, parentTask, task } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File>();

    const { showToast } = useContext(ToastContext);

    const isUpdate = task !== undefined;

    const { register, handleSubmit, formState: { errors }, clearErrors, setValue, control } = useForm<IAddTaskRequest>({
        defaultValues: task ? {
            description: task.description,
            assignedToUserId: task.assignedToUserId,
            startDateInUTC: task.startDateInUTC ? DateTime.fromSeconds(task.startDateInUTC) : undefined,
            dueDateInUTC: task.dueDateInUTC ? DateTime.fromSeconds(task.dueDateInUTC) : undefined
        } : undefined
    });

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setSelectedFile(acceptedFiles[0]);
    }, []);

    const {
        getRootProps,
        getInputProps
    } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'application/pdf': ['.pdf'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'video/mp4': ['.mp4'],
            'video/quicktime': ['.mov'],
        },
    });

    const onSubmit: SubmitHandler<IAddTaskRequest> = async (data) => {
        setIsLoading(true);
        if (!isUpdate && isSubTask) {
            data.parentTaskId = parentTask?.id;
        }

        let response: BoothXError | ITaskView;
        if (isUpdate) {
            response = await updateTask(event.id, task.id, data, selectedFile);
        } else {
            response = await addTask(event.id, data, selectedFile);
        }
        setIsLoading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        showToast(`${isSubTask ? "Task" : "Deliverable"} ${isUpdate ? "updated" : "added"}!`, "success");
        onClose(true, response);
    };

    return (
        <GenericDialog
            title={`${isUpdate ? "Update" : "Add"} ${isSubTask ? "Task" : "Deliverable"}`}
            isOpen={true}
            primaryButtonText={isUpdate ? "Update" : "Add"}
            primaryButtonLoading={isLoading}
            primaryButtonAction={handleSubmit(onSubmit)}
            width={"sm"}
            onClose={() => {
                onClose(false);
            }}
        >
            <Grid container spacing={0} padding={0}>
                <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    <TextField
                        {...register("description", { required: true })}
                        variant="filled"
                        InputProps={{
                            disableUnderline: true,
                            classes: classes
                        }}
                        margin="normal"
                        fullWidth
                        label="Description"
                        name="description"
                        autoComplete="off"
                        error={errors.description === undefined ? false : true}
                        helperText={errors.description === undefined ? "" : "Description is required"}
                        onChange={(e) => {
                            clearErrors("description");
                            setValue("description", e.target.value);
                        }}
                    />


                </Grid>
                {isSubTask && (
                    <>
                        <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                            <TextField
                                {...register("assignedToUserId", { required: true })}
                                variant="filled"
                                select
                                InputProps={{
                                    disableUnderline: true,
                                    classes: classes
                                }}
                                defaultValue={task?.assignedToUserId ?? ""}
                                margin="normal"
                                fullWidth
                                label="Assigned to"
                                name="Assigned to"
                                error={errors.assignedToUserId === undefined ? false : true}
                                helperText={errors.assignedToUserId === undefined ? "" : "Assigned to user is required"}
                                onChange={(event) => {
                                    clearErrors("assignedToUserId");
                                    setValue("assignedToUserId", event.target.value as string);
                                }}
                            >
                                {event.boothRepUsers.map((user) => (
                                    <MenuItem key={user.id} value={user.id}>
                                        {user.displayName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                            <Controller
                                name="startDateInUTC"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) =>
                                    <MobileDatePicker
                                        {...field}
                                        onChange={(event) => field.onChange(event === null ? undefined : event)}
                                        closeOnSelect
                                        disablePast
                                        format="dd-MMM-yyyy"
                                        slotProps={{
                                            textField: {
                                                label: "Start Date",
                                                fullWidth: true,
                                                variant: "filled",
                                                InputProps: {
                                                    disableUnderline: true,
                                                    classes: classes
                                                },
                                                sx: {
                                                    marginTop: 2
                                                },
                                                helperText: errors.startDateInUTC === undefined ? "" : "Start date is required",
                                                error: errors.startDateInUTC === undefined ? false : true
                                            }
                                        }}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                            <Controller
                                name="dueDateInUTC"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) =>
                                    <MobileDatePicker
                                        {...field}
                                        onChange={(event) => field.onChange(event === null ? undefined : event)}
                                        closeOnSelect
                                        disablePast
                                        format="dd-MMM-yyyy"
                                        slotProps={{
                                            textField: {
                                                label: "Due Date",
                                                fullWidth: true,
                                                variant: "filled",
                                                InputProps: {
                                                    disableUnderline: true,
                                                    classes: classes
                                                },
                                                sx: {
                                                    marginTop: 2
                                                },
                                                helperText: errors.dueDateInUTC === undefined ? "" : "Due date is required",
                                                error: errors.dueDateInUTC === undefined ? false : true
                                            }
                                        }}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    border: '1px dotted #AEB0BC',
                                    marginTop: 2,
                                    backgroundColor: "#F5F5F5",
                                    padding: 1,
                                    position: "relative"
                                }}
                            >
                                {selectedFile && (
                                    <IconButton
                                        aria-label="close"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setSelectedFile(undefined);
                                        }}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            zIndex: 10
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                )}
                                
                                <Box
                                    component={"div"}
                                    sx={{
                                        marginTop: 1
                                    }}
                                >
                                    <img height={40} width={40} src={"/assets/excelUploadIcon.svg"} alt="Upload Icon" />
                                </Box>
                                <Box>
                                        <Box
                                            sx={{ display: "flex", alignItems: "center" }}
                                            {...getRootProps()}
                                        >
                                            <input {...getInputProps()} />
                                            <Typography mr={1} onClick={(e) => e.stopPropagation()}>{selectedFile ? selectedFile.name : "Drop your PDF's here or"}</Typography>
                                            {selectedFile ? <EditOutlinedIcon sx={{ ml: 1 }} fontSize="small" /> : <Button variant="outlined" size="small">Browse</Button>}
                                        </Box>

                                </Box>
                            </Box>
                        </Grid>
                    </>
                )}
            </Grid>
        </GenericDialog>
    );
}

export default AddOrUpdateTaskDialog;
