import { useContext, useState } from "react";
import GenericDialog from "../genericDialog/GenericDialog";
import { BoothXError } from "../../models/BoothXError";
import { ToastContext } from "../../contexts/ToastContext";
import { SubmitHandler, useForm } from "react-hook-form";
import { Grid, List, ListItem, ListItemText, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import styles from './AddOrUpdateEmailTemplateDialog.module.css';
import { useDropzone } from "react-dropzone";
import { EmailTemplateType, EmailTemplateTypes, IEmailData, IEmailTemplateView, displayNameForEmailTemplateType } from "../../models/EmailTemplate";
import { verifyEmailTemplate } from "../../api/EmailTemplateApis";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddOrUpdateEmailTemplateConfirmationDialog from "../addEmailTemplatetConfirmationDialog/AddEmailTemplatetConfirmationDialog";
import { emailTemplateConfig } from "../../utils/configs/ConfigProvider";

const classes = { root: styles.input };

interface IProps {
    emailTemplate?: IEmailTemplateView;
    onClose: (didAddEmailTemplate: boolean, emailTemplate?: IEmailTemplateView) => void;
}

const AddOrUpdateEmailTemplateDialog: React.FC<IProps> = (props) => {
    const { onClose, emailTemplate } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [selectedHeaderImageFile, setSelectedHeaderImageFile] = useState<File | null>(null);
    const [showAddEmailTemplateDialog, setShowAddEmailTemplateDialog] = useState(false);
    const [emailData, setEmailData] = useState<IEmailData | undefined>();

    const { showToast } = useContext(ToastContext);

    const { register, handleSubmit, formState: { errors }, clearErrors, setValue, getValues } = useForm<IEmailData>({
        defaultValues: emailTemplate
    });

    const { open } = useDropzone({
        onDropAccepted: (acceptedFiles) => setSelectedHeaderImageFile(acceptedFiles[0]),
        onDropRejected: () => showToast("File size cannot be more than 5 MB", "error"),
        multiple: false,
        maxSize: 5 * 1024 * 1024,
        accept: {
            'image/jpeg': [],
            'image/png': []
        }
    });

    const verifyTemplate: SubmitHandler<IEmailData> = async (data) => {
        setIsLoading(true);
        const response = await verifyEmailTemplate(data, selectedHeaderImageFile);
        setIsLoading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }
        setEmailData(data);
        setShowAddEmailTemplateDialog(true);
    }

    const handleAddEmailTemplateConfirmationDialogClose = (didAddDocument: boolean, emailTemplate?: IEmailTemplateView) => {
        setShowAddEmailTemplateDialog(false);
        setEmailData(undefined);
        if (didAddDocument) {
            onClose(true, emailTemplate);
        }
    }

    const emailVariables = emailTemplateConfig();

    return (
        <GenericDialog
            title={`${emailTemplate ? "Update" : "Add"} Email Template`}
            isOpen={true}
            primaryButtonText={emailTemplate ? "Update" : "Add"}
            primaryButtonLoading={isLoading}
            primaryButtonAction={handleSubmit(verifyTemplate)}
            onClose={() => {
                onClose(false);
            }}
        >
            {showAddEmailTemplateDialog && emailData &&
                <AddOrUpdateEmailTemplateConfirmationDialog
                    emailData={emailData}
                    headerImageFile={selectedHeaderImageFile}
                    emailTemplate={emailTemplate}
                    onClose={handleAddEmailTemplateConfirmationDialogClose}
                />
            }

            <Grid container spacing={0} padding={0}>
                <Grid item xl={6} md={6} lg={6} sm={6} xs={12} paddingRight={{ sm: 1 }}>
                    <Typography component="div" sx={{
                        textAlign: "left",
                        fontWeight: "600",
                        mt: 2,
                        mb: 1,
                        display: "flex",
                        justifyContent: "start"
                    }}>
                        Template Name
                    </Typography>
                    <TextField
                        {...register("name", { required: true })}
                        variant="outlined"
                        InputProps={{
                            classes: classes
                        }}
                        margin="none"
                        fullWidth
                        name="name"
                        autoComplete="off"
                        defaultValue={emailTemplate?.name}
                        error={errors.name === undefined ? false : true}
                        helperText={errors.name === undefined ? "" : "Template name is required"}
                        onChange={(event) => {
                            clearErrors("name");
                            setValue("name", event.target.value);
                        }}
                    />
                </Grid>
                <Grid item xl={6} md={6} lg={6} sm={6} xs={12} paddingLeft={{ sm: 1 }}>
                    <Typography component="div" sx={{
                        textAlign: "left",
                        fontWeight: "600",
                        mt: 2,
                        mb: 1,
                        display: "flex",
                        justifyContent: "start"
                    }}>
                        Template Type
                    </Typography>
                    <TextField
                        {...register("type", { required: true })}
                        variant="outlined"
                        select
                        SelectProps={{
                            defaultValue: emailTemplate?.type ?? EmailTemplateType.TEXT,
                            displayEmpty: true
                        }}
                        InputProps={{
                            classes: classes
                        }}
                        margin="none"
                        fullWidth
                        name="type"
                        autoComplete="off"
                        error={errors.type === undefined ? false : true}
                        helperText={errors.type === undefined ? "" : "type is required"}
                        onChange={(event) => {
                            clearErrors("type");
                            if (event.target.value) {
                                setValue("type", event.target.value as EmailTemplateType);
                            }
                        }}
                    >
                        {EmailTemplateTypes.map((type) => (
                            <MenuItem key={type} value={type}>
                                {displayNameForEmailTemplateType(type)}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    <Typography component="div" sx={{
                        textAlign: "left",
                        fontWeight: "600",
                        mt: 2,
                        mb: 1,
                        display: "flex",
                        justifyContent: "start"
                    }}>
                        Email subject&nbsp;
                        <Tooltip
                            placement="top"
                            title={
                                <>
                                    <Typography>
                                        Supported placeholders that will be replaced with actual values:
                                    </Typography>
                                    <List>
                                        {emailVariables.variables.filter((variable) => variable.supportedFor.includes("subject"))
                                            .map((variable, index) =>
                                                <ListItem disablePadding key={`subject_${index}`}>
                                                    <ListItemText primary={`${index + 1}. ${variable.symbol} - ${variable.description}`} />
                                                </ListItem>
                                            )
                                        }
                                    </List>
                                </>
                            }
                            arrow
                        >
                            <InfoOutlinedIcon fontSize="small" />
                        </Tooltip>
                    </Typography>
                    <TextField
                        {...register("subjectTemplate", { required: true })}
                        variant="outlined"
                        InputProps={{
                            classes: classes
                        }}
                        margin="none"
                        fullWidth
                        name="emailSubjectTemplate"
                        autoComplete="off"
                        defaultValue={emailTemplate?.subjectTemplate ?? emailVariables.defaultSubject}
                        error={errors.subjectTemplate === undefined ? false : true}
                        helperText={errors.subjectTemplate === undefined ? "" : "Email subject is required"}
                        onChange={(event) => {
                            clearErrors("subjectTemplate");
                            setValue("subjectTemplate", event.target.value);
                        }}
                    />
                </Grid>
                <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    <Typography component="div" sx={{
                        textAlign: "left",
                        fontWeight: "600",
                        mt: 2,
                        mb: 1,
                        display: "flex",
                        justifyContent: "start"
                    }}>
                        Email body&nbsp;
                        <Tooltip
                            placement="top"
                            title={
                                <>
                                    <Typography>
                                        Supported placeholders that will be replaced with actual values:
                                    </Typography>
                                    <List>
                                        {emailVariables.variables.filter((variable) => variable.supportedFor.includes("body"))
                                            .map((variable, index) =>
                                                <ListItem disablePadding key={`body_${index}`}>
                                                    <ListItemText primary={`${index + 1}. ${variable.symbol} - ${variable.description}`} />
                                                </ListItem>
                                            )
                                        }
                                    </List>
                                </>
                            }
                            arrow
                        >
                            <InfoOutlinedIcon fontSize="small" />
                        </Tooltip>
                    </Typography>
                    <TextField
                        {...register("bodyTemplate", { required: true })}
                        variant="outlined"
                        InputProps={{
                            classes: classes
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="none"
                        fullWidth
                        name="bodyTemplate"
                        autoComplete="off"
                        multiline
                        defaultValue={emailTemplate?.bodyTemplate ?? emailVariables.defaultBody}
                        minRows={4}
                        error={errors.bodyTemplate === undefined ? false : true}
                        helperText={errors.bodyTemplate === undefined ? "" : "Email boby is required"}
                        onChange={(event) => {
                            clearErrors("bodyTemplate");
                            setValue("bodyTemplate", event.target.value);
                        }}
                    />
                </Grid>
                {getValues("type") === EmailTemplateType.TEXT_WITH_HEADER_IMAGE && (
                    <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                        <Typography
                            component="div"
                            sx={{
                                textAlign: "left",
                                fontWeight: "600",
                                mt: 2,
                                mb: 1,
                                display: "flex",
                                justifyContent: "start"
                            }}>
                            Header Image:&nbsp;
                            <button onClick={open}>Select</button>&nbsp;
                            <Typography component="span">
                                {selectedHeaderImageFile && selectedHeaderImageFile.name}
                            </Typography>
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </GenericDialog>
    );
}

export default AddOrUpdateEmailTemplateDialog;
