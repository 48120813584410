import { Bar, BarChart, CartesianGrid, Cell, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import style from "./GenericBarChart.module.css";
import { IKeyValuePair } from "../../models/Chart";

export interface IGenericBarChartProps<T extends IKeyValuePair> {
    data: T[];
    tooltipFormatter: (data: T) => string;
    barLabelFormatter?: (data: T, dataKey?: string) => string;
    xAxisLabelFormatter?: (key: string, index: number) => string;
    barCategoryGap?: number;
    minHeight?: number;
    barLabelKey?: string;
    dataKeys?: string[];
    colors?: string[];
    stack?: boolean;
}

function GenericBarChart<T extends IKeyValuePair>(props: IGenericBarChartProps<T>) {
    const {
        data,
        tooltipFormatter,
        barLabelFormatter,
        xAxisLabelFormatter = (key) => key,
        barCategoryGap = 10,
        minHeight = 300,
        barLabelKey = "key",
        dataKeys = ["value"],
        colors,
        stack = false,
    } = props;

    const barColors = colors ?? [
        '#64B5FC',
        '#5B3A96',
        '#CE1D26',
        '#FFA600',
        '#7FC91B',
    ];

    const CustomTooltip = (data: any) => {
        if (data.active && data.payload && data.payload.length) {
            return (
                <div className={style.genericBarChart_tooltipContainer}>
                    {tooltipFormatter(data.payload[0].payload as T)}
                </div>
            );
        }

        return null;
    };

    const CustomizedXAxisTick = (props: {
        x: number,
        y: number,
        payload: { value: string, index: number },
        [other: string]: any
    }) => {
        const { x, y, payload } = props;
        return (
            <g transform={`translate(${x},${y})`}>
                <text
                    x={0}
                    y={0}
                    dy={10}
                    textAnchor="middle"
                    fill="#000000"
                    fontWeight="bold"
                    fontSize={12}
                >
                    {xAxisLabelFormatter(payload.value, payload.index)}
                </text>
            </g>
        );
    };
    return (
        <ResponsiveContainer height={"100%"} minHeight={minHeight}>
            <BarChart data={data} barCategoryGap={barCategoryGap} maxBarSize={60}>
                <CartesianGrid vertical={false} stroke="#EEEEEE" />
                <XAxis
                    dataKey={barLabelKey}
                    interval={0}
                    tick={CustomizedXAxisTick}
                />
                <YAxis />
                <Tooltip content={CustomTooltip} />

                {dataKeys.map((dataKey, index) => (
                    <Bar
                        key={index}
                        dataKey={dataKey}
                        stackId={stack ? 1 : undefined}
                        isAnimationActive={false}
                    >
                        <LabelList
                            position="centerTop"
                            valueAccessor={(props: any) =>
                                barLabelFormatter
                                    ? barLabelFormatter(props.payload as T, dataKey)
                                    : ""
                            }
                            style={{
                                fontWeight: 500,
                                fill: "white",
                                fontSize: 14,
                            }}
                        />
                        {data.map((_, subInd) => (
                            <Cell
                                key={`cell-${subInd}${index}`}
                                fill={barColors[index % barColors.length]}
                                strokeWidth={1.5}
                            />
                        ))}
                    </Bar>
                ))}
            </BarChart>
        </ResponsiveContainer>
    );

}

export default GenericBarChart;
