import { Card, CardContent, Typography } from "@mui/material";
import React, { ReactNode } from "react";

interface IProps {
    stat: number | string | ReactNode;
    description: string;
    borderBottomColor: string;
}

export const DashboardCard: React.FC<IProps> = (props) => {
    const { stat, description, borderBottomColor } = props;
    return (
        <Card sx={{ minWidth: 200, border: 1, borderBottom: 3, borderColor: "#FDFDFD", borderBottomColor: { borderBottomColor } }}>
            <CardContent>
                <Typography color="text.secondary" align="left" fontWeight="semiBold" fontSize={14}>
                    {description}
                </Typography>
                <Typography fontSize={22} align="left">
                    {stat}
                </Typography>
            </CardContent>
        </Card>
    );
};
