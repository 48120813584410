import { Box, Button, Container, FilledInput, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PasswordRecoveryType } from "../../types/PasswordRecoveryType";
import { Loading } from "../loading/Loading";
import { SubmitHandler, useForm } from "react-hook-form";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import styles from "./SetPasswordForm.module.css";
import { ToastContext } from "../../contexts/ToastContext";
import { resetPassword } from "../../api/Auth";
import { BoothXError } from "../../models/BoothXError";
import { Auth } from "../../utils/Auth";

const classes = { root: styles.input };

interface ISetPassword {
  newPassword: string,
  confirmPassword: string
}

export const SetPasswordForm = () => {
  
  const toast = useContext(ToastContext);
  const location = useLocation();
  const search = location.search;
  const searchParams = new URLSearchParams(search);
  const type = searchParams.get('type');
  const code = searchParams.get('code');
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!type || type?.length === 0 || !code || code?.length === 0) {
      navigate("/recover")
    }
  });

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const { register, handleSubmit, formState: { errors }, clearErrors, watch, setValue } = useForm<ISetPassword>();

  let titleText = "Reset Password"
  
  if (type === PasswordRecoveryType.FIRST_TIME_PASSWORD_CREATION) {
    titleText = "Set Password"
  }

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit: SubmitHandler<ISetPassword> = async (data) => {
    setIsLoading(true);
    const response = await resetPassword(data.newPassword, code ?? "");
    setIsLoading(false);

    if (response instanceof BoothXError) {
        toast.showToast(response.message, "error");
        return;
    }

    if (type === PasswordRecoveryType.FIRST_TIME_PASSWORD_CREATION) {
      toast.showToast(
        "Password has been set successfully",
        "success"
      )
    } else {
      toast.showToast(
        "Password has been reset successfully",
        "success"
      )
    }

    const error = await Auth.getInstance().signInWithToken(response.customToken)
    if (error instanceof BoothXError) {
      toast.showToast(error.message, "error")
      return;
    }

    Auth.getInstance().saveUserSettings(response.settings);
    navigate("/");
}

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
  };

  return (
    <Container
      sx={{
        border: { xs: 'none', sm: 1 },
        borderRadius: 2,
        py: 5,
        px: 5,
        boxShadow: { xs: 'none', sm: 2 }
      }}
      maxWidth="xs"
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          
          <Typography component="h1" variant="h5">
            {titleText}
          </Typography>

          <FormControl
            variant="filled"
            fullWidth
            margin="normal"
          >
            <InputLabel
              htmlFor="filled-adornment-new-password"
              error={errors.newPassword === undefined ? false : true}
            >
              New Password
            </InputLabel>
            <FilledInput
              {...register("newPassword", { 
                required: true, 
                minLength: 8,
                validate: (val: string) => {
                  if (watch('confirmPassword') !== val) {
                    return "";
                  }
                }
              })}
              name="newPassword"
              id="filled-adornment-new-password"
              type={showNewPassword ? "text" : "password"}
              disableUnderline
              autoComplete="new-password"
              classes={classes}
              error={errors.newPassword === undefined ? false : true}
              onChange={(event) => {
                  clearErrors("newPassword");
                  setValue("newPassword", event.target.value as string);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText
              id="my-helper-text"
              error={errors.newPassword === undefined ? false : true}
            >
              {errors.newPassword !== undefined && errors.newPassword.type === "required" && "Password is required"}
              {errors.newPassword !== undefined && errors.newPassword.type === "minLength" && "Password should be at least 8 characters"}
            </FormHelperText>
          </FormControl>

          <FormControl
            variant="filled"
            fullWidth
            margin="normal"
          >
            <InputLabel
              htmlFor="filled-adornment-confirm-password"
              error={errors.confirmPassword === undefined ? false : true}
            >
              Confirm Password
            </InputLabel>
            <FilledInput
              {...register("confirmPassword", { 
                required: true, 
                minLength: 8, 
                validate: (val: string) => {
                  if (watch('newPassword') !== val) {
                    return "Your password does not match.";
                  }
                }})
              }
              name="confirmPassword"
              id="filled-adornment-confirm-password"
              type={showConfirmPassword ? "text" : "password"}
              disableUnderline
              autoComplete="confirm-password"
              classes={classes}
              error={errors.confirmPassword === undefined ? false : true}
              onChange={(event) => {
                  clearErrors("confirmPassword");
                  setValue("confirmPassword", event.target.value as string);
              }}
              endAdornment={
                  <InputAdornment position="end">
                      <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                      >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                  </InputAdornment>
              }
            />
            <FormHelperText
              id="my-helper-text"
              error={errors.confirmPassword === undefined ? false : true}
            >
              {errors.confirmPassword !== undefined && errors.confirmPassword.type === "required" && "Password is required"}
              {errors.confirmPassword !== undefined && errors.confirmPassword.type === "minLength" && "Password should be at least 8 characters"}
              {errors.confirmPassword !== undefined && errors.confirmPassword.type === "validate" && errors.confirmPassword.message}
            </FormHelperText>
          </FormControl>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoading}
          >
              <Loading text={"Submit"} isLoading={isLoading} />
          </Button>
      </Box>
    </Container>
  );
}
