import { Grid } from "@mui/material";
import { SetPasswordForm } from "../../components/setPasswordForm/SetPasswordForm";

const SetPasswordPage = () => {

    return (
        <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}>
            <Grid item xl={5} md={5} lg={5} sm={12} xs={12}>
                <SetPasswordForm />
            </Grid>
        </Grid>
    );

}

export default SetPasswordPage;
