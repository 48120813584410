import { BoothXError } from "../models/BoothXError";
import { IGetSettingsResponse } from "../models/User";
import { BACKEND_URL_V1, post } from "../utils/Api";

interface ILoginResponse {
  customToken: string;
  settings: IGetSettingsResponse
}

interface IPasswordResetRequest {
  resetToken: string;
  password: string;
}

export const resetPassword = async (
    password: string,
    resetToken: string
  ): Promise<ILoginResponse | BoothXError> => {
  
    try {
      const passwordResetRequest: IPasswordResetRequest = {
        resetToken: resetToken,
        password: password,
      }
      const response = await post<ILoginResponse>(`${BACKEND_URL_V1}/signup/resetPassword`, passwordResetRequest);
  
      if (Math.floor(response.status / 100) === 2 && response.parsedBody !== undefined) {
        return response.parsedBody;
      } else if (Math.floor(response.status / 100) === 4) {
        return new BoothXError("Session expired! try again");
      }
  
      const errorMessage = response.serverError?.error.message ?? "Unable to reset password";
      return new BoothXError(errorMessage);
    } catch (err) {
      console.log(err);
      return new BoothXError("Something went wrong");
    }
  }
  
  export const sendPasswordResetEmail = async (
    email: string,
  ): Promise<undefined | BoothXError> => {
  
    try {
      const response = await post(`${BACKEND_URL_V1}/signup/resetPasswordLink`, {email});
  
      if (Math.floor(response.status / 100) === 2) {
        return undefined;
      }
  
      const errorMessage = response.serverError?.error.message ?? "Unable to reset password";
      return new BoothXError(errorMessage);
    } catch (err) {
      console.log(err);
      return new BoothXError("Something went wrong");
    }
  }
