import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import style from "./GenericLineChart.module.css";
import { IKeyValuePair } from "../../models/Chart";

export interface IGenericLineChartProps<T extends IKeyValuePair> {
    data: T[];
    tooltipFormatter: (data: T) => string;
    xAxisLabelFormatter?: (key: string, index: number) => string;
    yAxisLabelFormatter?: (value: number) => string;
    dataKeys: string[];
    minHeight?: number;
    lineColors?: string[];
}

function GenericLineChart<T extends IKeyValuePair>(
    props: IGenericLineChartProps<T>
) {
    const {
        data,
        tooltipFormatter,
        xAxisLabelFormatter = (key) => key,
        yAxisLabelFormatter = (value) => value.toString(),
        dataKeys,
        minHeight = 300,
        lineColors = ["#8884d8", "#82ca9d"],
    } = props;

    const CustomTooltip = (data: any) => {
        if (data.active && data.payload && data.payload.length) {
            return (
                <div className={style.genericLineChart_tooltipContainer}>
                    {tooltipFormatter(data.payload[0].payload as T)}
                </div>
            );
        }
        return null;
    };

    return (
        <ResponsiveContainer height={"100%"} minHeight={minHeight}>
            <LineChart data={data}>
                <CartesianGrid vertical={false} stroke="#EEEEEE" />
                <XAxis
                    dataKey="key"
                    interval={0}
                    tick={{
                        fontSize: 12,
                        fontWeight: "bold",
                    }}
                    tickFormatter={xAxisLabelFormatter}
                />
                <YAxis
                    tick={{
                        fontSize: 12,
                        fontWeight: "bold",
                    }}
                    tickFormatter={yAxisLabelFormatter}
                />
                <Tooltip content={CustomTooltip} />

                {dataKeys.map((dataKey, index) => (
                    <Line
                        key={index}
                        type="monotone"
                        dataKey={dataKey}
                        stroke={lineColors[index % lineColors.length]}
                        strokeWidth={2}
                        dot={{ fill: lineColors[index % lineColors.length] }}
                        activeDot={{ r: 6 }}
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
}

export default GenericLineChart;
