import React, { useContext, useState } from 'react';
import { Typography, Box, TextField, Button, Container, Grid, Select, MenuItem, OutlinedInput } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Loading } from '../loading/Loading';
import { BoothXError } from '../../models/BoothXError';
import { ToastContext } from '../../contexts/ToastContext';
import { ICompanyView } from '../../models/Company';
import { updateCompany } from '../../api/CompanyApi';
import { ICompanySignupRequest } from '../../api/Signup';
import { canUserUpdateCompanyProfile } from '../../utils/AuthUtils';
import { getSupportedCurrencyCodes, getSymbolForCurrency } from '../../utils/Utils';


interface IProps {
    companyProfile: ICompanyView
}

const UpdateCompanyForm: React.FC<IProps> = (props) => {
    const { companyProfile } = props;

    const [isEditable, setIsEditable] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { showToast } = useContext(ToastContext);

    const { register, handleSubmit, formState: { errors }, clearErrors, setValue } = useForm<ICompanySignupRequest>();

    const onSubmit: SubmitHandler<ICompanySignupRequest> = async (companyData) => {
        setIsEditable(false);

        setIsLoading(true);
        const response = await updateCompany((companyProfile.id).toString(), companyData);
        setIsLoading(false);

        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            setIsLoading(false)
            return;
        }

        showToast("Company details are updated successfully", "success");
    };

    const handleSaveClick = () => {
        handleSubmit(onSubmit)();
    }

    const handleEditClick = () => {
        setIsEditable(true);
    }

    const allowUpdate = canUserUpdateCompanyProfile();

    return (
        <Container
            component="main"
            maxWidth="sm"
        >
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Grid item xl={6} md={6} lg={6} sm={12} xs={12} sx={{ padding: 2 }}>
                    <Box component="div"
                        sx={{
                            mb: 3,
                            display: "flex",
                            justifyContent: "space-between",
                        }} >
                        <Typography
                            component="h1"
                            variant="h6"
                            sx={{
                                textAlign: "left",
                                fontWeight: "600",
                            }}
                        >
                            Company Details
                        </Typography>

                        {allowUpdate && (
                            <Button
                                type="button"
                                variant={isEditable ? "contained" : "outlined"}
                                sx={{
                                    width: "6rem"
                                }}
                                disabled={isLoading}
                                onClick={isEditable ? handleSaveClick : handleEditClick}
                            >
                                <Loading isLoading={isLoading} text={isEditable ? "Save" : "Edit"} />
                            </Button>
                        )}

                    </Box>
                    <Box>
                        <Typography
                            component="div"
                            sx={{
                                textAlign: "left",
                                fontWeight: "600",
                                mb: 1
                            }}
                        >
                            Company Name
                        </Typography>
                        <TextField
                            {...register("companyName", { required: true })}
                            variant="outlined"
                            margin="none"
                            name="name"
                            fullWidth
                            defaultValue={companyProfile.name}
                            autoComplete="off"
                            size="small"
                            InputProps={{
                                readOnly: isEditable ? false : true,
                            }}
                            sx={{
                                margin: "none",
                                padding: "none",
                                mb: 2,
                                backgroundColor: isEditable ? '#fff' : undefined
                            }}
                            error={errors.companyName === undefined ? false : true}
                            helperText={errors.companyName === undefined ? "" : "Company name is required"}
                            onChange={(event) => {
                                clearErrors("companyName");
                                setValue("companyName", event.target.value);
                            }}
                        />
                        <Typography
                            component="div"
                            sx={{
                                textAlign: "left",
                                fontWeight: "600",
                                mb: 1
                            }}
                        >
                            Company Website
                        </Typography>
                        <TextField
                            {...register("companyWebsite", { required: true })}
                            variant="outlined"
                            margin="none"
                            name="name"
                            fullWidth
                            defaultValue={companyProfile.website}
                            autoComplete="off"
                            size="small"
                            InputProps={{
                                readOnly: isEditable ? false : true,
                            }}
                            sx={{
                                margin: "none",
                                padding: "none",
                                mb: 2,
                                backgroundColor: isEditable ? '#fff' : undefined
                            }}
                            error={errors.companyWebsite === undefined ? false : true}
                            helperText={errors.companyWebsite === undefined ? "" : "Company website is required"}
                            onChange={(event) => {
                                clearErrors("companyWebsite");
                                setValue("companyWebsite", event.target.value);
                            }}
                        />

                        <Typography
                            component="div"
                            sx={{
                                textAlign: "left",
                                fontWeight: "600",
                                mb: 1
                            }}
                        >
                            Address
                        </Typography>
                        <TextField
                            {...register("companyAddress", { required: true })}
                            variant="outlined"
                            margin="none"
                            name="name"
                            fullWidth
                            defaultValue={companyProfile.address}
                            autoComplete="off"
                            size="small"
                            InputProps={{
                                readOnly: isEditable ? false : true,
                            }}
                            sx={{
                                margin: "none",
                                padding: "none",
                                mb: 2,
                                backgroundColor: isEditable ? '#fff' : undefined
                            }}
                            error={errors.companyAddress === undefined ? false : true}
                            helperText={errors.companyAddress === undefined ? "" : "Company Address is required"}
                            onChange={(event) => {
                                clearErrors("companyAddress");
                                setValue("companyAddress", event.target.value);
                            }}
                        />

                        <Typography
                            component="div"
                            sx={{
                                textAlign: "left",
                                fontWeight: "600",
                                mb: 1
                            }}
                        >
                            State
                        </Typography>
                        <TextField
                            {...register("companyState", { required: true })}
                            variant="outlined"
                            margin="none"
                            name="name"
                            fullWidth
                            defaultValue={companyProfile.state}
                            autoComplete="off"
                            size="small"
                            InputProps={{
                                readOnly: isEditable ? false : true,
                            }}
                            sx={{
                                margin: "none",
                                padding: "none",
                                mb: 2,
                                backgroundColor: isEditable ? '#fff' : undefined
                            }}
                            error={errors.companyState === undefined ? false : true}
                            helperText={errors.companyState === undefined ? "" : "Company State is required"}
                            onChange={(event) => {
                                clearErrors("companyState");
                                setValue("companyState", event.target.value);
                            }}
                        />

                        <Typography
                            component="div"
                            sx={{
                                textAlign: "left",
                                fontWeight: "600",
                                mb: 1
                            }}
                        >
                            Pincode
                        </Typography>
                        <TextField
                            {...register("companyPincode", { required: true })}
                            variant="outlined"
                            margin="none"
                            name="name"
                            fullWidth
                            defaultValue={companyProfile.pincode}
                            autoComplete="off"
                            size="small"
                            InputProps={{
                                readOnly: isEditable ? false : true,
                            }}
                            sx={{
                                margin: "none",
                                padding: "none",
                                mb: 2,
                                backgroundColor: isEditable ? '#fff' : undefined
                            }}
                            error={errors.companyPincode === undefined ? false : true}
                            helperText={errors.companyPincode === undefined ? "" : "Company pincode is required"}
                            onChange={(event) => {
                                clearErrors("companyPincode");
                                setValue("companyPincode", event.target.value);
                            }}
                        />
                        <Typography
                            component="div"
                            sx={{
                                textAlign: "left",
                                fontWeight: "600",
                                mb: 1
                            }}
                        >
                            Home currency
                        </Typography>
                        <Select
                            {...register("homeCurrency", { required: true })}
                            fullWidth
                            readOnly={!isEditable}
                            defaultValue={companyProfile.homeCurrency}
                            input={
                                <OutlinedInput
                                    fullWidth
                                    sx={{
                                        textAlign: "left",
                                        backgroundColor: isEditable ? '#fff' : undefined
                                    }} />
                            }
                        >
                            {getSupportedCurrencyCodes.map((currency) => (
                                <MenuItem
                                    key={currency}
                                    value={currency}
                                >{currency} ({getSymbolForCurrency(currency)})</MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Grid>
            </Box>
        </Container>
    );
}

export default UpdateCompanyForm;
