import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { Loading } from "../loading/Loading";
import { useContext, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import styles from "./PasswordRecoveryForm.module.css";
import { sendPasswordResetEmail } from "../../api/Auth";
import { BoothXError } from "../../models/BoothXError";
import { ToastContext } from "../../contexts/ToastContext";

const classes = { root: styles.input };

interface IPasswordRecovery {
    email: string
}

export const PasswordRecoveryForm = () => {
    
    const [isLoading, setIsLoading] = useState(false);
    
    const toast = useContext(ToastContext);

    const onSubmit: SubmitHandler<IPasswordRecovery> = async (data) => {
        setIsLoading(true);
        const response = await sendPasswordResetEmail(data.email);
        setIsLoading(false);

        if (response instanceof BoothXError) {
            toast.showToast(response.message, "error");
            return;
        }
        toast.showToast(
            "Recovery link has been sent to the registered email successfully",
            "success"
        )
        setValue("email", "");
    }

    const { register, handleSubmit, formState: { errors }, clearErrors, setValue } = useForm<IPasswordRecovery>();
    
    return (
        <Container
            sx={{
                border: { xs: 'none', sm: 1 },
                borderRadius: 2,
                py: 5,
                px: 5,
                boxShadow: { xs: 'none', sm: 2 }
            }}
            maxWidth="xs"
        >

            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                
                <Typography component="h1" variant="h5">
                    {"Password Recovery"}
                </Typography>

                <p style={{ fontSize: "1em" }}>{"A recovery link will be sent to the registered email id"}</p>

                <TextField
                    {...register("email", { required: true })}
                    variant="filled"
                    InputProps={{
                        disableUnderline: true,
                        classes: classes
                    }}
                    margin="normal"
                    fullWidth
                    label="Email Id"
                    type="email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    error={errors.email === undefined ? false : true}
                    helperText={errors.email === undefined ? "" : "Email Id is required"}
                    onChange={(event) => {
                        clearErrors("email");
                        setValue("email", event.target.value as string);
                    }}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={isLoading}
                >
                    <Loading text={"Submit"} isLoading={isLoading} />
                </Button>
            </Box>
          </Container>
    );

}
