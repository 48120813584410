import { Card, CardContent, Grid, Typography } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Auth } from '../../utils/Auth';
import { ToastContext } from '../../contexts/ToastContext';
import { BoothXError } from '../../models/BoothXError';
import { DashboardStatsType, IDashboardStats } from '../../models/Dashboard';
import { getDashboardStats } from '../../api/DashboardApi';
import { Loading } from '../../components/loading/Loading';
import { DateTime } from 'luxon';
import ExpensesLabel from '../../components/expensesLabel/ExpensesLabel';
import GenericPieChart from '../../components/genericPieChart/GenericPieChart';
import { capitalizeFirstLetter, displayAmountWithCurrency, getInitials, getSymbolForCurrency } from '../../utils/Utils';
import GenericBarChart from '../../components/genericBarChart/GenericBarChart';
import { IKeyValuePair } from '../../models/Chart';
import { DashboardCard } from '../../components/dashboardCard/DashboardCard';

interface ISalesmenLeadsPieChartData extends IKeyValuePair { }

interface ISalesmenExpensesPieChartData extends IKeyValuePair {
    name: string;
    currency: string;
}

interface ICategoryExpensesPieChartData extends IKeyValuePair {
    currency: string;
}

interface IEventCostPerLeadPieChartData extends IKeyValuePair {
    eventName: string;
    currency: string;
}

interface IEventDuplicateLeadsCount extends IKeyValuePair {
    eventName: string;
    duplicateLeadsCount: number;
}

function getLabelForTotalLeads(type: DashboardStatsType) {
    if (type === DashboardStatsType.ALL_EVENTS) {
        return "Leads created (all events)";
    }

    if (type === DashboardStatsType.ASSIGNED_EVENTS) {
        return "Leads created (assigned events)";
    }

    return "Leads created by you";
}

function getLabelForCostPerLead(type: DashboardStatsType) {
    if (type === DashboardStatsType.ALL_EVENTS) {
        return "Cost per lead (all events)";
    }

    if (type === DashboardStatsType.ASSIGNED_EVENTS) {
        return "Cost per lead (assigned events)";
    }

    return "Cost per lead created by you";
}

function getLabelForTotalExpenses(type: DashboardStatsType) {
    if (type === DashboardStatsType.ALL_EVENTS) {
        return "Total expenses (all events)";
    }

    if (type === DashboardStatsType.ASSIGNED_EVENTS) {
        return "Total expenses (assigned events)";
    }

    return "All your expenses";
}

const DashboardPage: React.FC = React.memo(() => {
    const [stats, setStats] = useState<IDashboardStats>();
    const [salesmenLeadStats, setSalesmenLeadStats] = useState<ISalesmenLeadsPieChartData[]>();
    const [salesmenExpenseStats, setSalesmenExpenseStats] = useState<ISalesmenExpensesPieChartData[]>();
    const [categoryExpenseStats, setCategoryExpenseStats] = useState<ICategoryExpensesPieChartData[]>();
    const [eventsCostPerLeadStats, setEventsCostPerLeadStats] = useState<IEventCostPerLeadPieChartData[]>();
    const [eventsDuplicateLeadsStats, setEventsDuplicateLeadsStats] = useState<IEventDuplicateLeadsCount[]>();
    const [lastUpdated, setLastUpdated] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const userSettings = Auth.getInstance().getUserSettings();
    const { showToast } = useContext(ToastContext);

    const fetchDashboardStats = useCallback(async () => {
        setIsLoading(true);
        const response = await getDashboardStats();
        setIsLoading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        setStats(response.stats);
        setLastUpdated(response.lastUpdated);
    }, [showToast]);

    useEffect(() => {
        fetchDashboardStats();
    }, [fetchDashboardStats]);

    useEffect(() => {
        const salesmenStats = stats?.salesmenStats;
        if (!salesmenStats) {
            return;
        }

        const salesmenLeadStats: ISalesmenLeadsPieChartData[] = [];
        const salesmenExpenseStats: ISalesmenExpensesPieChartData[] = [];
        salesmenStats.forEach((salesmanStats) => {
            if (salesmanStats.totalLeads > 0) {
                salesmenLeadStats.push({ key: salesmanStats.name, value: salesmanStats.totalLeads });
            }

            if (salesmanStats.totalExpenses > 0) {
                salesmenExpenseStats.push({ key: salesmanStats.userId, value: salesmanStats.totalExpenses, name: salesmanStats.name, currency: salesmanStats.expenseCurrency })
            }
        });
        setSalesmenLeadStats(salesmenLeadStats);
        setSalesmenExpenseStats(salesmenExpenseStats);
    }, [stats]);

    useEffect(() => {
        const expensesPerCategory = stats?.expensesPerCategory;
        if (!expensesPerCategory) {
            return;
        }

        const categoryExpenseStats: ICategoryExpensesPieChartData[] = [];
        const homeCurrency = stats.homeCurrency;
        for (const [key, value] of Object.entries(expensesPerCategory)) {
            if (value === 0) {
                continue;
            }

            categoryExpenseStats.push({ key: capitalizeFirstLetter(key), value: value, currency: homeCurrency });
        }
        setCategoryExpenseStats(categoryExpenseStats);
    }, [stats]);

    useEffect(() => {
        const eventsStats = stats?.eventsStats;
        if (!eventsStats) {
            return;
        }

        const eventsCostPerLeadStats: IEventCostPerLeadPieChartData[] = [];
        const homeCurrency = stats.homeCurrency;
        eventsStats.forEach((eventStats) => {
            eventsCostPerLeadStats.push({ key: eventStats.eventId, value: eventStats.costPerLead, eventName: eventStats.eventName, currency: homeCurrency })
        });

        setEventsCostPerLeadStats(eventsCostPerLeadStats);


        const eventsDuplicateLeadsStats: IEventDuplicateLeadsCount[] = [];
        eventsStats.forEach((eventStats) => {
            eventsDuplicateLeadsStats.push({ key: eventStats.eventName, eventName: eventStats.eventName, value: (eventStats.leadsCount - eventStats.duplicateLeadsCount), duplicateLeadsCount: eventStats.duplicateLeadsCount })
        });
        setEventsDuplicateLeadsStats(eventsDuplicateLeadsStats);
    }, [stats]);

    return (
        <Grid container spacing={2} padding={3}>
            <Grid item md={12} sm={12}>
                <Typography variant="h5" component="div" sx={{ textAlign: "left" }}>
                    Hello {userSettings?.profileView.displayName}
                </Typography>
                {lastUpdated && (
                    <Typography component="div" sx={{ textAlign: "left", fontStyle: "italic", fontSize: "12px" }}>
                        Last updated: {DateTime.fromSeconds(lastUpdated).toFormat("dd/MMM/yyyy hh:mm a")}
                    </Typography>
                )}
            </Grid>
            {isLoading && <Loading isLoading />}
            {!isLoading && stats && (
                <>
                    <Grid item md={3} sm={12}>
                        <DashboardCard
                            stat={stats.totalEvents}
                            description={stats.type === DashboardStatsType.ALL_EVENTS ? "Events created" : "Events assigned"}
                            borderBottomColor={"#177CE5"}
                        />
                    </Grid>
                    <Grid item md={3} sm={12}>
                        <DashboardCard
                            stat={stats.totalLeads}
                            description={getLabelForTotalLeads(stats.type)}
                            borderBottomColor={"#F05B57"}
                        />
                    </Grid>
                    <Grid item md={3} sm={12}>
                        <DashboardCard
                            stat={displayAmountWithCurrency(stats.overallCostPerLead, stats.homeCurrency, 2)}
                            description={getLabelForCostPerLead(stats.type)}
                            borderBottomColor={"#FCAD00"}
                        />
                    </Grid>
                    <Grid item md={3} sm={12}>
                        <DashboardCard
                            stat={<ExpensesLabel totalExpensesMap={stats.totalExpensesMap} fontSize={22} justifyContent="left" />}
                            description={getLabelForTotalExpenses(stats.type)}
                            borderBottomColor={"#6AA012"}
                        />
                    </Grid>
                    <Grid item md={3} sm={12}>
                        <DashboardCard
                            stat={stats.averageLeadsPerEvent.toFixed(2)}
                            description={"Avg leads per event"}
                            borderBottomColor={"#177CE5"}
                        />
                    </Grid>
                    <Grid item md={3} sm={12}>
                        <DashboardCard
                            stat={stats.averageDailyLeads ? stats.averageDailyLeads.toFixed(2) : "-"}
                            description={"Avg daily leads"}
                            borderBottomColor={"#F05B57"}
                        />
                    </Grid>
                    <Grid item md={3} sm={12}>
                        <DashboardCard
                            stat={0}
                            description={"Avg lead per event of others"}
                            borderBottomColor={"#FCAD00"}
                        />
                    </Grid>
                    <Grid item md={3} sm={12}>
                        <DashboardCard
                            stat={0}
                            description={"Carbon emission reduction"}
                            borderBottomColor={"#6AA012"}
                        />
                    </Grid>
                    {salesmenLeadStats && salesmenLeadStats.length > 0 && (
                        <Grid item md={6} sm={12}>
                            <Card sx={{ border: 1, borderColor: "#FDFDFD" }}>
                                <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                                    <Typography color="#000000" align="left" fontWeight="bold" marginBottom={3}>
                                        Leads per sales person
                                    </Typography>
                                    {salesmenLeadStats.length > 0 && (
                                        <GenericBarChart
                                            data={salesmenLeadStats}
                                            tooltipFormatter={(data) => `${data.key}: ${data.value} ${(data.value && data.value > 1) ? "leads" : "lead"}`}
                                            barLabelFormatter={(data) => `${data.value} ${(data.value && data.value > 1) ? "leads" : "lead"}`}
                                            xAxisLabelFormatter={(key) => getInitials(key)}
                                            colors={["#F05B57"]}
                                        />
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                    {eventsDuplicateLeadsStats && eventsDuplicateLeadsStats.length > 0 && (
                        <Grid item md={6} sm={12}>
                            <Card sx={{ border: 1, borderColor: "#FDFDFD" }}>
                                <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                                    <Typography color="#000000" align="left" fontWeight="bold" marginBottom={3}>
                                        Event Wise Leads + Duplicate Leads (last {eventsDuplicateLeadsStats.length} events)
                                    </Typography>
                                    {eventsDuplicateLeadsStats.length > 0 && (
                                        <GenericBarChart
                                            data={eventsDuplicateLeadsStats}
                                            tooltipFormatter={(data) => `Unique: ${data.value} leads, Duplicate: ${data.duplicateLeadsCount} leads`}
                                            barLabelFormatter={(data, dataKey) => {
                                                if (dataKey === "value" && data.value) {
                                                    return data.value > 0 ? `${data.value} leads` : ""
                                                }
                                                if (dataKey === "duplicateLeadsCount") {
                                                    return data.duplicateLeadsCount > 0 ? `${data.duplicateLeadsCount} leads` : ""
                                                }
                                                return "";
                                            }}
                                            xAxisLabelFormatter={(key) => getInitials(key)}
                                            dataKeys={['duplicateLeadsCount', 'value']}
                                            stack={true}
                                            colors={["#820908", "#FF6361"]}
                                        />
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                    {categoryExpenseStats && categoryExpenseStats.length > 0 && (
                        <Grid item md={6} sm={12}>
                            <Card sx={{ border: 1, borderColor: "#FDFDFD" }}>
                                <CardContent sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography color="#000000" align="left" fontWeight="bold">
                                        Expenses per category
                                    </Typography>
                                    {categoryExpenseStats.length > 0 && (
                                        <GenericPieChart
                                            data={categoryExpenseStats}
                                            colors={["#003870", "#0A579E", "#1578CF", "#249CFF", "#77C2FE"]}
                                            toolTipLabelFormatter={(data) => `${data.key}: ${getSymbolForCurrency(data.currency)}${data.value}`}
                                            minHeight={310}
                                        />
                                    )}
                                    <Typography align="right" sx={{ fontSize: 10 }}>(*approximate currency exchange rates)</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                    {salesmenExpenseStats && salesmenExpenseStats.length > 0 && (
                        <Grid item md={6} sm={12}>
                            <Card sx={{ border: 1, borderColor: "#FDFDFD" }}>
                                <CardContent sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography color="#000000" align="left" fontWeight="bold">
                                        Expenses per sales person
                                    </Typography>
                                    {salesmenExpenseStats.length > 0 && (
                                        <GenericPieChart
                                            data={salesmenExpenseStats}
                                            colors={["#62BEB6", "#0B9A8D", "#077368", "#034D44", "#002B24"]}
                                            toolTipLabelFormatter={(data) => `${data.name}: ${getSymbolForCurrency(data.currency)}${data.value}`}
                                            minHeight={310}
                                            lineDisplayLabelFormatter={(data) => getInitials(data.name)}
                                        />
                                    )}

                                    <Typography align="right" sx={{ fontSize: 10 }}>(*approximate currency exchange rates)</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                    {eventsCostPerLeadStats && eventsCostPerLeadStats.length > 0 && (
                        <Grid item md={6} sm={12}>
                            <Card sx={{ border: 1, borderColor: "#FDFDFD" }}>
                                <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                                    <Typography color="#000000" align="left" fontWeight="bold" marginBottom={3}>
                                        Cost per lead (last {eventsCostPerLeadStats.length} events)
                                    </Typography>
                                    {eventsCostPerLeadStats.length > 0 && (
                                        <GenericBarChart
                                            data={eventsCostPerLeadStats}
                                            tooltipFormatter={(data) => `${data.eventName}: ${displayAmountWithCurrency(data.value!, data.currency, 2)}`}
                                            barLabelFormatter={(data) => `${displayAmountWithCurrency(data.value!, data.currency, 0, "compact")}`}
                                            xAxisLabelFormatter={(_, index) => `${getInitials(eventsCostPerLeadStats.at(index)!.eventName)}`}
                                            colors={["#fcad00"]}
                                            barLabelKey="eventName"
                                        />
                                    )}
                                    <Typography align="right" sx={{ fontSize: 10 }}>(*approximate currency exchange rates)</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}

                </>
            )}
        </Grid>
    );
});

export default DashboardPage;
