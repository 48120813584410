
export enum EmailTemplateType {
    TEXT = "TEXT",
    TEXT_WITH_HEADER_IMAGE = "TEXT_WITH_HEADER_IMAGE"
}

export const EmailTemplateTypes = [EmailTemplateType.TEXT, EmailTemplateType.TEXT_WITH_HEADER_IMAGE];

export interface IEmailData {
    type: EmailTemplateType;
    name: string;
    subjectTemplate: string;
    bodyTemplate: string;
}

export interface IEmailTemplateView {
    id: string;
    name: string;
    type: EmailTemplateType;
    creationTimeInUTC: number;
    subjectTemplate: string;
    bodyTemplate: string;
    headerImagePath: string;
}

export function displayNameForEmailTemplateType(type: EmailTemplateType) {
    switch (type) {
        case EmailTemplateType.TEXT:
            return "Text only";
        case EmailTemplateType.TEXT_WITH_HEADER_IMAGE:
            return "Text with header image";
    }
} 
