import React, { useContext, useEffect, useState } from 'react';
import { Typography, Box, TextField, Button, Container, FormControl, IconButton, InputAdornment, InputLabel, FilledInput, FormHelperText, Link } from '@mui/material';
import styles from './LoginForm.module.css';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Auth } from '../../utils/Auth';
import { Loading } from '../loading/Loading';
import { getSettings } from '../../api/UsersApi';
import { BoothXError } from '../../models/BoothXError';
import { useNavigate } from 'react-router-dom';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { ToastContext } from '../../contexts/ToastContext';
import { TrapType } from '../../types/TrapType';
import { IGetSettingsResponse } from '../../models/User';
import { getPagesForCapabilites } from '../../models/Page';

const classes = { root: styles.input };

type LoginRequest = {
    emailId: string,
    password: string,
};

const LoginForm: React.FC = React.memo(() => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { showToast } = useContext(ToastContext);

    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, clearErrors, setValue } = useForm<LoginRequest>();

    const onSubmit: SubmitHandler<LoginRequest> = async (data) => {
        setIsLoading(true);
        const error = await Auth.getInstance().loginWithEmailAndPassword(data.emailId, data.password);
        if (error) {
            setIsLoading(false);
            showToast(error.message, "error");
            return;
        }

        const response = await getSettings();
        setIsLoading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }
        handleTrapIfRequired(response);
    };

    const handleTrapIfRequired = (response: IGetSettingsResponse) => {
        if (response.trap && response.trap.trapType === TrapType.COMPANY_REGISTRATION) {
            navigate("/signup/company");
        } else {
            setIsLoggedIn(true);
        }
    }

    useEffect(() => {
        if (isLoggedIn) {
            const userSettings = Auth.getInstance().getUserSettings();
            if (!userSettings) {
                return;
            }

            const pages = getPagesForCapabilites(userSettings.profileView.capabilities);
            navigate(pages[0].href);
        }
    }, [isLoggedIn, navigate]);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <Container
            sx={{
                border: { xs: 'none', sm: 1 },
                borderRadius: 2,
                pt: 5,
                pb: 2,
                px: 5,
                boxShadow: { xs: 'none', sm: 2 }
            }}
            maxWidth="xs"
        >
            <Typography component="h1" variant="h5">
                Login
            </Typography>
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    {...register("emailId", { required: true })}
                    variant="filled"
                    InputProps={{
                        disableUnderline: true,
                        classes: classes
                    }}
                    margin="normal"
                    fullWidth
                    label="Email Id"
                    type="email"
                    name="emailId"
                    autoComplete="email"
                    autoFocus
                    error={errors.emailId === undefined ? false : true}
                    helperText={errors.emailId === undefined ? "" : "Email Id is required"}
                    onChange={(event) => {
                        clearErrors("emailId");
                        setValue("emailId", event.target.value as string);
                    }}
                />

                <FormControl
                    variant="filled"
                    fullWidth
                    margin="normal"
                >
                    <InputLabel
                        htmlFor="filled-adornment-password"
                        error={errors.password === undefined ? false : true}
                    >
                        Password
                    </InputLabel>
                    <FilledInput
                        {...register("password", { required: true })}
                        name="password"
                        id="filled-adornment-password"
                        type={showPassword ? "text" : "password"}
                        disableUnderline
                        autoComplete="current-password"
                        classes={classes}
                        error={errors.password === undefined ? false : true}
                        onChange={(event) => {
                            clearErrors("password");
                            setValue("password", event.target.value as string);
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText
                        id="my-helper-text"
                        error={errors.password === undefined ? false : true}
                    >
                        {errors.password === undefined ? "" : "Password is required"}
                    </FormHelperText>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-end',
                            typography: 'body2',
                            '& > :not(style) + :not(style)': {
                                ml: 2,
                            },
                        }}
                    >
                        <Link href="/recover" underline="always">
                            Forgot Password?
                        </Link>
                    </Box>
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                    disabled={isLoading}
                >
                    <Loading isLoading={isLoading} text="Login" />
                </Button>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        typography: 'body1',
                        '& > :not(style) + :not(style)': {
                            ml: 2,
                        },
                    }}
                >
                    Dont have an account yet?&nbsp;
                    <Link href="/signup/user" underline="always">
                        Sign Up
                    </Link>
                </Box>
                <Typography
                    sx={{
                        mt: 2,
                        fontSize: "0.7rem"
                    }}
                >
                    <Link href="/terms-of-service" underline="always" target="_blank">Terms</Link> | <Link href="/privacy-policy" underline="always" target="_blank">Privacy Policy</Link>
                </Typography>
            </Box>
        </Container>
    );
});

export default LoginForm;
