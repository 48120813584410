import { ErrorCode } from "./BoothXServerError";

export class BoothXError {
    message: string;
    errorCode?: ErrorCode

    constructor(message: string, errorCode?: ErrorCode) {
        this.message = message;
        this.errorCode = errorCode;
    }

    public getErrorMessage() {
        if (this.errorCode) {
            return this.getErrorMessageForErrorCode(this.errorCode);
        }

        return this.message;
    }

    private getErrorMessageForErrorCode(errorCode: ErrorCode) {
        switch (errorCode) {
            case ErrorCode.ENTITY_NOT_FOUND:
                return "User not found";
        }
    }
}
