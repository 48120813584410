import { Capability } from "./Capability";

export enum Feature {
    APP_ACCESS = "APP_ACCESS",
    MANAGE_ASSIGNED_EVENTS = "MANAGE_ASSIGNED_EVENTS",
    MANAGE_ALL_EVENTS = "MANAGE_ALL_EVENTS",
    BILLING = "BILLING",
    ADD_UPDATE_USERS = "ADD_UPDATE_USERS",
    ADD_UPDATE_DOCUMENTS = "ADD_UPDATE_DOCUMENTS",
    ADD_UPDATE_EMAIL_TEMPLATES = "ADD_UPDATE_EMAIL_TEMPLATES",
    ADD_UPDATE_TASKS = "ADD_UPDATE_TASKS",
}

export const getAllFeatures = () => {
    return Object.values(Feature);
}

export function displayNameForFeature(feature: Feature) {
    switch (feature) {
        case Feature.APP_ACCESS:
            return "Add leads and expenses via BoothX mobile app";
        case Feature.MANAGE_ASSIGNED_EVENTS:
            return "View and manage assigned events (no create access)";
        case Feature.MANAGE_ALL_EVENTS:
            return "View and manage all events (no create access)";
        case Feature.ADD_UPDATE_TASKS:
            return "Add and update tasks";
        case Feature.BILLING:
            return "Create events, manage add ons, billing etc.";
        case Feature.ADD_UPDATE_USERS:
            return "Add and update users";
        case Feature.ADD_UPDATE_DOCUMENTS:
            return "Add and update documents";
        case Feature.ADD_UPDATE_EMAIL_TEMPLATES:
            return "Add and update email templates";
    }
}

export function capabilitiesForFeature(feature: Feature) {
    switch (feature) {
        case Feature.APP_ACCESS:
            return [
                Capability.VIEW_EVENT,
                Capability.MANAGE_LEADS,
                Capability.MANAGE_EXPENSES
            ];

        case Feature.MANAGE_ASSIGNED_EVENTS:
            return [
                Capability.VIEW_USERS,
                Capability.MANAGE_EVENT,
                Capability.MANAGE_ALL_LEADS,
                Capability.MANAGE_ALL_EXPENSES,
                Capability.VIEW_DOCUMENTS,
                Capability.VIEW_EMAIL_TEMPLATES,
                Capability.VIEW_TASKS
            ];

        case Feature.MANAGE_ALL_EVENTS:
            return [
                Capability.VIEW_USERS,
                Capability.MANAGE_ALL_EVENTS,
                Capability.MANAGE_ALL_LEADS,
                Capability.MANAGE_ALL_EXPENSES,
                Capability.VIEW_DOCUMENTS,
                Capability.VIEW_EMAIL_TEMPLATES,
                Capability.VIEW_TASKS
            ];

        case Feature.BILLING:
            return [
                Capability.USER_MANAGEMENT,
                Capability.CREATE_EVENT,
                Capability.BUY_EVENT_ADD_ONS,
                Capability.MANAGE_ALL_EVENTS,
                Capability.MANAGE_ALL_LEADS,
                Capability.MANAGE_ALL_EXPENSES,
                Capability.MANAGE_DOCUMENTS,
                Capability.MANAGE_EMAIL_TEMPLATES,
                Capability.MANAGE_TASKS
            ];

        case Feature.ADD_UPDATE_USERS:
            return [Capability.USER_MANAGEMENT];

        case Feature.ADD_UPDATE_DOCUMENTS:
            return [Capability.MANAGE_DOCUMENTS];

        case Feature.ADD_UPDATE_EMAIL_TEMPLATES:
            return [Capability.MANAGE_EMAIL_TEMPLATES];

        case Feature.ADD_UPDATE_TASKS:
            return [Capability.MANAGE_TASKS];
    }
}

export function featuresForCapabilities(capabilities: Capability[]) {
    const allFeatures = Object.values(Feature);

    const applicableFeatures: Feature[] = [];

    allFeatures.forEach((feature) => {
        const featureCapabilities = capabilitiesForFeature(feature);
        const isFeatureApplicable = featureCapabilities.every(capability => capabilities.includes(capability));
        if (isFeatureApplicable) {
            applicableFeatures.push(feature);
        }
    });

    return applicableFeatures;
}
