
interface ISupportedCurrency {
    currencyCode: string
    currencySymbol: string
}

const supportedCurrencies: ISupportedCurrency[] = [
    {
        currencyCode: "USD",
        currencySymbol: "$"
    },
    {
        currencyCode: "INR",
        currencySymbol: "₹"
    },
];

export const getCurrencySymbol = (currencyCode: string): string | undefined => {
    const currencyDetails = supportedCurrencies.find(currency => currency.currencyCode === currencyCode)
    return currencyDetails?.currencySymbol;
}
