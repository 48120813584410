import { useCallback, useContext, useEffect, useState } from "react";
import { ToastContext } from "../../contexts/ToastContext";
import { IGetEventInsightsResponse, getEventInsights } from "../../api/EventsApi";
import { BoothXError } from "../../models/BoothXError";
import { Loading } from "../loading/Loading";
import { DateTime } from "luxon";
import { DashboardCard } from "../dashboardCard/DashboardCard";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { IKeyValuePair } from "../../models/Chart";
import GenericLineChart from "../genericLineChart/GenericLineChart";
import { capitalizeFirstLetter, displayAmountWithCurrency, getSymbolForCurrency } from "../../utils/Utils";
import GenericPieChart from "../genericPieChart/GenericPieChart";

export interface IEventInsightsProps {
    eventId: string;
}

interface IDateToNumberOfLeads extends IKeyValuePair {
    duplicateLeads: number;
}

interface IDateToExpense extends IKeyValuePair {
    date: DateTime;
    value: number;
}

interface ICategoryExpensesPieChartData extends IKeyValuePair {
    currency: string;
}

export const EventInsights = (props: IEventInsightsProps) => {
    const { eventId } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [insights, setInsights] = useState<IGetEventInsightsResponse>();
    const [lastUpdated, setLastUpdated] = useState<number>();
    const { showToast } = useContext(ToastContext);

    const fetchEventInsights = useCallback(async () => {
        setIsLoading(true);
        const response = await getEventInsights(eventId);
        setIsLoading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }
        setInsights(response);
        setLastUpdated(response.lastUpdated);
    }, [showToast, eventId]);

    useEffect(() => {
        fetchEventInsights();
    }, [fetchEventInsights]);

    const datesToNumberOfLeadsArray: IDateToNumberOfLeads[] = [];
    const datesToExpenseArray: IDateToExpense[] = [];
    const categoryExpenseStats: ICategoryExpensesPieChartData[] = [];
    let homeCurrency: string = "";

    if (insights) {
        homeCurrency = insights.homeCurrency;
        Object.entries(insights.datesToEventInsightsMap).forEach(([dateString, eventInsights]) => {
            const date = DateTime.fromFormat(dateString, 'dd-MM-yyyy').toLocal();
            const dateFormatted = date.toFormat('dd-MM');

            datesToNumberOfLeadsArray.push({
                key: dateFormatted,
                value: eventInsights.numberOfLeads,
                duplicateLeads: eventInsights.duplicateLeadsCount,
            });

            datesToExpenseArray.push({
                key: dateFormatted,
                date: date,
                value: eventInsights.totalExpenses,
            });
        });

        datesToNumberOfLeadsArray.sort((a, b) => DateTime.fromFormat(a.key, 'dd-MM').toMillis() - DateTime.fromFormat(b.key, 'dd-MM').toMillis());
        datesToExpenseArray.sort((a, b) => a.date.toMillis() - b.date.toMillis());

        const expensesPerCategory = insights.expensesPerCategory;
        if (expensesPerCategory) {
            for (const [key, value] of Object.entries(expensesPerCategory)) {
                if (value === 0) {
                    continue;
                }

                categoryExpenseStats.push({ key: capitalizeFirstLetter(key), value: value, currency: homeCurrency });
            }
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item md={12} sm={12}>
                {lastUpdated && (
                    <Typography component="div" sx={{ textAlign: "left", fontStyle: "italic", fontSize: "12px" }}>
                        Last updated: {DateTime.fromSeconds(lastUpdated).toFormat("dd/MMM/yyyy hh:mm a")}
                    </Typography>
                )}
            </Grid>
            {isLoading && <Loading isLoading={isLoading} />}
            {!isLoading && insights && (
                <>
                    {insights.isSingleDayEvent ? (
                        <>
                            <Grid item md={3} sm={12}>
                                <DashboardCard
                                    stat={Object.values(insights.datesToEventInsightsMap)[0].numberOfLeads}
                                    description="Total Leads"
                                    borderBottomColor={"#177CE5"}
                                />
                            </Grid>
                            <Grid item md={3} sm={12}>
                                <DashboardCard
                                    stat={displayAmountWithCurrency(Object.values(insights.datesToEventInsightsMap)[0].totalExpenses, homeCurrency, 2)}
                                    description={"Total Expenses"}
                                    borderBottomColor={"#F05B57"}
                                />
                            </Grid>
                            <Grid item md={3} sm={12}>
                                <DashboardCard
                                    stat={Object.values(insights.datesToEventInsightsMap)[0].duplicateLeadsCount}
                                    description={"Total duplicate leads"}
                                    borderBottomColor={"#FCAD00"}
                                />
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item md={6} sm={12}>
                                <Card sx={{ border: 1, borderColor: "#FDFDFD" }}>
                                    <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                                        <Typography color="#000000" align="left" fontWeight="bold" marginBottom={3}>
                                            Daily Leads
                                        </Typography>
                                        <GenericLineChart
                                            data={datesToNumberOfLeadsArray}
                                            tooltipFormatter={(data) => `${data.key}: Leads - ${data.value}, Duplicates - ${data.duplicateLeads}`}
                                            xAxisLabelFormatter={(key) => key}
                                            yAxisLabelFormatter={(value) => value.toString()}
                                            dataKeys={['value', 'duplicateLeads']} // Pass both value and duplicateLeads as data keys
                                            lineColors={["#FF6361", "#00b39b"]} // Assign colors to each line
                                        />


                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item md={6} sm={12} paddingTop={2}>
                                <Card sx={{ border: 1, borderColor: "#FDFDFD" }}>
                                    <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                                        <Typography color="#000000" align="left" fontWeight="bold" marginBottom={3}>
                                            Daily expenses
                                        </Typography>
                                        <GenericLineChart
                                            data={datesToExpenseArray.map(({ date, value }) => ({
                                                key: date.toFormat('dd-MM'),
                                                value: value,
                                            }))}
                                            tooltipFormatter={(data) => `${data.key}: ${getSymbolForCurrency(homeCurrency)}${data.value}`}
                                            xAxisLabelFormatter={(key) => key}
                                            yAxisLabelFormatter={(value) => `${value}`}
                                            dataKeys={['value']}
                                            lineColors={["#005aba"]}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </>
                    )}
                    <Grid item md={6} sm={12} paddingTop={2}>
                        <Card sx={{ border: 1, borderColor: "#FDFDFD" }}>
                            <CardContent sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                <Typography color="#000000" align="left" fontWeight="bold">
                                    Expenses per category
                                </Typography>
                                {categoryExpenseStats.length > 0 && (
                                    <GenericPieChart
                                        data={categoryExpenseStats}
                                        toolTipLabelFormatter={(data) => `${data.key}: ${getSymbolForCurrency(data.currency)}${data.value}`}
                                        minHeight={310}
                                    />
                                )}
                                <Typography align="right" sx={{ fontSize: 10 }}>(*approximate currency exchange rates)</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </>
            )}
        </Grid>
    );
};
