import { BoothXError } from "../models/BoothXError";
import { BACKEND_URL_V1, post } from "../utils/Api";

export interface IUserSignupRequest {
    userFirstName: string;
    userLastName: string;
    userEmail: string;
    userPassword: string;
}

export interface ICompanySignupRequest {
    companyName: string;
    companyWebsite: string;
    companyAddress: string;
    companyState: string;
    companyPincode: string;
    companyCountry: string;
    companyDomain?: string; // TODO
    companyLogoPath?: string; // TODO

    homeCurrency?: string;
}

export const signupUser = async (request: IUserSignupRequest): Promise<null | BoothXError> => {
    try {
        const response = await post(`${BACKEND_URL_V1}/signup/user`, request);
        if (Math.floor(response.status / 100) === 2) {
            return null;
        }

        const errorMessage = response.serverError?.error.message ?? "User could not be created";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
};

export const signupCompany = async (request: ICompanySignupRequest): Promise<null | BoothXError> => {
    try {
        const response = await post(`${BACKEND_URL_V1}/signup/company`, request);
        if (Math.floor(response.status / 100) === 2) {
            return null;
        }

        const errorMessage = response.serverError?.error.message ?? "Company could not be created";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
};
