import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import GenericTable from "../../components/genericTable/GenericTable";
import { ToastContext } from "../../contexts/ToastContext";
import { BoothXError } from "../../models/BoothXError";
import { DateTime } from "luxon";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Loading } from "../../components/loading/Loading";
import { IEmailTemplateView, displayNameForEmailTemplateType } from "../../models/EmailTemplate";
import { getEmailTemplates } from "../../api/EmailTemplateApis";
import DeleteEmailTemplatetConfirmationDialog from "../../components/deleteEmailTemplatetConfirmationDialog/DeleteEmailTemplatetConfirmationDialog";
import AddOrUpdateEmailTemplateDialog from "../../components/addOrUpdateEmailTemplateDialog/AddOrUpdateEmailTemplateDialog";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { canUserAddOrUpdateEmailTemplates } from "../../utils/AuthUtils";

const EmailTemplatesPage: React.FC = React.memo(() => {
    const [emailTemplates, setEmailTemplates] = useState<IEmailTemplateView[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<IEmailTemplateView>();
    const [showDeleteEmailTemplateConfirmationDialog, setShowDeleteEmailTemplateConfirmationDialog] = useState<boolean>(false);
    const { showToast } = useContext(ToastContext);
    const allowAddAndUpdateEmailTemplates = canUserAddOrUpdateEmailTemplates();

    const [showAddOrUpdateEmailTemplateDialog, setShowAddEmailTemplateDialog] = useState<boolean>(false);

    const fetchEmailTemplates = useCallback(async () => {
        setIsLoading(true);
        const response = await getEmailTemplates();
        setIsLoading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        const sortedEmailTemplates = response.sort((a, b) => { return a.name.localeCompare(b.name) });
        setEmailTemplates(sortedEmailTemplates);
    }, [showToast]);

    const handleDeleteEmailTemplateConfirmationDialogClose = (didDeleteEmailTemplate: boolean) => {
        setShowDeleteEmailTemplateConfirmationDialog(false);
        setSelectedEmailTemplate(undefined);
        if (didDeleteEmailTemplate) {
            fetchEmailTemplates();
        }
    }

    const handleAddEmailTemplateDialogClose = (didAddEmailTemplate: boolean) => {
        setShowAddEmailTemplateDialog(false);
        setSelectedEmailTemplate(undefined);
        if (didAddEmailTemplate) {
            fetchEmailTemplates();
        }
    }

    useEffect(() => {
        fetchEmailTemplates();
    }, [fetchEmailTemplates]);

    return (
        <Box sx={{ padding: 3 }}>
            {showAddOrUpdateEmailTemplateDialog && <AddOrUpdateEmailTemplateDialog emailTemplate={selectedEmailTemplate} onClose={handleAddEmailTemplateDialogClose} />}
            {showDeleteEmailTemplateConfirmationDialog && selectedEmailTemplate && <DeleteEmailTemplatetConfirmationDialog emailTemplate={selectedEmailTemplate} onClose={handleDeleteEmailTemplateConfirmationDialogClose} />}
            <Box
                component="div"
                sx={{
                    mb: 2,
                    display: "flex",
                    justifyContent: { xs: "flex-end", sm: "space-between" },
                }}
            >
                <Typography
                    component="h1"
                    variant="h6"
                    sx={{
                        textAlign: "left",
                        fontWeight: "600",
                        display: { xs: "none", sm: "block" } // hide on mobile
                    }}
                >
                    All Email Templates
                </Typography>
                {allowAddAndUpdateEmailTemplates && (
                    <Button
                        aria-label="add"
                        variant="contained"
                        onClick={() => setShowAddEmailTemplateDialog(true)}
                    >
                        <Loading isLoading={false} text="Add Email Template" />
                    </Button>
                )}
            </Box>
            <Grid container spacing={2}>
                <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    <GenericTable
                        data={emailTemplates}
                        headers={[
                            { header: "Template Name" },
                            { header: "Type" },
                            { header: "Date Added" },
                            ...(allowAddAndUpdateEmailTemplates ? [{ header: "Actions" }] : []),
                        ]}
                        isLoading={isLoading}
                        dataRenderer={(emailTemplateRow, column) => {
                            switch (column) {
                                case 0:
                                    return emailTemplateRow.name;
                                case 1:
                                    return displayNameForEmailTemplateType(emailTemplateRow.type);
                                case 2:
                                    return DateTime.fromSeconds(emailTemplateRow.creationTimeInUTC).toLocaleString(DateTime.DATE_MED);
                                case 3:
                                    return (
                                        <>
                                            <IconButton
                                                aria-label="edit"
                                                color="primary"
                                                size="small"
                                                onClick={() => {
                                                    setSelectedEmailTemplate(emailTemplateRow);
                                                    setShowAddEmailTemplateDialog(true);
                                                }}
                                            >
                                                <EditOutlinedIcon />
                                            </IconButton>
                                            <IconButton
                                                aria-label="delete"
                                                color="primary"
                                                size="small"
                                                onClick={() => {
                                                    setSelectedEmailTemplate(emailTemplateRow);
                                                    setShowDeleteEmailTemplateConfirmationDialog(true);
                                                }}
                                            >
                                                <DeleteOutlineOutlinedIcon />
                                            </IconButton>
                                        </>
                                    );
                            }
                        }}
                    />
                </Grid >
            </Grid>
        </Box>
    );
});

export default EmailTemplatesPage;
