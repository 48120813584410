import { ReactNode, useCallback, useContext, useEffect, useState } from "react"
import { createBillingSession, getBillingPlanConfig } from "../../api/Billing";
import { ToastContext } from "../../contexts/ToastContext";
import { BoothXError } from "../../models/BoothXError";
import { EventPlanType, IBillingPlanConfig, PaymentSessionStatus } from "../../models/Billing";
import GenericTable from "../genericTable/GenericTable";
import { displayNameForPlan } from "../../utils/BillingUtils";
import { displayAmountWithCurrency } from "../../utils/Utils";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Loading } from "../loading/Loading";
import { useNavigate } from "react-router-dom";



export const PricingPlan = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingBillingSession, setIsLoadingBillingSession] = useState<boolean>(false);
    const [billingPlanConfig, setBillingPlanConfig] = useState<IBillingPlanConfig>();
    const { showToast } = useContext(ToastContext);
    const [selectedPlan, setSelectedPlan] = useState<EventPlanType>();
    const navigate = useNavigate();


    const CATEGORY = "Category";
    const MAX_NUMBER_OF_LEADS = "Max Number of Leads";
    const MAX_LEADS_EXPORT = "Max Leads Export";
    const MAX_DOCUMENTS = "Max Documents";
    const MAX_EXPENSE_TRACKING = "Max Expense Tracking";
    const MAX_USERS = "Max Users";
    const DATA_RETENTION_PERIOD = "Data Retention Period in Days";
    const PLAN_TYPE = "PlanType";

    const fetchBillingConfig = useCallback(async () => {
        setIsLoading(true);
        const response = await getBillingPlanConfig();
        setIsLoading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        setBillingPlanConfig(response.billingPlanConfig);
    }, [showToast]);

    useEffect(() => {
        fetchBillingConfig();
    }, [fetchBillingConfig]);

    const tableHeaderForPlanType = (planType: EventPlanType) => {
        return { header: displayNameForPlan(planType) };
    }

    const isCurrentSelectedPlan = (planType?: string) => {
        return planType === selectedPlan;
    }

    const getPricingTableHeaders = (config: IBillingPlanConfig) => {
        var headers = [];
        headers.push({ header: "Features" });
        config.planCosts.forEach(planCost => {
            headers.push(tableHeaderForPlanType(planCost.eventPlanType));
        }
        );
        return headers;
    }

    const features = [
        CATEGORY,
        MAX_NUMBER_OF_LEADS,
        MAX_LEADS_EXPORT,
        MAX_DOCUMENTS,
        MAX_EXPENSE_TRACKING,
        MAX_USERS,
        DATA_RETENTION_PERIOD,
        PLAN_TYPE
    ]

    interface IBillingRow {
        feature: string;
        values: string[];
    }

    const buildTableData = (config: IBillingPlanConfig) => {
        var data: IBillingRow[] = [];
        features.forEach(feature => {
            var values = [];
            values.push(feature);
            // for each feature, go through each plan and get the value for that feature
            config.planCosts.forEach(planCost => {
                switch (feature) {
                    case PLAN_TYPE:
                        values.push(planCost.eventPlanType);
                        break;
                    case CATEGORY:
                        values.push(displayAmountWithCurrency(planCost.currencyAmount.amount, planCost.currencyAmount.currency));
                        break;
                    case MAX_NUMBER_OF_LEADS:
                        values.push(planCost.features.numOfLeadsCaptureAllowed);
                        break;
                    case MAX_LEADS_EXPORT:
                        values.push(planCost.features.numOfLeadsExportAllowed);
                        break;
                    case MAX_DOCUMENTS:
                        values.push(planCost.features.numOfDocumentsAllowed);
                        break;
                    case MAX_EXPENSE_TRACKING:
                        values.push(planCost.features.numOfExpenseTrackingAllowed);
                        break;
                    case MAX_USERS:
                        values.push(planCost.features.numOfUsersAllowed);
                        break;
                    case DATA_RETENTION_PERIOD:
                        values.push(planCost.features.retentionPeriodInDays);
                        break;
                }
            });
            data.push({ feature: feature, values: values });
        });
        return data;
    }


    const selectPlanButton = (planType: EventPlanType) => {
        return <Button
            variant="outlined"
            disabled={isLoadingBillingSession}
            onClick={
                () => {
                    setSelectedPlan(planType);
                    processBillingRequest(planType);
                }
            }
        >
            <Loading
                isLoading={(isLoadingBillingSession && isCurrentSelectedPlan(planType))}
                text="Select Plan"
            />

        </Button>
    }

    const processBillingRequest = async (planType: EventPlanType) => {
        setIsLoadingBillingSession(true);
        const response = await createBillingSession(planType);

        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            setIsLoadingBillingSession(false);
            return;
        }
        if (response.paymentSession.paymentUrl) {
            // intentionally not setting the loading state to false here, as we are redirecting to the payment page
            // prevents a small flickering where spinner stops and then a split second delay before next page loads
            window.location.href = (response.paymentSession.paymentUrl);
            return;
        }

        if (response.paymentSession.status === PaymentSessionStatus.COMPLETED && response.paymentSession.targetEventId) {
            navigate(`/events/${response.paymentSession.targetEventId}`);
            return;
        }

        showToast("Something went wrong, please try again later", "error");
        setIsLoadingBillingSession(false);
    }


    return (
        <Box>
            <Box
                component="div"
                sx={{
                    mb: 2,
                    display: "flex",
                    justifyContent: { xs: "flex-end", sm: "space-between" },
                }}
            >
                <Typography
                    component="h1"
                    variant="h6"
                    sx={{
                        textAlign: "left",
                        fontWeight: "600",
                        display: { xs: "none", sm: "block" }
                    }}
                >
                    Pricing Plan
                </Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    {!billingPlanConfig && (<Loading isLoading={isLoading} />)}
                    {billingPlanConfig?.planCosts && (
                        <GenericTable
                            isLoading={isLoading}
                            hover={false}
                            headers={getPricingTableHeaders(billingPlanConfig!)}
                            data={buildTableData(billingPlanConfig!)}
                            alignContent="center"
                            dataRenderer={function (dataRow: IBillingRow, column: number): ReactNode {

                                if (dataRow.feature === PLAN_TYPE && column === 0) {
                                    return ""; // no row heading for button row
                                } else if (dataRow.feature === PLAN_TYPE) {
                                    var planType = dataRow.values.at(column) as EventPlanType;
                                    return selectPlanButton(planType);
                                }

                                // first row, after first column is prices, so make those bold
                                var shouldBeBold = (dataRow.feature === CATEGORY && column > 0);
                                return <span
                                    style={{
                                        fontWeight: shouldBeBold ? 'bold' : 'normal'
                                    }}
                                >
                                    {dataRow.values.at(column)}
                                </span>;
                            }}
                        />
                    )}
                </Grid>
            </Grid>
        </Box>
    )
}

export default PricingPlan;
