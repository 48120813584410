import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import GenericTable from "../../components/genericTable/GenericTable";
import { IUserProfileView } from "../../models/User";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ToastContext } from "../../contexts/ToastContext";
import { BoothXError } from "../../models/BoothXError";
import { getUsers } from "../../api/UsersApi";
import AddOrUpdateUserDialog from "../../components/addOrEditUserDialog/AddOrUpdateUserDialog";
import DeleteUserConfirmationDialog from "../../components/deleteUserConfirmationDialog/DeleteUserConfirmationDialog";
import { featuresForCapabilities } from "../../models/Feature";
import { Capability } from "../../models/Capability";
import { canUserAddOrUpdateUsers } from "../../utils/AuthUtils";

const UsersPage: React.FC = React.memo(() => {
    const [users, setUsers] = useState<IUserProfileView[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showAddOrEditUserDialog, setShowAddOrEditUserDialog] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<IUserProfileView>();
    const [showDeleteUserConfirmationDialog, setShowDeleteUserConfirmationDialog] = useState<boolean>(false);
    const { showToast } = useContext(ToastContext);

    const allowAddAndUpdateUser = canUserAddOrUpdateUsers();

    const fetchUsers = useCallback(async () => {
        setIsLoading(true);
        const response = await getUsers();
        setIsLoading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        const sortedUsers = response.sort((a, b) => { return a.displayName.localeCompare(b.displayName) });
        setUsers(sortedUsers);
    }, [showToast]);

    const handleAddOrEditUserDialogClose = (didAddOrEditUser: boolean) => {
        setShowAddOrEditUserDialog(false);
        setSelectedUser(undefined);
        if (didAddOrEditUser) {
            fetchUsers();
        }
    }

    const handleDeleteUserConfirmationDialogClose = (didDeleteUser: boolean) => {
        setShowDeleteUserConfirmationDialog(false);
        setSelectedUser(undefined);
        if (didDeleteUser) {
            fetchUsers();
        }
    }

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    return (
        <Box sx={{ padding: 3 }}>
            {showAddOrEditUserDialog && <AddOrUpdateUserDialog user={selectedUser} onClose={handleAddOrEditUserDialogClose} />}
            {showDeleteUserConfirmationDialog && selectedUser && <DeleteUserConfirmationDialog user={selectedUser} onClose={handleDeleteUserConfirmationDialogClose} />}
            <Box
                component="div"
                sx={{
                    mb: 2,
                    display: "flex",
                    justifyContent: { xs: "flex-end", sm: "space-between" },
                }}
            >
                <Typography
                    component="h1"
                    variant="h6"
                    sx={{
                        textAlign: "left",
                        fontWeight: "600",
                        display: { xs: "none", sm: "block" } // hide on mobile
                    }}
                >
                    User Details
                </Typography>

                {allowAddAndUpdateUser && (
                    <Button
                        aria-label="add"
                        variant="contained"
                        onClick={() => setShowAddOrEditUserDialog(true)}
                    >
                        Add User
                    </Button>
                )}
            </Box>
            <Grid container spacing={2}>
                <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    <GenericTable
                        data={users}
                        headers={[
                            { header: "User Name" },
                            { header: "Email Id" },
                            { header: "No. of Features" },
                            ...(allowAddAndUpdateUser ? [{ header: "Actions" }] : []),
                        ]}
                        isLoading={isLoading}
                        dataRenderer={(userRow, column) => {
                            switch (column) {
                                case 0:
                                    return userRow.displayName;
                                case 1:
                                    return userRow.email;
                                case 2:
                                    const capabilities = userRow.capabilities;
                                    if (capabilities.includes(Capability.ALL) || capabilities.includes(Capability.SUPER)) {
                                        return "All";
                                    }
                                    const features = featuresForCapabilities(capabilities);
                                    return `${features.length} features assigned`
                                case 3:
                                    return (
                                        <>
                                            <IconButton
                                                aria-label="edit"
                                                color="primary"
                                                size="small"
                                                onClick={() => {
                                                    setSelectedUser(userRow);
                                                    setShowAddOrEditUserDialog(true);
                                                }}
                                            >
                                                <EditOutlinedIcon />
                                            </IconButton>
                                            <IconButton
                                                aria-label="delete"
                                                color="primary"
                                                size="small"
                                                onClick={() => {
                                                    setSelectedUser(userRow);
                                                    setShowDeleteUserConfirmationDialog(true);
                                                }}
                                            >
                                                <DeleteOutlineOutlinedIcon />
                                            </IconButton>
                                        </>
                                    );
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
});

export default UsersPage;
