import { Box, Button, Chip, Grid, Typography } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IEventView } from '../../models/Event';
import { getEvents } from '../../api/EventsApi';
import { BoothXError } from '../../models/BoothXError';
import { ToastContext } from '../../contexts/ToastContext';
import GenericTable from '../../components/genericTable/GenericTable';
import { useNavigate } from 'react-router-dom';
import useEventsCache from '../../hooks/EventsHook';
import { DateTime } from 'luxon';
import { canUserCreateEvent } from "../../utils/AuthUtils";
import ExpensesLabel from '../../components/expensesLabel/ExpensesLabel'; import { displayNameForPlan } from '../../utils/BillingUtils';
import { getStatusChip } from '../../utils/EventUtils';
;

const EventsPage: React.FC = React.memo(() => {

    const [events, setEvents] = useState<IEventView[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const allowCreateEvent = canUserCreateEvent();

    const { showToast } = useContext(ToastContext);
    const navigate = useNavigate();
    const { populateEventsCache } = useEventsCache();

    const fetchEvents = useCallback(async () => {
        setIsLoading(true);
        const response = await getEvents();
        setIsLoading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }
        setEvents(response);
    }, [showToast]);

    useEffect(() => {
        fetchEvents();
    }, [fetchEvents]);

    useEffect(() => {
        populateEventsCache(events);
    }, [events, populateEventsCache]);

    const handleCreateEventClicked = () => {
        navigate("/events/create");
    }

    return (
        <Box sx={{ padding: 3 }}>
            <Box
                component="div"
                sx={{
                    mb: 2,
                    display: "flex",
                    justifyContent: { xs: "flex-end", sm: "space-between" },
                }}
            >
                <Typography
                    component="h1"
                    variant="h6"
                    sx={{
                        textAlign: "left",
                        fontWeight: "600",
                        display: { xs: "none", sm: "block" } // hide on mobile
                    }}
                >
                    All Events
                </Typography>
                {allowCreateEvent && (
                    <Button
                        aria-label="add"
                        variant="contained"
                        onClick={handleCreateEventClicked}
                    >
                        Create event
                    </Button>
                )}
            </Box>
            <Grid container spacing={2}>
                <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    <GenericTable
                        headers={[
                            {
                                header: "Event Name",
                                sortByField: "name",
                                filter: {
                                    filterValues: ["Active", "Upcoming"],
                                    isValid: (view: IEventView, selectedFilter: string) => {
                                        if (view.isDraftEvent) {
                                            return true;
                                        }
                                        const now = DateTime.now();
                                        const startDate = DateTime.fromSeconds(view.startDateInUTC);
                                        const endDate = DateTime.fromSeconds(view.endDateInUTC);
                                        if (selectedFilter === "Active") {
                                            if (now.diff(startDate).as("milliseconds") > 0 && now.diff(endDate).as("milliseconds") <= 0) {
                                                return true;
                                            }
                                        } else if (selectedFilter === "Upcoming") {
                                            if (startDate.diff(now).as("milliseconds") > 0) {
                                                return true;
                                            }
                                        }
                                        return false;
                                    }
                                }
                            },
                            { header: "Plan" },
                            { header: "Event Date" },
                            { header: "Leads Generated" },
                            { header: "Expense" },
                        ]}
                        defaultSortByField={"name"}
                        isLoading={isLoading}
                        cursor="pointer"
                        onRowClick={(eventRow) => {
                            if (eventRow.isDraftEvent) {
                                navigate(`/events/${eventRow.id}/edit`);
                            } else {
                                navigate(`/events/${eventRow.id}`);
                            }
                        }}
                        data={events}
                        dataRenderer={(eventRow, column) => {
                            switch (column) {
                                case 0:
                                    return (
                                        <Grid display={"flex"} alignItems={"center"}>
                                            <p>{eventRow.isDraftEvent ? "New Event" : eventRow.name}</p>
                                            {getStatusChip(eventRow)}
                                        </Grid>
                                    );
                                case 1:
                                    return <Chip label={displayNameForPlan(eventRow.eventPlanType)} />
                                case 2:
                                    return eventRow.isDraftEvent ? "" : `${DateTime.fromSeconds(eventRow.startDateInUTC).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)} - ${DateTime.fromSeconds(eventRow.endDateInUTC).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}`;
                                case 3:
                                    return eventRow.isDraftEvent ? "" : eventRow.totalLeadsCount.toFixed();
                                case 4:
                                    return eventRow.isDraftEvent ? "" : <ExpensesLabel totalExpensesMap={eventRow.totalExpensesMap} />;
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
});

export default EventsPage;
