import { getStorage, ref, uploadBytes } from "firebase/storage";
import { BoothXError } from "../models/BoothXError";
import { IEventView } from "../models/Event";
import { getFileExtension } from "../utils/Utils";

export const uploadExpenseReceipt = async (event: IEventView, expenseClientId: string, file: File): Promise<BoothXError | string> => {
    try {
        const storage = getStorage();
        const storagePath = `${event.companyId}/expenseImages/${event.id}/${expenseClientId}.${getFileExtension(file)}`;
        const storageRef = ref(storage, storagePath);
        await uploadBytes(storageRef, file);
        return storagePath;
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}

