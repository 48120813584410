import { useCallback } from "react";
import { IEventView } from "../models/Event";
import { useLocalStorage } from "./LocalStorageHook";

const useEventsCache = () => {
    const [cachedEvents, setCachedEvents] = useLocalStorage<IEventView[]>("events");
    const populateEventsCache = useCallback((events: IEventView[]) => {
        setCachedEvents(events);
    }, [setCachedEvents]);

    const getEventFromCache = useCallback((eventId: string) => {
        return cachedEvents?.find((event) => event.id === eventId);
    }, [cachedEvents]);

    return { populateEventsCache, getEventFromCache };
};

export default useEventsCache;
