export interface IKeyValuePair {
    key: string;
    value: number | undefined;
}

export const chartColors = [
    "#6b1aed",
    "#f05b57",
    "#e69007",
    "#0991e6",
    "#38b100",
    "#ec1b69",
    "#8c56f0",
    "#00b39b",
    "#f056c2",
    "#005aba"
];
