export function createArrayWithNumbers(length: number): number[] {
    return Array.from({ length }, (_, k) => k);
}

export function bytesToSize(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    const i = Math.min(Math.floor(Math.log(bytes) / Math.log(1024)), sizes.length - 1);
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
}

export const displayAmountWithCurrency = (amount: number, currency: string, decimalPoints: number = 0, notation?: "standard" | "compact") => {
    return new Intl.NumberFormat(
        getDisplayLocaleForCurrency(currency),
        {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: decimalPoints,
            notation
        }).format(amount);
}

export const getSymbolForCurrency = (currency: string) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(0).replace(/[0-9.,]/g, '').trim();
}

// TODO : extend to a more complete list
export const getSupportedCurrencyCodes = ['USD', 'EUR', 'GBP', 'INR', 'AUD', 'CAD', 'SGD', 'CHF', 'HKD', 'JPY', 'CNY'];

function getDisplayLocaleForCurrency(currency: string) {
    switch (currency.toUpperCase()) {
        case "EUR":
        case "GBP":
            return "en-GB";
        case "INR":
            return "en-IN";
        case "CHF":
            return "en-CH";
        case "HKD":
            return "en-HK";
        case "JPY":
            return "ja-JP";
        case "CNY":
            return "zh-CN";
        case "CAD":
        case "AUD":
        case "SGD":
        case "USD":
        default:
            return "en-US";
    }
}

export function getFileExtension(file: File): string {
    return file.name.split('.').pop() ?? "";
}

export function capitalizeFirstLetter(string: String) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLocaleLowerCase();
}

export function getInitials(string: String) {
    const words = string.trim().split(" ");
    if (words.length === 0) {
        return "";
    }

    if (words.length === 1) {
        const word = words[0];
        if (word.length === 0) {
            return "";
        }

        if (word.length === 1) {
            return word.charAt(0).toUpperCase();
        }

        return word.charAt(0).toUpperCase() + word.charAt(1).toUpperCase();
    }

    return words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase();
}
