import { DateTime } from "luxon";
import { EventPlanType } from "./Billing";
import { IEventDocumentView } from "./Document";
import { IEmailTemplateView } from "./EmailTemplate";
import { IUserProfileView } from "./User";

export enum EventObjective {
    LEAD_GENERATION = "LEAD_GENERATION",
    BRAND_AWARENESS = "BRAND_AWARENESS",
    PRODUCT_LAUNCH = "PRODUCT_LAUNCH",
    NETWORKING = "NETWORKING",
    PARTNERSHIPS = "PARTNERSHIP",
    OTHER = "OTHER"
}

export interface IEventView {
    id: string;
    companyId: string;
    startDateInUTC: number;
    endDateInUTC: number;
    name: string;
    address?: string;
    description: string;
    city: string;
    state: string;
    pincode: string;
    country: string;
    currencies: string[];
    boothRepUsers: IUserProfileView[];
    applicableDocuments: IEventDocumentView[];
    expenseCategories: string[];
    emailTemplate?: IEmailTemplateView;
    totalLeadsCount: number;
    totalExpensesMap: Map<string, number>;
    disableExpenses?: boolean;
    isDraftEvent: boolean;
    eventPlanType: EventPlanType;
    boothNo?: string;
    objective?: EventObjective;
    applicableGiftVouchers?: IGiftVoucherView[];
}

export interface IGiftVoucherView {
    id: string;
    name: string;
    imageUrl: string;
    currencyCode: string;
    currencyName: string;
    isPhoneNumberMandatory: boolean;
    denomination: number;
}

export interface IAddExpenseRequest {
    clientSideId: string;
    description: string;
    amount: number;
    currency: string;
    expenseTimeInUTC: number;
    category: string;
    imagePath?: string;
}

export interface ILeadView {
    id: string;
    createdByUserId: string;
    createdByUserName: string;
    eventId: string;
    eventName: string;
    visitTimeUTC: number;
    creationTimeUTC: number;
    customerFirstName: string;
    customerLastName: string;
    customerEmail: string | undefined;
    customerMobileNumber: string | undefined;
    customerCompanyName: string;
    customerNarration: string | undefined;
    customerPhotoPath?: string;
    customerDesignation: string;
    interestLevel: number;
    isOverLimit?: boolean;
}

export interface IExpenseView {
    id: string;
    clientSideId: string;
    createdByUserId: string;
    createdByUserName: string;
    eventId: string;
    eventName: string;
    expenseTimeInUTC: number;
    description: string;
    category: string;
    amount: number;
    currency: string;
    imagePath?: string;
    isOverLimit?: boolean;
}

export enum TaskStatus {
    PENDING = "PENDING",
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED",
    CANCELLED = "CANCELLED"
}

export interface ITaskView {
    id: string;
    description: string;
    taskStatus: TaskStatus;
    assignedToUserId?: string;
    assignedUserDisplayName?: string;
    parentTaskId?: string;
    subTasks?: ITaskView[];
    startDateInUTC?: number;
    attachmentPath?: string;
    dueDateInUTC?: number;
    creationTimeInUTC: number;
}

export interface IAddTaskRequest {
    description: string;
    parentTaskId?: string;
    assignedToUserId?: string;
    startDateInUTC?: DateTime;
    dueDateInUTC?: DateTime;
}

export interface IUpdateTaskRequest {
    description: string;
    assignedToUserId?: string;
    startDateInUTC?: DateTime;
    dueDateInUTC?: DateTime;
}
