import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import { IPage } from '../../models/Page';
import { Box, CssBaseline, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png';
import { useEffect } from 'react';

const drawerWidth = 200;

interface IProps {
    pages: IPage[];
    children: React.ReactNode;
}

function getSelectedPageIndex(pages: IPage[], currentPath: string) {
    let index = 0;
    pages.forEach((page) => {
        const doesMatch = matchPath(page.href, currentPath);
        if (doesMatch) {
            index = pages.indexOf(page);
        } else if (page.children) {
            page.children.forEach((childPage) => {
                const doesMatch = matchPath(childPage.href, currentPath);
                if (doesMatch) {
                    index = pages.indexOf(page);
                }
            });
        }
    });

    return index;
}

export default function HomeLayout(props: IProps) {

    const { pages, children } = props;

    const [mobileOpen, setMobileOpen] = React.useState(false);

    let location = useLocation();

    const [selectedIndex, setSelectedIndex] = React.useState(getSelectedPageIndex(pages, location.pathname));

    const navigate = useNavigate();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleListItemClick = (_: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
        setSelectedIndex(index);
    };

    useEffect(() => {
        setSelectedIndex(getSelectedPageIndex(pages, location.pathname));
    }, [location, pages]);

    const drawer = (
        <div>
            <Toolbar sx={{ mr: 1, ml: 1 }}>
                <img src={logo} alt='logo' style={{ width: '100%' }} />
            </Toolbar>
            <List>
                {pages.map((page, index) => {
                    const Icon = page.icon as React.ElementType;
                    return (
                        <ListItem
                            key={page.title}
                            disablePadding
                        >
                            <ListItemButton
                                selected={selectedIndex === index}
                                onClick={(event) => {
                                    navigate(page.href);
                                    handleListItemClick(event, index);
                                }}
                                sx={{
                                    "&.Mui-selected": {
                                        backgroundColor: "#000000",
                                        color: "#ffffff",
                                        ":hover": {
                                            backgroundColor: "#000000"
                                        }
                                    }
                                }}
                            >
                                <ListItemIcon color="#ffffff">
                                    <Icon style={{ color: selectedIndex === index ? "#ffffff" : "#000000" }} />
                                </ListItemIcon>
                                <ListItemText primary={page.title} />
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </List>
        </div >
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    backgroundColor: "white",
                    color: "black",
                    display: { sm: 'none' } // hide appbar on desktop
                }}
                elevation={0}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        {pages[selectedIndex].title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{
                    width: { sm: drawerWidth },
                    flexShrink: { sm: 0 }
                }}
                aria-label="mailbox folders"
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    backgroundColor: '#F5F5F5',
                    minHeight: '100vh',
                    padding: 0
                }}
            >
                <Toolbar sx={{ display: { sm: 'none' } }} />
                {children}
            </Box>
        </Box>
    );
}
