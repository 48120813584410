import React, { useContext, useState } from 'react';
import { Typography, Box, TextField, Button, Container, FormControl, IconButton, InputAdornment, InputLabel, FilledInput, FormHelperText, Link, FormControlLabel, Checkbox } from '@mui/material';
import styles from './UserSignupForm.module.css';
import { SubmitHandler, useForm } from 'react-hook-form';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { Loading } from '../loading/Loading';
import { IUserSignupRequest, signupUser } from '../../api/Signup';
import { BoothXError } from '../../models/BoothXError';
import { useNavigate } from 'react-router-dom';
import { ToastContext } from '../../contexts/ToastContext';
import { Auth } from '../../utils/Auth';

const classes = { root: styles.input };

const UserSignupForm: React.FC = React.memo(() => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
    const [termsError, setTermsError] = useState<boolean>(false);

    const { showToast } = useContext(ToastContext);
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, clearErrors, setValue } = useForm<IUserSignupRequest>();

    const onSubmit: SubmitHandler<IUserSignupRequest> = async (userData) => {
        if (termsAccepted === false) {
            setTermsError(true);
            return;
        }

        setIsLoading(true);
        const response = await signupUser(userData);

        if (response instanceof BoothXError) {
            setIsLoading(false);
            showToast(response.getErrorMessage(), "error");
            return;
        }

        const error = await Auth.getInstance().loginWithEmailAndPassword(userData.userEmail, userData.userPassword);
        setIsLoading(false);
        if (error) {
            showToast(error.message, "error");
            return;
        }

        navigate('/signup/company');
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <Container
            component="main"
            maxWidth="xs"
        >
            <Typography component="h1" variant="h5">
                Create an account
            </Typography>
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    {...register("userFirstName", { required: true })}
                    variant="filled"
                    InputProps={{
                        disableUnderline: true,
                        classes: classes
                    }}
                    margin="normal"
                    fullWidth
                    label="First Name"
                    name="userFirstName"
                    autoComplete="given-name"
                    autoFocus
                    error={errors.userFirstName === undefined ? false : true}
                    helperText={errors.userFirstName === undefined ? "" : "First name is required"}
                    onChange={(event) => {
                        clearErrors("userFirstName");
                        setValue("userFirstName", event.target.value);
                    }}
                />

                <TextField
                    {...register("userLastName", { required: true })}
                    variant="filled"
                    InputProps={{
                        disableUnderline: true,
                        classes: classes
                    }}
                    margin="normal"
                    fullWidth
                    label="Last Name"
                    name="userLastName"
                    autoComplete="family-name"
                    error={errors.userLastName === undefined ? false : true}
                    helperText={errors.userLastName === undefined ? "" : "Last name is required"}
                    onChange={(event) => {
                        clearErrors("userLastName");
                        setValue("userLastName", event.target.value);
                    }}
                />

                <TextField
                    {...register("userEmail", { required: true })}
                    variant="filled"
                    InputProps={{
                        disableUnderline: true,
                        classes: classes
                    }}
                    margin="normal"
                    fullWidth
                    label="Email Id"
                    name="userEmail"
                    type="email"
                    autoComplete="email"
                    error={errors.userEmail === undefined ? false : true}
                    helperText={errors.userEmail === undefined ? "" : "Email Id is required"}
                    onChange={(event) => {
                        clearErrors("userEmail");
                        setValue("userEmail", event.target.value);
                    }}
                />

                <FormControl
                    variant="filled"
                    fullWidth
                    margin="normal"
                >
                    <InputLabel
                        htmlFor="filled-adornment-password"
                        error={errors.userPassword === undefined ? false : true}
                    >
                        Password
                    </InputLabel>
                    <FilledInput
                        {...register("userPassword", { required: true, minLength: 8 })}
                        name="userPassword"
                        id="filled-adornment-password"
                        type={showPassword ? "text" : "password"}
                        disableUnderline
                        autoComplete="new-password"
                        classes={classes}
                        error={errors.userPassword === undefined ? false : true}
                        onChange={(event) => {
                            clearErrors("userPassword");
                            setValue("userPassword", event.target.value);
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText
                        id="my-helper-text"
                        error={errors.userPassword === undefined ? false : true}
                    >
                        {errors.userPassword !== undefined && errors.userPassword.type === "required" && "Password is required"}
                        {errors.userPassword !== undefined && errors.userPassword.type === "minLength" && "Password should be at least 8 characters"}
                    </FormHelperText>
                </FormControl>
                <Box sx={{ display: "flex", alignItems: "left", textAlign: "left" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={termsAccepted}
                                onChange={(event) => {
                                    setTermsAccepted(event.target.checked);
                                    setTermsError(false);
                                }}
                            />
                        }
                        label={
                            <Typography sx={{
                                fontSize: "0.8rem",
                                color: termsError ? "#d32f2f" : undefined,
                            }}>
                                I agree with the <Link href="/terms-of-service" underline="always" target="_blank" sx={{ color: termsError ? "#d32f2f" : undefined }}>Terms</Link> and <Link href="/privacy-policy" underline="always" target="_blank" sx={{ color: termsError ? "#d32f2f" : undefined }}>Privacy Policy</Link>
                            </Typography>
                        }
                    />
                </Box>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                >
                    <Loading isLoading={isLoading} text={"Next"} />
                </Button>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        typography: 'body1',
                        '& > :not(style) + :not(style)': {
                            ml: 2,
                        },
                    }}
                >
                    Already have an account?&nbsp;
                    <Link href="/login" underline="always">
                        Login
                    </Link>
                </Box>
            </Box>
        </Container>
    );
});

export default UserSignupForm;
