import { useContext, useState } from "react";
import GenericDialog from "../genericDialog/GenericDialog";
import { addUser, updateUser } from "../../api/UsersApi";
import { BoothXError } from "../../models/BoothXError";
import { IAddUserRequest, IUserProfileView } from "../../models/User";
import { ToastContext } from "../../contexts/ToastContext";
import { SubmitHandler, useForm } from "react-hook-form";
import { Checkbox, Grid, ListItemText, MenuItem, TextField } from "@mui/material";
import styles from './AddOrUpdateUserDialog.module.css';
import { Capability } from "../../models/Capability";
import { Feature, capabilitiesForFeature, displayNameForFeature, featuresForCapabilities, getAllFeatures } from "../../models/Feature";

const classes = { root: styles.input };

interface IProps {
    user?: IUserProfileView;
    onClose: (didAddUser: boolean, user?: IUserProfileView) => void;
}

const isSubsetFeature = (selectedFeatures: Feature[], currentFeature: Feature) => {
    const capabilitiesForCurrentFeature = capabilitiesForFeature(currentFeature);
    return selectedFeatures.some(_feature => {
        const _capabilities = capabilitiesForFeature(_feature);
        return capabilitiesForCurrentFeature.every(cap => {
            return _capabilities.includes(cap);
        })
    });
}

const AddOrUpdateUserDialog: React.FC<IProps> = (props) => {
    const { user, onClose } = props;
    const [isLoading, setIsLoading] = useState(false);
    const { showToast } = useContext(ToastContext);

    const defaultFeatures = user ? featuresForCapabilities(user.capabilities) : [];
    const { register, handleSubmit, watch, formState: { errors }, clearErrors } = useForm<IAddUserRequest>({
        defaultValues: {
            ...user,
            features: defaultFeatures
        }
    });

    const watchFeatures = watch("features");

    const onSubmit: SubmitHandler<IAddUserRequest> = async (data) => {
        setIsLoading(true);

        data.capabilities = [...new Set(data.features.flatMap(feature => capabilitiesForFeature(feature)))];
        let response;
        if (user) {
            response = await updateUser(user.id, data);
        } else {
            response = await addUser(data);
        }
        setIsLoading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        showToast(`User ${user ? "updated" : "added"} successfully`, "success");
        onClose(true, response);
    };

    return (
        <GenericDialog
            title={user ? "Update User" : "Add User"}
            isOpen={true}
            primaryButtonText={user ? "Update" : "Add"}
            primaryButtonLoading={isLoading}
            primaryButtonAction={handleSubmit(onSubmit)}
            onClose={() => {
                onClose(false);
            }}
        >
            <Grid container spacing={0} padding={0}>
                <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    <TextField
                        {...register("firstName", { required: true })}
                        variant="filled"
                        InputProps={{
                            disableUnderline: true,
                            classes: classes
                        }}
                        margin="normal"
                        fullWidth
                        label="First Name"
                        name="firstName"
                        autoComplete="off"
                        defaultValue={user?.firstName}
                        error={errors.firstName === undefined ? false : true}
                        helperText={errors.firstName === undefined ? "" : "First name is required"}
                        onChange={() => {
                            clearErrors("firstName");
                        }}
                    />
                </Grid>
                <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    <TextField
                        {...register("lastName", { required: true })}
                        variant="filled"
                        InputProps={{
                            disableUnderline: true,
                            classes: classes
                        }}
                        margin="normal"
                        fullWidth
                        label="Last Name"
                        name="lastName"
                        autoComplete="off"
                        defaultValue={user?.lastName}
                        error={errors.lastName === undefined ? false : true}
                        helperText={errors.lastName === undefined ? "" : "Last name is required"}
                        onChange={() => {
                            clearErrors("lastName");
                        }}
                    />
                </Grid>
                <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    <TextField
                        {...register("email", { required: true })}
                        variant="filled"
                        InputProps={{
                            disableUnderline: true,
                            classes: classes
                        }}
                        margin="normal"
                        fullWidth
                        label="Email"
                        name="email"
                        type="email"
                        defaultValue={user?.email}
                        disabled={user !== undefined}
                        autoComplete="off"
                        error={errors.email === undefined ? false : true}
                        helperText={errors.email === undefined ? "" : "Email is required"}
                        onChange={() => {
                            clearErrors("email");
                        }}
                    />
                </Grid>
                {!user?.capabilities.includes(Capability.ALL) &&
                    <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                        <TextField
                            {...register("features", { required: true })}
                            variant="filled"
                            select
                            SelectProps={{
                                multiple: true,
                                defaultValue: defaultFeatures,
                                renderValue: (values) => {
                                    let count = 0;
                                    const selectedFeatures = values as Feature[];
                                    const allFeatures = getAllFeatures();
                                    allFeatures.forEach(feature => {
                                        const isCurrentFeatureASubset = isSubsetFeature(selectedFeatures, feature);
                                        if (selectedFeatures.includes(feature) || isCurrentFeatureASubset) {
                                            count += 1;
                                        }
                                    });
                                    return `${count} features selected`;
                                }
                            }}
                            margin="normal"
                            fullWidth
                            label="Features*"
                            name="features"
                            error={errors.features === undefined ? false : true}
                            helperText={errors.features === undefined ? "" : "Features are required"}
                        >
                            {Object.values(Feature).map((feature) => (
                                < MenuItem key={feature} value={feature} >
                                    <Checkbox
                                        color="primary"
                                        checked={(() => {
                                            const selectedFeatures = watchFeatures;
                                            if (!selectedFeatures) {
                                                return false;
                                            }
                                            const isCurrentFeatureASubset = isSubsetFeature(selectedFeatures, feature);
                                            return selectedFeatures.includes(feature) || isCurrentFeatureASubset;
                                        })()}
                                        disabled={(() => {
                                            const selectedFeatures = watchFeatures;
                                            const isCurrentFeatureASubset = isSubsetFeature(selectedFeatures, feature);
                                            return (!selectedFeatures.includes(feature) && isCurrentFeatureASubset);
                                        })()}
                                    />
                                    <ListItemText primary={displayNameForFeature(feature)} />
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                }
            </Grid>
        </GenericDialog >
    );
}

export default AddOrUpdateUserDialog;
