import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "../../utils/Auth";

const LogoutPage: React.FC = () => {
  const [isLoggedIn, setLoggedIn] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    Auth.getInstance().logout();
    setLoggedIn(false);
  }, []);

  useEffect(() => {
    navigate('/login');
  }, [isLoggedIn, navigate]);

  return <></>;
};

export default LogoutPage;
