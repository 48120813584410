import { Grid } from '@mui/material';
import React from 'react';
import LoginForm from '../../components/loginForm/LoginForm';

const LoginPage: React.FC = React.memo(() => {
    return (
        <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}>
            <Grid item xs={12}>
                <LoginForm />
            </Grid>
        </Grid>
    );
});

export default LoginPage;
