import { Breadcrumbs, Link } from "@mui/material";
import React from "react";
import { displayAmountWithCurrency } from "../../utils/Utils";

interface IProps {
    totalExpensesMap: Map<string, number>;
    fontSize?: number;
    justifyContent?: string;
}

const ExpensesLabel: React.FC<IProps> = React.memo((props) => {
    const { totalExpensesMap, fontSize, justifyContent } = props;
    return (
        <>
            {Object.keys(totalExpensesMap).length === 0
                ? "-"
                : (
                    <Breadcrumbs separator="|" sx={{ display: "flex", fontSize: fontSize, justifyContent: justifyContent }}>
                        {Object.entries(totalExpensesMap)
                            .map((entry, index) =>
                                <Link
                                    key={index}
                                    underline='none'
                                >
                                    {displayAmountWithCurrency(entry[1] as number, entry[0], 2, "compact")}
                                </Link>
                            )}
                    </Breadcrumbs>
                )
            }
        </>
    );
});

export default ExpensesLabel;
