import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BoothXThemeProvider } from './contexts/BoothXThemeContext';
import { ToastProvider } from './contexts/ToastContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { Config } from './utils/Config';

function getApp() {
  return (
    <BoothXThemeProvider>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <ToastProvider>
          <App />
        </ToastProvider>
      </LocalizationProvider>
    </BoothXThemeProvider>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const isTestEnvironment = Config.getInstance().isTestEnvironment();

root.render(
  isTestEnvironment
    ? getApp()
    : (
      <React.StrictMode>
        {getApp()}
      </React.StrictMode>
    )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
