import { useCallback, useContext, useEffect, useState } from "react";
import { getLeads, getLeadsReport } from "../../api/EventsApi";
import { BoothXError } from "../../models/BoothXError";
import { IEventView, ILeadView } from "../../models/Event";
import { ToastContext } from "../../contexts/ToastContext";
import { Box, Button, Grid, Rating, Typography } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import GenericTable from "../genericTable/GenericTable";
import DescriptionIcon from '@mui/icons-material/Description';
import { parsePhoneNumberWithError } from "libphonenumber-js";
import { Loading } from "../loading/Loading";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

export interface IEventLeadsProps {
    event: IEventView;
}

export const EventLeads = (props: IEventLeadsProps) => {

    const { event } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [leads, setLeads] = useState<ILeadView[]>([]);
    const [isDownloading, setIsDownloading] = useState(false);

    const { showToast } = useContext(ToastContext);

    const fetchLeads = useCallback(async () => {
        setIsLoading(true);
        const response = await getLeads(event.id);
        setIsLoading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        const leadsExceedingPlan = response.leadsExceedingPlan.map(lead => {
            lead.isOverLimit = true;
            return lead;
        });
        setLeads(response.leads.concat(leadsExceedingPlan));
    }, [showToast, event]);

    const downloadLeadsReport = async () => {
        setIsDownloading(true);
        const response = await getLeadsReport(event.id);
        setIsDownloading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
        }
    }

    useEffect(() => {
        fetchLeads();
    }, [fetchLeads]);

    const NotesTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} arrow />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 350,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    const usersForFilter = [...new Set(leads.map(lead => lead.createdByUserName))];

    return (
        <Box>
            <Box
                component="div"
                sx={{
                    mb: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                <Button
                    aria-label="add"
                    variant="text"
                    onClick={downloadLeadsReport}
                    disabled={isDownloading}
                    sx={{ fontWeight: "bold", textTransform: "none" }}
                >
                    {isDownloading && <Loading isLoading={isDownloading} />}
                    {!isDownloading && (
                        <>
                            <FileDownloadOutlinedIcon />
                            Download Report
                        </>
                    )}
                </Button>
            </Box>
            <Grid container spacing={2}>
                <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    <GenericTable
                        data={leads}
                        headers={[
                            { header: "Lead Name" },
                            { header: "Company Name" },
                            { header: "Designation" },
                            { header: "Email Id" },
                            { header: "Mobile No" },
                            { header: "Interest", sortByField: "interestLevel" },
                            { header: "Notes" },
                            {
                                header: "Added By",
                                filter: usersForFilter.length === 1 ? undefined : {
                                    filterValues: usersForFilter,
                                    isValid: (lead: ILeadView, selectedFilter: string) => lead.createdByUserName === selectedFilter
                                }
                            }
                        ]}
                        pagination
                        isLoading={isLoading}
                        dataRenderer={(leadRow, column) => {
                            switch (column) {
                                case 0:
                                    return leadRow.customerFirstName + " " + leadRow.customerLastName;
                                case 1:
                                    return leadRow.customerCompanyName;
                                case 2:
                                    return leadRow.customerDesignation;
                                case 3:
                                    if (leadRow.isOverLimit) {
                                        return "***";
                                    }

                                    if (!leadRow.customerEmail || leadRow.customerEmail.length === 0) {
                                        return "N/A"
                                    }

                                    return leadRow.customerEmail;

                                case 4:
                                    if (leadRow.isOverLimit) {
                                        return "***";
                                    }

                                    if (!leadRow.customerMobileNumber || leadRow.customerMobileNumber.length === 0) {
                                        return "N/A"
                                    }

                                    try {
                                        return parsePhoneNumberWithError(leadRow.customerMobileNumber).formatInternational();
                                    } catch (error) {
                                        return leadRow.customerMobileNumber;
                                    }

                                case 5:
                                    return <Rating readOnly value={leadRow.interestLevel} />;
                                case 6:
                                    return (
                                        <>
                                            {(!leadRow.customerNarration || leadRow.customerNarration.length === 0) && "N/A"}
                                            {leadRow.customerNarration && leadRow.customerNarration.length > 0 &&
                                                <NotesTooltip
                                                    title={<Typography>{leadRow.customerNarration}</Typography>}
                                                    placement="bottom-start"
                                                >
                                                    <DescriptionIcon />
                                                </NotesTooltip>
                                            }
                                        </>
                                    );
                                case 7:
                                    return leadRow.createdByUserName;
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
