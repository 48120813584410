import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { getEventTaskDocumentUrl, getTasks, updateTaskStatus } from "../../api/EventsApi";
import { BoothXError } from "../../models/BoothXError";
import { IEventView, ITaskView, TaskStatus } from "../../models/Event";
import { ToastContext } from "../../contexts/ToastContext";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Grid, IconButton, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { canUserAddOrUpdateOrDeleteTasks } from "../../utils/AuthUtils";
import AddOrUpdateTaskDialog from "../addTaskDialog/AddOrUpdateTaskDialog";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SubdirectoryArrowRightRoundedIcon from '@mui/icons-material/SubdirectoryArrowRightRounded';
import { Loading } from "../loading/Loading";
import { DateTime } from "luxon";
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteTaskConfirmationDialog from "../deleteTaskConfirmationDialog/DeleteTaskConfirmationDialog";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

interface IEventExpensesProps {
    event: IEventView;
}

interface ITaskDialogProps {
    isSubTask: boolean;
    task?: ITaskView;
    parentTask?: ITaskView;
}

interface LoadingStates {
    [taskId: string]: boolean;
}

const itemStyle = { display: "flex", justifyContent: "center" };

export const EventTasks = (props: IEventExpensesProps) => {
    const { event } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tasks, setTasks] = useState<ITaskView[]>([]);
    const [selectedTask, setSelectedTask] = useState<ITaskDialogProps>();
    const [completionLoading, setCompletionLoading] = useState<LoadingStates>({});

    const [showAddOrEditTaskDialog, setShowAddOrEditTaskDialog] = useState<ITaskDialogProps | false>(false);
    const [showDeleteTaskDialog, setShowDeleteTaskDialog] = useState<ITaskDialogProps | false>(false);

    const allowAddOrUpdateOrDeleteExpense = canUserAddOrUpdateOrDeleteTasks();

    const { showToast } = useContext(ToastContext);

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, task: ITaskView, isSubTask: boolean) => {
        setMenuAnchorEl(event.currentTarget);
        setSelectedTask({ task, isSubTask });
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleEditTask = () => {
        setMenuAnchorEl(null);
        if (selectedTask) {
            setShowAddOrEditTaskDialog(selectedTask);
        }
    };

    const handleDeleteTask = () => {
        setMenuAnchorEl(null);
        if (selectedTask) {
            setShowDeleteTaskDialog(selectedTask);
        }
    };

    const fetchTasks = useCallback(async () => {

        setIsLoading(true);
        const response = await getTasks(event.id);
        setIsLoading(false);

        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        response.sort((a, b) => a.creationTimeInUTC > b.creationTimeInUTC ? 1 : -1);
        response.forEach((task) => {
            task.subTasks?.sort((a, b) => {
                if (a.dueDateInUTC === undefined) {
                    return -1;
                }

                if (b.dueDateInUTC === undefined) {
                    return 1;
                }

                if (a.dueDateInUTC === b.dueDateInUTC) {
                    return a.creationTimeInUTC > b.creationTimeInUTC ? 1 : -1;
                }

                return a.dueDateInUTC > b.dueDateInUTC ? 1 : -1;
            });
        });

        setTasks(response);
    }, [showToast, event]);

    const completeTask = async (taskId: string) => {
        setCompletionLoading({
            ...completionLoading,
            [taskId]: true
        });

        const response = await updateTaskStatus(event.id, taskId, TaskStatus.COMPLETED);

        setCompletionLoading({
            ...completionLoading,
            [taskId]: false
        });

        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        fetchTasks();
    };

    useEffect(() => {
        fetchTasks();
    }, [fetchTasks]);

    const handleAddTaskDialogClose = (didAddTask: boolean, _?: ITaskView) => {
        didAddTask && fetchTasks();
        setShowAddOrEditTaskDialog(false);
    }

    const handleDeleteTaskDialogClose = (didDeleteTask: boolean, _?: ITaskView) => {
        didDeleteTask && fetchTasks();
        setShowDeleteTaskDialog(false);
    }

    const handleDownloadDocument = async (subTask: ITaskView) => {
        const response = await getEventTaskDocumentUrl(event.id, subTask.id);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        window.open(response, "_blank");
    }

    return (
        <Box>
            {showAddOrEditTaskDialog &&
                <AddOrUpdateTaskDialog
                    isSubTask={showAddOrEditTaskDialog.isSubTask}
                    parentTask={showAddOrEditTaskDialog.parentTask}
                    task={showAddOrEditTaskDialog.task}
                    event={event}
                    onClose={handleAddTaskDialogClose}
                />
            }

            {showDeleteTaskDialog &&
                <DeleteTaskConfirmationDialog
                    isSubTask={showDeleteTaskDialog.isSubTask}
                    task={showDeleteTaskDialog.task!}
                    event={event}
                    onClose={handleDeleteTaskDialogClose}
                />
            }

            <Box
                component="div"
                sx={{
                    mb: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                <Box>
                    {allowAddOrUpdateOrDeleteExpense && (
                        <Button
                            aria-label="add"
                            variant="text"
                            onClick={() => setShowAddOrEditTaskDialog({ isSubTask: false })}
                            sx={{ fontWeight: "bold", textTransform: "none" }}
                        >
                            + Add New Deliverable
                        </Button>
                    )}
                </Box>
            </Box>
            <Grid container spacing={2}>
                <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    {isLoading && <Loading isLoading />}
                    <Menu
                        anchorEl={menuAnchorEl}
                        open={menuOpen}
                        onClose={handleMenuClose}
                    >
                        {selectedTask?.task?.taskStatus !== TaskStatus.COMPLETED && <MenuItem onClick={handleEditTask}>Edit</MenuItem>}
                        <MenuItem onClick={handleDeleteTask} sx={{ color: "#FF6361" }}>Delete</MenuItem>
                    </Menu>
                    {tasks.map((task) => {
                        const totalSubTasks = task.subTasks?.length ?? 0;
                        const completedSubTasks = task.subTasks?.filter((subTask) => subTask.taskStatus === TaskStatus.COMPLETED).length ?? 0;
                        return (
                            <Accordion
                                key={task.id}
                                sx={{
                                    '&:not(:last-child)': {
                                        borderBottom: 0
                                    },
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{
                                        flexDirection: "row-reverse",
                                        ".MuiAccordionSummary-content": {
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            marginLeft: 1,
                                        },
                                        ".MuiAccordionSummary-content.Mui-expanded": {
                                            display: "flex",
                                            justifyContent: "space-between",
                                            marginLeft: 1
                                        }
                                    }}
                                >
                                    <Typography>{task.description} ({completedSubTasks}/{totalSubTasks} completed)</Typography>
                                    {allowAddOrUpdateOrDeleteExpense && (
                                        <Box>
                                            <Button
                                                variant="text"
                                                size="small"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setShowAddOrEditTaskDialog({ isSubTask: true, parentTask: task });
                                                }}
                                            >
                                                + Add Task
                                            </Button>
                                            <IconButton
                                                size="small"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleMenuClick(e, task, false);
                                                }}>
                                                <MoreVertIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                    )}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {task.subTasks?.map((subTask, index, allSubTasks) => {
                                        const isCompleting = completionLoading[subTask.id];
                                        const isOverDue = subTask.dueDateInUTC ? DateTime.now().toUTC() > DateTime.fromSeconds(subTask.dueDateInUTC).toUTC() : false;
                                        const isCompleted = subTask.taskStatus === TaskStatus.COMPLETED;
                                        return (
                                            <Fragment key={subTask.id}>
                                                <ListItem
                                                    secondaryAction={
                                                        <IconButton
                                                            size="small"
                                                            onClick={(e) => {
                                                                handleMenuClick(e, subTask, true);
                                                            }}>
                                                            <MoreVertIcon fontSize="small" />
                                                        </IconButton>
                                                    }
                                                    sx={{
                                                        ".MuiListItemSecondaryAction-root": {
                                                            right: 0
                                                        }
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <SubdirectoryArrowRightRoundedIcon />
                                                    </ListItemIcon>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={3}
                                                            justifyContent={"flex-end"}
                                                            alignItems={"center"}
                                                        >
                                                            <ListItemText>{subTask.description}</ListItemText>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <ListItemText>{subTask.assignedUserDisplayName}</ListItemText>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <ListItemText sx={itemStyle}>{subTask.startDateInUTC ? DateTime.fromSeconds(subTask.startDateInUTC).toFormat("dd-MMM-yyyy") : "-"}</ListItemText>

                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <ListItemText sx={{
                                                                ...itemStyle,
                                                                color: !isCompleted && isOverDue ? "red" : undefined
                                                            }}>{subTask.dueDateInUTC ? DateTime.fromSeconds(subTask.dueDateInUTC).toFormat("dd-MMM-yyyy") : "-"}</ListItemText>
                                                        </Grid>
                                                        <Grid item xs={1} sx={itemStyle}>
                                                            {subTask.attachmentPath ? (
                                                                <IconButton
                                                                aria-label="download"
                                                                color="primary"
                                                                size="small"
                                                                onClick={() => {
                                                                    handleDownloadDocument(subTask);
                                                                }}
                                                            >
                                                                <DownloadOutlinedIcon />
                                                            </IconButton>
                                                            ) : "-"}
                                                        </Grid>
                                                        <Grid item xs={1} display={"flex"} alignItems={"center"}>
                                                            {subTask.taskStatus === TaskStatus.COMPLETED &&
                                                                <IconButton>
                                                                    <CheckCircleIcon color="success" />
                                                                </IconButton>
                                                            }
                                                            {subTask.taskStatus === TaskStatus.PENDING &&
                                                                <IconButton
                                                                    disabled={isCompleting}
                                                                    onClick={() => completeTask(subTask.id)}
                                                                >
                                                                    {isCompleting && <Loading isLoading />}
                                                                    {!isCompleting && <CheckCircleOutlineRoundedIcon color="disabled" />}
                                                                </IconButton>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                                {index !== (allSubTasks.length - 1) && <Divider />}
                                            </Fragment>
                                        );
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </Grid>
            </Grid>
        </Box >
    );
}
