import { initializeApp } from "firebase/app";
import { getAuth, signInWithCustomToken, signInWithEmailAndPassword } from "firebase/auth";
import { BoothXError } from "../models/BoothXError";
import { IGetSettingsResponse } from "../models/User";
import { Config } from "./Config";
import { Capability } from "../models/Capability";

export class Auth {
    private static instance: Auth;

    private static readonly userSettingsStorageKey = "userSettings";

    private constructor() {
        initializeApp(Config.getInstance().getFirebaseConfig());
    }

    public static getInstance() {
        if (!Auth.instance) {
            Auth.instance = new Auth();
        }
        return Auth.instance;
    }

    public async getToken(): Promise<string | BoothXError> {
        return new Promise<string | BoothXError>(function (resolve, reject) {
            getAuth().onAuthStateChanged(async function (user) {
                if (user) {
                    const token = await user.getIdToken();
                    if (token) {
                        resolve(token);
                    }
                    reject(new BoothXError("token not found"));
                } else {
                    reject(new BoothXError("user not found"));
                }
            });
        }).catch((error) => {
            if (error instanceof BoothXError) {
                return error;
            }
            console.log(error);
            return new BoothXError("Something went wrong");
        });
    }

    public async refreshToken() {
        await getAuth().currentUser?.getIdToken(true);
    }

    public isLoggedIn() {
        return this.getUserSettings() ? true : false;
    }

    public logout() {
        getAuth().signOut();
        this.clearUserSettings();
    }

    public saveUserSettings(userSettings: IGetSettingsResponse) {
        window.localStorage.setItem(Auth.userSettingsStorageKey, JSON.stringify(userSettings));
    }

    public getUserSettings(): IGetSettingsResponse | undefined {
        const userSettingsJson = window.localStorage.getItem(Auth.userSettingsStorageKey);
        if (userSettingsJson) {
            return JSON.parse(userSettingsJson);
        }

        return undefined;
    }

    private clearUserSettings() {
        window.localStorage.removeItem(Auth.userSettingsStorageKey);
    }

    public async loginWithEmailAndPassword(email: string, password: string): Promise<BoothXError | undefined> {
        return await signInWithEmailAndPassword(getAuth(), email, password)
            .then((result) => {
                if (result && result.user && result.user.email === email) {
                    return;
                }

                return new BoothXError("Something went wrong");
            })
            .catch((error) => {
                console.log(error);
                return new BoothXError("Invalid Email Id or Password, please try again.");
            });
    }

    public async signInWithToken(
        token: string
    ): Promise<BoothXError | undefined> {

        return await signInWithCustomToken(getAuth(), token)
            .then((_) => {
                return undefined;
            })
            .catch((error) => {
                console.log(error);
                return new BoothXError("Something went wrong");
            });
    }


    public isAdminOrSuperAdmin(): boolean {
        const settings = this.getUserSettings();

        if (!settings) {
            return false;
        }

        const isAdminOrSuperAdmin = settings.profileView.capabilities.includes(Capability.ALL) || settings.profileView.capabilities.includes(Capability.SUPER);
        return isAdminOrSuperAdmin;
    }

    public containsCapability(capability: Capability): boolean {
        const settings = this.getUserSettings();

        if (!settings) {
            return false;
        }

        return settings.profileView.capabilities.includes(capability);
    }
}
