import { Box, Button, Card, CardContent, Grid, Tab, Tabs, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useEventsCache from "../../hooks/EventsHook";
import { EventLeads } from "../../components/eventLeads/EventLeads";
import { EventExpenses } from "../../components/eventExpenses/EventExpenses";
import { BoothXError } from "../../models/BoothXError";
import { IEventView } from "../../models/Event";
import { getEvent } from "../../api/EventsApi";
import { Loading } from "../../components/loading/Loading";
import { EventTasks } from "../../components/eventTasks/EventTasks";
import { DateTime } from "luxon";
import { getDisplayAddress, getStatusChip } from "../../utils/EventUtils";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { ToastContext } from "../../contexts/ToastContext";
import { canUserManageEvent } from "../../utils/AuthUtils";
import { EventInsights } from "../../components/eventInsights/EventInsights";

const EventPage: React.FC = React.memo(() => {
    const { eventId } = useParams();
    const [event, setEvent] = useState<IEventView>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const isEditEnabled = canUserManageEvent();

    const { showToast } = useContext(ToastContext);
    const { getEventFromCache } = useEventsCache();
    const [selectedTab, setSelectedTab] = useState(0);

    const navigate = useNavigate();

    const setEventOrNavigate = useCallback((eventView: IEventView) => {
        if (eventView.isDraftEvent) {
            navigate(`/events/${eventView.id}/edit`);
            return;
        }

        setEvent(eventView);
    }, [navigate]);

    useEffect(() => {
        const fetchEvent = async () => {
            setIsLoading(true);
            const response = await getEvent(eventId!);
            setIsLoading(false);
            if (response instanceof BoothXError) {
                showToast(response.getErrorMessage(), "error");
                return;
            }
            setEventOrNavigate(response);
        };

        const eventView = getEventFromCache(eventId ?? "");
        if (eventView === undefined) { // will never be undefined since we are getting from cache, but just to be safe and to not use ? operator
            fetchEvent();
        } else {
            setEventOrNavigate(eventView);
        }
    }, [getEventFromCache, eventId, showToast, setEventOrNavigate]);

    return (
        <Box sx={{ width: '100%' }}>
            <Loading isLoading={isLoading} />
            {event && (
                <>
                    <Card sx={{ margin: 3 }}>
                        <CardContent>
                            <Grid container columnSpacing={1}>
                                <Grid item md={4}>
                                    <Typography align="left" sx={{ fontSize: 24, fontWeight: 600 }}>
                                        {event.name} {getStatusChip(event)}
                                    </Typography>
                                    <Typography align="left" sx={{ fontSize: 14, fontWeight: 600 }}>
                                        {DateTime.fromSeconds(event.startDateInUTC).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)} - {DateTime.fromSeconds(event.endDateInUTC).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                                    </Typography>
                                    <Typography align="left" sx={{ fontSize: 14 }}>
                                        {getDisplayAddress(event)}
                                    </Typography>
                                    <Typography align="left" sx={{ fontSize: 14 }}>
                                        {event.description}
                                    </Typography>
                                </Grid>
                                <Grid item md={4}>
                                    <Typography align="left" sx={{ fontSize: 14, fontWeight: 600 }}>
                                        Booth Reps
                                    </Typography>
                                    <Typography align="left" sx={{ mt: 2, fontSize: 14 }}>
                                        {event.boothRepUsers.map(user => user.displayName).join(" | ")}
                                    </Typography>
                                </Grid>
                                <Grid item md={3}>
                                    <Typography align="left" sx={{ fontSize: 14, fontWeight: 600 }}>
                                        Event Currencies
                                    </Typography>
                                    <Typography align="left" sx={{ mt: 2, fontSize: 14 }}>
                                        {event.currencies.join("\r\n")}
                                    </Typography>
                                </Grid>
                                {isEditEnabled && (
                                    <Grid item md={1} alignItems={"end"}>
                                        <Button
                                            aria-label="add"
                                            variant="text"
                                            onClick={() => { navigate(`/events/${event.id}/edit`); }}
                                            sx={{ fontWeight: "bold", textTransform: "none", padding: 0 }}
                                        >
                                            <EditOutlinedIcon fontSize="small" /> Edit
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>

                    {!event.isDraftEvent && (
                        <Box sx={{ bgcolor: '#ffffff', margin: 3 }}>
                            <Tabs
                                value={selectedTab}
                                onChange={(_, newValue) => setSelectedTab(newValue)}
                                variant="fullWidth"
                                sx={{
                                    borderBottom: 1,
                                    borderColor: "divider",
                                    backgroundColor: "#D9D9D9",
                                    borderRadius: 1,
                                    ".Mui-selected": {
                                        backgroundColor: "#FFFFFF",
                                        marginTop: 0.5,
                                        marginLeft: 0.5,
                                        marginRight: 0.5,
                                        borderRadius: 1,
                                        fontWeight: 600
                                    },
                                    ".MuiTouchRipple-child": {
                                        backgroundColor: "#FFFFFF"
                                    }
                                }}
                                TabIndicatorProps={{
                                    sx: {
                                        height: 2,
                                        borderRadius: 2
                                    }
                                }}
                            >
                                <Tab label="Insights" sx={{ textTransform: "none" }} />
                                <Tab label="Leads" sx={{ textTransform: "none" }} />
                                {!event?.disableExpenses && <Tab label="Expenses" sx={{ textTransform: "none" }} />}
                                <Tab label="Tasks" sx={{ textTransform: "none" }} />
                            </Tabs>
                        </Box>
                    )}

                    <Grid container sx={{ paddingLeft: 3, paddingRight: 3 }}>
                        <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                            {selectedTab === 0 && <EventInsights eventId={event.id} />}
                            {selectedTab === 1 && <EventLeads event={event} />}
                            {(!event.disableExpenses && selectedTab === 2) && <EventExpenses event={event} />}
                            {((!event.disableExpenses && selectedTab === 3) || (event.disableExpenses && selectedTab === 2)) && <EventTasks event={event} />}
                        </Grid>
                    </Grid>
                </>
            )}
        </Box >

    );
});

export default EventPage;
