import { useEffect, useRef } from 'react'

function useInterval(callback: () => void, delayInMs: number | null) {
    const savedCallback = useRef(callback);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {

        function tick() {
            savedCallback.current();
        }

        if (delayInMs === null || delayInMs === 0) {
            return;
        }

        const id = setInterval(tick, delayInMs);
        return () => clearInterval(id);
    }, [delayInMs]);
}

export default useInterval;
