import { BoothXError } from "../models/BoothXError";
import { IBoothXServerError } from "../models/BoothXServerError";
import { Auth } from "./Auth";
import { Config } from "./Config";

const BOOTHX_BACKEND = Config.getInstance().getHost();
export const BACKEND_URL_V1 = `${BOOTHX_BACKEND}/v1`;

interface HttpResponse<T> extends Response {
    parsedBody?: T;
    serverError?: IBoothXServerError;
}

async function http<T>(
    path: string,
    args: RequestInit,
    processSucessResponse: boolean = true,
    processErrorResponse: boolean = true,
    retries: number = 1
): Promise<HttpResponse<T>> {
    const tokenOrError = await Auth.getInstance().getToken();
    const newHeaders = new Headers(args.headers);
    if (tokenOrError && !(tokenOrError instanceof BoothXError)) {
        newHeaders.set("Authorization", `Bearer ${tokenOrError}`);
    }
    newHeaders.set("X-Boothx-Client-Timezone", Intl.DateTimeFormat().resolvedOptions().timeZone);
    args.headers = newHeaders;
    const request = new Request(path, args);
    const response: HttpResponse<T> = await fetch(request);

    if (response.ok) {
        if (!processSucessResponse) {
            return response;
        }
        try {
            response.parsedBody = await response.json();
        } catch (ex) {
            // may error if there is no body
        }
        return response;
    }

    if (response.status === 401 && retries > 0) {
        await Auth.getInstance().refreshToken();
        return http<T>(
            path,
            args,
            processSucessResponse,
            processErrorResponse,
            retries - 1
        );
    }

    if (response.status === 401) {
        // logout user if even after retry we get a 401 from server
        Auth.getInstance().logout();
        // FIXME: figure out how to navigate to login screen from here. react-router-v6 doesnt provide an easy way to navigate from
        // outside components. Check https://github.com/remix-run/react-router/issues/9422 for some ideas on how it could be done.
    } else {
        if (!processErrorResponse) {
            return response;
        }

        try {
            response.serverError = await response.json();
        } catch (ex) {
            // may error if there is no body
            console.log(ex);
        }
    }

    return response;
}

export async function get<T>(
    path: string,
    processSucessResponse?: boolean,
    args: RequestInit = { method: "get" }
): Promise<HttpResponse<T>> {
    return await http<T>(path, args, processSucessResponse);
}

export async function post<T>(
    path: string,
    body?: any,
    args: RequestInit = {
        method: "post",
        body: JSON.stringify(body),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    },
    processErrorResponse?: boolean
): Promise<HttpResponse<T>> {
    return await http<T>(path, args, true, processErrorResponse);
}

// 'delete' is a reserved word, so just adding an extra 'e' at the end
export async function deletee<T>(
    path: string,
    body?: any,
    args: RequestInit = { method: "delete", body: JSON.stringify(body) }
): Promise<HttpResponse<T>> {
    return await http<T>(path, args);
}
