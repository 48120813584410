import { Box } from "@mui/material";
import React from "react";

const TermsOfServicePage: React.FC = React.memo(() => {
    return (
        <Box component={"div"} sx={{ textAlign: "left", m: 3, marginTop: 10 }}>
            <h1>TERMS OF USE</h1>

            <h2>Introduction</h2>
            <p>M/s Lastbyte Marketing Technologies LLP, on behalf of itself and its group companies/affiliates is the author, owner and publisher of the internet resource https://boothx.app and the application “BoothX”, (together “Website”). The Website is operated by M/s Lastbyte Marketing Technologies LLP (“Company”, “We”, “Us”, “Our”) having registered office at B2/9 Shri Krishna Apts, 392/3 Gultekdi Road, Pune 411037. This document is an electronic record under Information Technology Act, 2000 and rules thereunder as applicable and is generated by a computer system and does not require any physical or digital signatures. This document is published and shall be construed in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and user agreement for access or usage of the Website.</p>
            <p>For the purpose of these Terms of Use, wherever the context so requires "You", “Your” or "User" shall mean any natural or legal person who accesses, browses, uses or subscribes to Our Website, agrees to become a buyer or user on the Website or avails or offers to avail any of Our deliverables or services from the Website. Your access to or use of this Website together with all its webpages, hyperlinks or references is governed by and subject to the following terms and conditions (“Terms of Use”, “Terms”). By accessing, viewing, browsing, using, transacting through this Website or availing any services or other facilities through this Website, You agree that You have read, understood and agree to be bound by these Terms, our Privacy Policy and also such other terms, conditions, rules, regulations, policies, etc. that are relevant and applicable to Your access, use, browsing, subscribing, transacting business through the Website, or availing any facilities, deliverables or services through the Website and such other terms, conditions, rules, regulations, policies shall be deemed to be incorporated into these Terms and shall be considered a part of these Terms.</p>
            <p>Company reserves the right, at all times, to modify, change, amend, delete any portion of these Terms without any obligation to notify You or any other user of the Website of such modification, change, amendment or deletion. If You do not agree with these Terms of Use or the Privacy Policy, please do not access or use the Website. Your continued use of the Website and Services following the posting of revised Terms means that You accept and agree to the changes. You are expected to check this page frequently, so You are aware of any changes, as they are binding on You. By continuing to access or use Our Website and Services after any revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, You are no longer authorized to use the Website.</p>
            <p>These Terms govern Your access or usage of the Website and all other services offered through the Website (together “Services”). The Services under these Terms are intended to increase efficiency in trade shows and exhibitions by simplifying lead capturing and expense tracking. (“Purpose”).</p>

            <h2>Eligibility for Use of the Website</h2>
            <p>The Website may only be used by persons competent to enter into a contract as per the Indian Contract Act, 1872. You shall not be permitted to use or conclude any transactions on the Website if You do not conform to the qualifications to enter into a contract as specified under the Indian Contract Act, 1872 or as per any other applicable law for the time being in force. By using or accessing the Website, You hereby represent that You are competent to enter into a contract. The Company, shall at all times, reserve the right to refuse or restrict Your access or use of the Website in the event the Company learns of Your incompetency to enter into a contract. THE COMPANY SHALL NOT, IN ANY MANNER, BE HELD RESPONSIBLE FOR THE CONSEQUENCES THAT MAY ARISE IN CASE OF YOUR INCOMPETENCY TO ENTER INTO THIS CONTRACT OR TO ACCEPT THESE TERMS.</p>

            <h2>Communications</h2>
            <p>By using Our Website, You agree to subscribe to newsletters, marketing or promotional materials and other information We may send. However, You may opt out of receiving any, or all, of these communications from Us by following the unsubscribe link or by emailing Us at <a href="mailto:contact@lastbyte.co.in">contact@lastbyte.co.in</a> with a subject line of ‘Unsubscribe’.</p>

            <h2>User Accounts</h2>
            <p>To access certain features and functionalities of the Website, You may be required to create a user account. The Company reserves the right to disable You from creating a user account in the event that any of the details for registration provided by You are found by the Company to be incorrect, incomplete, forged or falsified. You shall solely be responsible to maintain the confidentiality of Your user account, passwords, details of transactions concluded through the user account, payment details, transaction history etc. The Company shall not be liable for any loss or damage arising, directly or indirectly, out of Your failure to comply with the confidentiality obligations stated herein. You shall be responsible for any loss incurred by the Company or any third party, due to Your breach of obligations, non-performance or negligence in performing Your obligations, Your unauthorized or illegal use of the Website or Your failure to keep secure and confidential all information related to Your User account on the Website.</p>

            <h2>Payments and Purchases</h2>
            <p>The Services shall be made available or may be availed, for and in relation to the Purpose described above, as per these Terms, to persons (natural or legal), intending to avail the Services (“Buyer/s”).</p>
            <p>Certain features or Services provided by the Company may require payment. Any payments made through the Website are subject to the terms and conditions of the third-party payment processors.</p>
            <p>To facilitate payments, You may be asked to supply certain information relevant to Your transaction, including without limitation, Your name, age, address, contact details like phone numbers and email IDs, details of your employer, Your credit or debit card number, the expiration date of Your card or other details of such nature. By submitting any content, data or information on or through the Website (your “Material”), You grant Us a worldwide, non-exclusive, royalty-free license (with the right to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit, display and distribute such Material in any and all media or distribution methods (now known or later developed) and to associate Your Material with You for the purpose of providing You Services. The Company does not store Your payment related Material except as required to process or facilitate payments through the third-party payment processors. Company is not responsible for any issues or disputes between You and the third-party payment processors.</p>
            <p>You shall solely be responsible for the accuracy, correctness and completeness of all such payment related details. During Your transactions through the Website for the use of the Services provided by third parties, You may also be subject to certain other additional terms, conditions, rules, notices, guidelines, disclaimers etc. You agree to be bound by such additional terms, if any. </p>
            <p>We may employ the use of third-party services for the purpose of facilitating payment and the completion of purchases. By submitting Your information, You grant us the right to provide the information to these third parties. We may also use third-party service providers to monitor and analyze the use, including Your use of Our Services.</p>

            <h2>Refund Policy</h2>
            <p>Notwithstanding anything to the contrary in these Terms or elsewhere, any payments made for the Services through this Website or otherwise, shall be non-refundable. You recognize and agree that You shall not be entitled to a refund for any purchase under any circumstances.</p>

            <h2>Contests, Sweepstakes and Promotions</h2>
            <p>Any contests, sweepstakes or other promotions (collectively, “Promotions”) made available through the Services may be governed by rules that are separate from these Terms. If You participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms, the Promotion rules will apply, to the extent of such conflict.</p>

            <h2>Use of the Services</h2>
            <p>You shall not decompile, reverse engineer, or disassemble the contents of the Website or Services or modify, copy, distribute, transmit, display, perform, record, reproduce, publish, license, create derivative works from, transfer, or sell any information or software obtained from the Website or Services, or remove any copyright, trademark registration, or other proprietary notices from the contents of the Website or Services.</p>
            <p>You shall not use the Services in any way that is unlawful or harms the Company or any other person or entity as determined by the Company.</p>
            <ol type="a">
                <li><p>You shall not perform any of the following prohibited activities while availing our Website and Services:</p></li>
                <li><p>Making available any content that is misleading, unlawful, harmful, threatening, abusive, tortious, defamatory, libelous, vulgar, obscene, child-pornographic, lewd, lascivious, profane, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable;</p></li>
                <li><p>Stalking, intimidating and/or harassing another and/or inciting other to commit violence;</p></li>
                <li><p>Transmitting material that encourages anyone to commit a criminal offense, that results in civil liability or otherwise breaches any relevant laws, regulations or code of practice;</p></li>
                <li><p>Accessing or using the Website in breach of third party confidential information  or intellectual property rights;</p></li>
                <li><p>Making, transmitting or storing electronic copies of materials protected by copyright without the permission of the owner, committing any act that amounts to the infringement of intellectual property or making available any material that infringes any intellectual property rights or other proprietary rights of anyone else;</p></li>
                <li><p>Posting, transmitting or making available any material that contains viruses, trojan horses, worms, spyware, time bombs, cancelbots, or other computer programming routines, code, files or such other programs that may harm the Services, Website, interests or rights of other users or limit the functionality of any computer software, hardware or telecommunications, or that may harvest or collect any data or personal information about other users without their consent;</p></li>
                <li><p>Hosting, uploading, modifying, publishing, transmitting, storing, updating or sharing any information, which pornographic, pedophilic, invasive of another‘s privacy, relating or encouraging money laundering or gambling, any other unlawful activities or otherwise inconsistent with or contrary to the laws in force or is threatening to the unity, integrity, sovereignty, peace and safety of India or impacts or is likely to impact India’s relations with any foreign states and nations;</p></li>
                <li><p>Altering or modifying any part of the Website or Services;</p></li>
                <li><p>Using the Website or Services for purposes that are not permitted by: (i) these Terms; and (ii) any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdiction; or</p></li>
                <li><p>Violating any of the terms specified under these Terms for the use of the Services. </p></li>
            </ol>
            <p>The Company shall also be entitled to investigate occurrences which may involve such violations and take appropriate legal action, involve and cooperate with law enforcement authorities in prosecuting Users who are involved in such violations.</p>
            <p>The Company shall not be responsible, in any manner whatsoever for any claims, losses or causes of action arising out of or in relation to Your use of the Website.</p>
            <p>During Your use or access of the Website, You must at all times ensure compliance with all applicable provisions of the Information Technology Act, 2000, all applicable Rules thereunder and all applicable laws and regulations for the time being in force.</p>
            <p>You shall immediately report to the Company any unauthorized use or infringement of the Company’s Website that comes to Your attention. If You believe or have reason to believe that any content, data, information on the Website is illegal, offensive, defamatory, sexually explicit, harmful to sentiments, discriminatory, obscene, indecent, provocative, threatening to the safety and security of any person or group of persons, in breach of confidentiality or third party intellectual property or inappropriate in any manner whatsoever, You shall notify Us immediately at <a href="mailto:contact@lastbyte.co.in">contact@lastbyte.co.in</a>. The Company will exercise its best endeavors and judgment to investigate into the matter and take down such inappropriate content from the Website, if deemed necessary.</p>

            <h2>General Terms and Conditions</h2>
            <p>You agree to avail the Services through this Website out of Your own free will and that We have not forced, influenced, compelled or pressurized You, in any manner whatsoever for the same. We do not guarantee or promise any desired or expected results, outcomes, success or end-results from the use of Our Services or Website.</p>
            <p>The Company may, based on any form of access to the Website or registrations through any source whatsoever, contact the User through SMS, e-mail and call, to give information about its Services as well as notifications on various important updates and/or to seek permission for demonstration of its Services. The User expressly grants such permission to contact him/her through telephone, SMS, e-mail and holds the Company indemnified against any liabilities including financial penalties, damages, expenses in case the User's mobile number is registered with Do not Call (DNC) database. By registering yourself, You agree to make your contact details available to Our employees, associates and partners so that You may be contacted for information and promotions through telephone, SMS, e-mail etc.</p>
            <p>Access to certain elements of the Services may be subject to separate terms, conditions and fair usage policy. The Company reserves the right to determine the criteria for provision of various elements of Services to the different categories of Users based on its policies. Hence, subscription to the Services or registrations do not automatically entitle the User to any and all elements of Services provided by the Company and the Company shall be entitled to exercise its discretion while providing access to and determining continuity of certain elements of Services. The Company reserves the right to extend, cancel, discontinue, prematurely withdraw or modify any of the Services at its sole discretion.</p>
            <p>You agree to defend, indemnify and hold harmless the Company, its officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from: (i) Your use of and access of the Website and Services; (ii) Your violation of these Terms or any other policy of the Company; (iii) Your violation of any third party confidential information or rights, including without limitation, any copyright, property, or privacy right; or (iv) any claim that Your use of the Services has caused damage to a third party. This defense and indemnification obligation will survive these Terms.</p>
            <p>In no event shall the Company, its officers, directors, employees, partners or agents be liable to You or any third party for any special, incidental, indirect, consequential or punitive damages whatsoever, including those resulting from loss of use, data or profits or any other claim arising out, of or in connection with, Your use of, or access to, the Services.</p>
            <p>In the event of Your breach of these Terms, You agree that the Company will be irreparably harmed and may not have an adequate remedy in money or damages. The Company, therefore, shall be entitled in such event to obtain an injunction against such a breach from any court of competent jurisdiction. The Company's right to obtain such relief shall not limit its right to obtain other remedies.</p>
            <p>Any violation by You of these Terms may result in immediate suspension or termination of Your Your right to use or access the Services apart from any legal remedy that the Company can avail. In such instances, the Company may also disclose Your account Information if required by any Governmental or legal authority. You understand that the violation of these Terms could also result in civil or criminal liability under applicable laws.</p>
            <p>The Company provides the Website to You for use on an "as is" basis. Company makes no representations or warranties of any kind, express or implied, as to the information, content, services, materials, or products included on the Website. You expressly agree that Your use of this Website is at Your sole risk. While the Company shall attempt to employ reasonably good measures to ensure that the Website is free from viruses or other harmful contents, the Company does not warrant that the Website shall at all times be safe for Your use or free from viruses or harmful content.</p>
            <p>The Company hereby disclaims to the maximum extent permitted under law, any liability or responsibility for:</p>
            <ul>
                <li><p>Inaccuracies, errors, mistakes or flaws displayed on the Website. </p></li>
                <li><p>Any cessation, obstruction, interruption, hurdle, impediment or hindrance of any kind experienced by You in Your access or use of the Website.</p></li>
                <li><p>Any bugs, viruses or malware transmitted by any third party through the Website, without the Company’s knowledge or in the absence of any fault or involvement of the Company.</p></li>
                <li><p>Hacking or unauthorized usage of the Website by third party.</p></li>
                <li><p>Any personal injury or damage to person or property caused by the use and access of the Website by the User or any third party. </p></li>
            </ul>
            <p>Company shall not be responsible for the delay or inability to use the Website or related functionalities, the provision of or failure to provide functionalities, or for any information, software, products, functionalities and related graphics obtained through the Website, or otherwise arising out of the use of the Website, whether based on contract, tort, negligence, strict liability or otherwise. The Company shall not be responsible for any acts or omissions of the Users or any third parties.</p>

            <h2>Use of Tracking Technologies</h2>
            <p>The Company may use tracking technologies including but not limited to cookies and web beacons to collect information such as Your browsing behavior, Your activity on the Website,  internet protocol address of Your computer system, date, time, duration of Your access to the Website, etc. The Company shall collect this information solely for the purpose of understanding Your preferences, inclinations towards specific Services on the Website and thereby providing You appropriate suggestions as per Your choices and likings to enhance Your experience with Us.</p>

            <h2>Use of Website Content</h2>
            <p>All Services provided through the Website are provided in accordance with the applicable laws of India. If You are accessing the Website from locations outside India, it shall be Your sole responsibility to comply with all applicable local laws and regulations. The Company hereby grants to You a limited personal, revocable, non-exclusive, non-transferable right and access to  view and use the Website provided that –</p>
            <ul>
                <li><p>Your access and use of the Website shall at all times be in compliance with these Terms and all applicable laws.</p></li>
                <li><p>You shall not use any contents and information from the Website to copy, sell, rent, lease, license or otherwise make available to any third parties.</p></li>
                <li><p>You shall not make any representation, warranties, guarantees or promises on behalf of the  Company.</p></li>
                <li><p>The trade dress, design, layout, structure of the Website is as per the Company’s sole discretion and control. The limited rights granted to You under these Terms do not include any rights in the trade dress, design, layout, structure of the Website. The Company exclusively owns the intellectual property rights in and to such elements of the Website.  </p></li>
                <li><p>It shall solely be Your responsibility to procure and arrange the hardware, software, network, internet or such other requirements necessary to facilitate Your use and access of the Website. The Company shall not be under an obligation to facilitate Your access and use of the Website.</p></li>
            </ul>

            <h2>Confidentiality</h2>
            <p>The Company and User may have access to the conﬁdential information of each other. Each party acknowledges that the conﬁdential information constitutes valuable, special and unique property of the concerned party. Each party will treat all such conﬁdential information with the same level of security with which it treats its own conﬁdential information. The parties shall use the confidential information only in performing their respective obligations under these Terms and shall not otherwise use such confidential information for the benefit of any unrelated third party.</p>
            <p>You agree that the Company may gain access to some of Your confidential information including but not limited to Your address, other contact details, bank details etc. (together “Customer Data”) during the course of sale and purchase of Services from the Website. All such Customer Data shall be collected, accessed, stored, used, processed, shared, transferred, backed up or deleted only in accordance with the confidentiality and data protection laws and regulations in India for the time being in force. The Company and Website shall use the Customer Data only for or in relation to the purpose of sale and purchase of Services by You from this Website and not for any other reason whatsoever, unless required by law. </p>

            <h2>Intellectual Property</h2>
            <p>The User shall respect and not violate the intellectual property of the Company in the information traded including the trademarks, business know-how, trade secrets, copyright, designs etc. and any other proprietary rights, title and interest in and to the Services (“Intellectual Property”). The domain name https://boothx.app is owned and operated by the Company and is the Intellectual Property of the Company. The Company and its licensors shall be the exclusive owners of its Intellectual Property and You shall not claim any right over it.</p>
            <p>All material on the Website is protected by copyrights, trademarks, and other intellectual property rights of the Company. You must not copy, duplicate, reproduce, record, download, republish, upload, post, transmit or distribute such material in any way, including by email or other electronic means and whether directly or indirectly and You must not assist any other person to do so without the prior written consent of the Company. Modification of the materials or any other use of the materials for any purpose not permitted by these Terms is a violation of the copyrights, trademarks and other proprietary rights, and is prohibited.</p>
            <p>Nothing stated in these Terms or stated elsewhere shall convey to the User any right, license, title, interest in or to the Company’s Intellectual Property, property interest, license or right. In addition, the User shall have no right to use any Company trade name, trademark, logo, service mark or any other Intellectual Property without the express written permission of the Company.</p>

            <h2>Links to Other Web Sites</h2>
            <p>Our Website or Services may contain links to third party web sites or services that are not owned or controlled by Us. The Company has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third-party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites. You acknowledge and agree that Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such third-party web sites or services. We strongly advise You to read the terms of service and privacy policies of any third-party web sites or services that You visit.</p>

            <h2>Limitation of Liability</h2>
            <p>Except as prohibited by law, You will hold Us and Our officers, directors, employees, and agents harmless for any indirect, punitive, special, incidental, or consequential damage, however it arises (including attorneys’ fees and all related costs and expenses of litigation and arbitration, or at trial or on appeal (together “Claim”), if any, whether or not litigation or arbitration is instituted), whether in an action of contract, negligence, or other tortious action, arising out of or in connection with these Terms, including without limitation any claim for personal injury or property damage, arising from these terms and any violation by you of any federal/central, state, or local laws, statutes, rules, or regulations, even if Company has been previously advised of the possibility of such damage. Except as prohibited by law, if there is liability found on the part of Company, it will be limited to the amount paid for the Services during the six months period prior to the date of such Claim, and under no circumstances will there be consequential or punitive damages.</p>

            <h2>Force Majeure</h2>
            <p>The Company is not liable for failure to perform any of its obligations, if such failure is as a result of Acts of God (including fire, flood, earthquake, storm, hurricane or other natural disaster), war, epidemics and pandemics, invasion, act of foreign enemies, hostilities (regardless of whether war is declared), civil war, rebellion, revolution, insurrection, military or usurped power or confiscation, terrorist activities, nationalization, government sanction, blockage, embargo, labour dispute, strike, lockout or any interruption or any failure of electricity or server, system, computer, internet or telephone service or any other reasons beyond the reasonable control of the Company.</p>

            <h2>Governing Law</h2>
            <p>These Terms shall be governed by and construed in accordance with the laws of India, without regard to conflict of law principles. Further, these Terms shall be subject to the exclusive jurisdiction of the competent courts located in Pune, India and You hereby accede to and accept the jurisdiction of such courts.</p>

            <h2>Changes to Services</h2>
            <p>We reserve the right to withdraw or amend Our Services, and any service or material We provide via the Services, in Our sole discretion without notice. We will not be liable if for any reason all or any part of the Services is unavailable at any time or for any period. From time to time, We may restrict access to some parts of the Services, or the entire Services, to users, including registered users.</p>

            <h2>Waiver and Severability</h2>
            <p>No waiver by Company of any term or condition set forth in herein shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Company to assert a right or provision under these Terms shall not constitute a waiver of such right or provision.</p>
            <p>If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms will continue in full force and effect.</p>

            <h2>Acknowledgement</h2>
            <p>By using the Website or other Services provided by Us, You acknowledge that You have read these Terms and agree to be bound by it.</p>

            <h2>Contact Us</h2>
            <p>Please send Your feedback, comments, requests for technical support by email at <a href="mailto:contact@lastbyte.co.in">contact@lastbyte.co.in</a>.</p>
            <br />
            <br />
        </Box>
    );
});

export default TermsOfServicePage;
