import { Grid } from '@mui/material';
import React from 'react';
import UserSignupForm from '../../components/userSignupForm/UserSignupForm';

const UserSignupPage: React.FC = React.memo(() => {

    return (
        <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}>
            <Grid item xl={6} md={6} lg={6} sm={12} xs={12}>
                <UserSignupForm />
            </Grid>
        </Grid>
    );
});

export default UserSignupPage;
