import React from "react";
import { CircularProgress } from "@mui/material";

interface IProps {
  isLoading: boolean;
  text?: string;
  size?: number;
}

export const Loading: React.FC<IProps> = (props) => {
  const { isLoading, text, size = 25 } = props;
  return (
    <>
      {!isLoading && `${text || ""}`}
      {isLoading && <CircularProgress size={size} color={"inherit"} />}
    </>
  );
};
