import { Navigate, Outlet } from "react-router-dom";
import HomeLayout from "../components/homeLayout/HomeLayout";
import { getPagesForCapabilites } from "../models/Page";
import { Auth } from "./Auth";

function PrivateRouteWithLayout() {
  const userSettings = Auth.getInstance().getUserSettings();
  if (!userSettings) {
    return <Navigate to="/login" />;
  }

  const pages = getPagesForCapabilites(userSettings.profileView.capabilities);
  return (
    <HomeLayout pages={pages}>
      <Outlet />
    </HomeLayout>
  );
};

export default PrivateRouteWithLayout;
