import { useCallback, useContext, useEffect, useState } from "react";
import { IAddExpenseRequest, IEventView, IExpenseView } from "../../models/Event";
import GenericDialog from "../genericDialog/GenericDialog";
import { ToastContext } from "../../contexts/ToastContext";
import { SubmitHandler, useForm } from "react-hook-form";
import { addExpense } from "../../api/EventsApi";
import { BoothXError } from "../../models/BoothXError";
import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import styles from './AddExpenseDialog.module.css';
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import { uploadExpenseReceipt } from "../../api/FirebaseStorageUploadApi";
import { Loading } from "../loading/Loading";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { DateTime } from "luxon";

interface IProps {
    event: IEventView;
    onClose: (didAddExpense: boolean, expense?: IExpenseView) => void;
}

const classes = { root: styles.input };

const AddExpenseDialog: React.FC<IProps> = (props) => {

    const { event, onClose } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [isUploadingReceipt, setIsUploadingReceipt] = useState(false);
    const [receiptFile, setReceiptFile] = useState<File | null>(null);
    const [uuid, setUuid] = useState<string>();
    const [receiptPath, setReceiptPath] = useState<string>();

    const { showToast } = useContext(ToastContext);

    const { register, handleSubmit, formState: { errors }, clearErrors } = useForm<IAddExpenseRequest>();

    const { open } = useDropzone({
        onDropAccepted: (acceptedFiles) => setReceiptFile(acceptedFiles[0]),
        onDropRejected: () => showToast("File size cannot be more than 20 MB", "error"),
        multiple: false,
        maxSize: 20 * 1024 * 1024,
        accept: {
            'application/pdf': ['.pdf'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png']
        },
    });

    const onSubmit: SubmitHandler<IAddExpenseRequest> = async (data) => {
        setIsLoading(true);
        data.imagePath = receiptPath;
        data.clientSideId = uuid!; // safe to unwrap here
        data.expenseTimeInUTC = DateTime.now().toUTC().toSeconds();
        const response = await addExpense(event.id, data);
        setIsLoading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        showToast("Expense added successfully", "success");
        onClose(true, response);
    };

    const handleUploadReceipt = useCallback(async (file: File, expenseClientId: string) => {
        setIsUploadingReceipt(true);
        const response = await uploadExpenseReceipt(event, expenseClientId, file);
        setIsUploadingReceipt(false);
        if (response instanceof BoothXError) {
            showToast(response.message, "error");
            return;
        }

        setReceiptPath(response);
    }, [showToast, event]);

    useEffect(() => {
        if (receiptFile === null || uuid === undefined) {
            return;
        }

        handleUploadReceipt(receiptFile, uuid);
    }, [receiptFile, uuid, handleUploadReceipt]);

    useEffect(() => {
        setUuid(uuidv4());
    }, []);

    return (
        <GenericDialog
            title={"Add Expense"}
            isOpen={true}
            primaryButtonText={"Add"}
            primaryButtonLoading={isLoading}
            primaryButtonAction={handleSubmit(onSubmit)}
            primaryButtonDisabled={isUploadingReceipt}
            onClose={() => {
                onClose(false);
            }}
        >
            <Grid container spacing={0} padding={0}>
                <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    <TextField
                        {...register("currency", { required: true })}
                        variant="filled"
                        select
                        defaultValue=""
                        InputProps={{
                            disableUnderline: true,
                            classes: classes
                        }}
                        margin="normal"
                        fullWidth
                        label="Currency*"
                        name="currency"
                        error={errors.currency === undefined ? false : true}
                        helperText={errors.currency === undefined ? "" : "Currency is required"}
                    >
                        {event.currencies.map((currency) => (
                            <MenuItem key={currency} value={currency}>
                                {currency}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    <TextField
                        {...register("category", { required: true })}
                        variant="filled"
                        select
                        defaultValue=""
                        InputProps={{
                            disableUnderline: true,
                            classes: classes
                        }}
                        margin="normal"
                        fullWidth
                        label="Category*"
                        name="category"
                        error={errors.category === undefined ? false : true}
                        helperText={errors.category === undefined ? "" : "Category is required"}
                    >
                        {event.expenseCategories.map((category) => (
                            <MenuItem key={category} value={category}>
                                {category}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    <TextField
                        {...register("amount", { required: true })}
                        variant="filled"
                        InputProps={{
                            disableUnderline: true,
                            classes: classes
                        }}
                        margin="normal"
                        fullWidth
                        label="Amount"
                        name="amount"
                        type="number"
                        autoComplete="off"
                        error={errors.amount === undefined ? false : true}
                        helperText={errors.amount === undefined ? "" : "Amount is required"}
                        onChange={() => {
                            clearErrors("amount");
                        }}
                    />
                </Grid>
                <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    <TextField
                        {...register("description", { required: true })}
                        variant="filled"
                        InputProps={{
                            disableUnderline: true,
                            classes: classes
                        }}
                        margin="normal"
                        fullWidth
                        multiline
                        rows={4}
                        label="Description"
                        name="description"
                        autoComplete="off"
                        error={errors.description === undefined ? false : true}
                        helperText={errors.description === undefined ? "" : "Description is required"}
                        onChange={() => {
                            clearErrors("description");
                        }}
                    />
                </Grid>
                <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    <Typography
                        component="div"
                        sx={{
                            textAlign: "left",
                            mt: 2,
                            mb: 1,
                            display: "flex",
                            justifyContent: "start"
                        }}>
                        Upload Receipt (optional):&nbsp;
                        <button onClick={open}>Select</button>&nbsp;
                        <Typography component="span">
                            {receiptFile && (
                                <>
                                    {receiptFile.name} {isUploadingReceipt ? <Loading isLoading={true} size={12} /> : <DoneAllIcon sx={{ height: 18, color: "green" }} />}
                                </>
                            )}
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
        </GenericDialog>
    );
}

export default AddExpenseDialog;
