import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/login/LoginPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import PrivateRouteWithLayout from './utils/PrivateRouteWithLayout';
import LogoutPage from './pages/logout/LogoutPage';
import CreateEventPage from './pages/events/CreateEventPage';
import UserSignupPage from './pages/signup/UserSignupPage';
import CompanySignupPage from './pages/signup/CompanySignupPage';
import UsersPage from './pages/users/UsersPage';
import DocumentsPage from './pages/documents/DocumentsPage';
import PublicRouteWithLogo from './utils/PublicRouteWithLogo';
import EventPage from './pages/events/EventPage';
import EventsPage from './pages/events/EventsPage';
import SetPasswordPage from './pages/setPasswordPage/SetPasswordPage';
import PasswordRecoveryPage from './pages/passwordRecoveryPage/PasswordRecoveryPage';
import EmailTemplatesPage from './pages/emailTemplates/EmailTemplatesPage';
import ProfilePage from './pages/profile/ProfilePage';
import PrivacyPolicyPage from './pages/privacyPolicy/PrivacyPolicyPage';
import TermsOfServicePage from './pages/termsOfService/TermsOfServicePage';
import Page404 from './pages/page404/Page404';
import PaymentSessionPage from './pages/paymentSession/PaymentSessionPage';
import EditEventPage from './pages/events/EditEventPage';
import BillingPage from './pages/billingPage/RewardsPage';

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/logout" element={<LogoutPage />} />

                    <Route path="/" element={<PrivateRouteWithLayout />}>
                        <Route path="" element={<DashboardPage />} />
                        <Route path="users" element={<UsersPage />} />
                        <Route path="profile" element={<ProfilePage />} />
                        <Route path="billing" element={<BillingPage />} />
                        <Route path="collaterals" element={<DocumentsPage />} />
                        <Route path="emails" element={<EmailTemplatesPage />} />
                        <Route path="events">
                            <Route path="" element={<EventsPage />} />
                            <Route path=":eventId" element={<EventPage />} />
                            <Route path=":eventId/edit" element={<EditEventPage />} />
                            <Route path="create" element={<CreateEventPage />} />
                        </Route>
                        <Route path="payment-session">
                            <Route path=":paymentSessionId/:status" element={<PaymentSessionPage />} />
                        </Route>

                        <Route path="*" element={<Page404 />} />
                    </Route>

                    {/* Public route needs to always be after private route since the base path for both is "/" */}
                    <Route path="/" element={<PublicRouteWithLogo />}>
                        <Route path="login" element={<LoginPage />} />
                        <Route path="signup">
                            <Route path="user" element={<UserSignupPage />} />
                            <Route path="company" element={<CompanySignupPage />} />
                        </Route>
                        <Route path="recover">
                            <Route path="" element={<PasswordRecoveryPage />} />
                            <Route path="set-password" element={<SetPasswordPage />} />
                        </Route>
                        <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
                        <Route path="terms-of-service" element={<TermsOfServicePage />} />

                        <Route path="*" element={<Page404 />} />
                    </Route>

                    {/* <Route path="*" element={<Page404/>}/> */}

                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
