export interface IPlanCost {
    eventPlanType: EventPlanType;
    currencyAmount: ICurrencyAmount;
    features: IFeatureInclusions;
};

export interface IFeatureInclusions {
    numberOfEvents: number;
    numOfLeadsCaptureAllowed: number;
    numOfLeadsExportAllowed: number;
    numOfDocumentsAllowed: number;
    numOfExpenseTrackingAllowed: number;
    numOfUsersAllowed: number;
    retentionPeriodInDays: number;
}

export interface IAddOnCost {
    addOnType: AddOnType;
    currencyAmount: ICurrencyAmount;
};

export interface IBillingPlanConfig {
    planCosts: IPlanCost[];
    addOnCosts: IAddOnCost[];
}

export interface ICurrencyAmount {
    amount: number;
    currency: string;
}

export enum AddOnType {
    ADD_ON_USER,
    ADD_ON_DOCUMENT,
    ADD_ON_LEAD_EXPORT
}

export enum EventPlanType {
    TRIAL = "TRIAL",
    STARTER = "STARTER",
    PRO = "PRO",
    ENTERPRISE = "ENTERPRISE"
}

export interface IPaymentSession {
    id: string;
    paymentUrl?: string;
    status: PaymentSessionStatus;
    paymentSessionTargetAction: PaymentSessionTargetAction;
    targetEventId?: string;
}

export enum PaymentSessionStatus {
    INITIATED = "INITIATED",
    COMPLETED = "COMPLETED",
    CANCELLED = "CANCELLED",
    EXPIRED = "EXPIRED"
}

export enum PaymentSessionTargetAction {
    CREATE_EVENT = "CREATE_EVENT",
    ADD_FUNDS = "ADD_FUNDS"
}

export interface IBillingItem {
    id: string;
    currency: string;
    amountTotal: number;
    planType: EventPlanType;
    addOnType: AddOnType[];
    paymentSessionTargetAction: PaymentSessionTargetAction;
    createdByUserDisplayName: string;
    creationTime: number;
    paymentStatus: PaymentSessionStatus;
}
