import { useState, useEffect, Dispatch, SetStateAction } from "react";

function getStorageValue<T>(key: string, defaultValue?: T): T | undefined {
    const saved = localStorage.getItem(key);
    if (!saved) {
        return defaultValue;
    }

    return JSON.parse(saved);
}

export function useLocalStorage<T>(key: string, defaultValue?: T): [T | undefined, Dispatch<SetStateAction<T | undefined>>] {
    const [value, setValue] = useState(() => {
        return getStorageValue(key, defaultValue);
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
};
