import { BoothXError } from "../models/BoothXError";
import { IGetDashboardStatsResponse } from "../models/Dashboard";
import { BACKEND_URL_V1, get } from "../utils/Api";
import { Auth } from "../utils/Auth";

export const getDashboardStats = async (): Promise<IGetDashboardStatsResponse | BoothXError> => {
    try {
        const userId = Auth.getInstance().getUserSettings()?.profileView.id ?? "";
        // adding user ID so that if user logs out and logs in with another user the network cache is invalidated
        const response = await get<IGetDashboardStatsResponse>(`${BACKEND_URL_V1}/dashboard/stats?id=${userId}`);
        if (response.parsedBody) {
            return response.parsedBody;
        }

        const errorMessage = response.serverError?.error.message ?? "Dashboard stats could not be retrieved";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}
