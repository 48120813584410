import { Box, Grid } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { IGetSettingsResponse } from "../../models/User";
import { ToastContext } from "../../contexts/ToastContext";
import { BoothXError } from "../../models/BoothXError";
import { getSettings } from "../../api/UsersApi";
import UpdateUserForm from "../../components/updateUserForm/UpdateUserForm"
import UpdateCompanyForm from "../../components/updateCompanyForm/UpdateCompanyForm";
import { Loading } from "../../components/loading/Loading";

const ProfilePage: React.FC = React.memo(() => {

    const [userSettings, setUserSettings] = useState<IGetSettingsResponse>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { showToast } = useContext(ToastContext);

    const fetchUserDetails = useCallback(async () => {
        setIsLoading(true);
        const response = await getSettings();
        setIsLoading(false);
        if (response instanceof BoothXError) {
            showToast(response.message, "error");
            return;
        }

        setUserSettings(response);
    }, [showToast]);

    useEffect(() => {
        fetchUserDetails();
    }, [fetchUserDetails])

    return (
        <Box sx={{ padding: 1 }}>
            {isLoading && <Loading isLoading={isLoading} />}

            {!isLoading && userSettings && (
                <Grid
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-around"
                >
                    <Grid item width="100%" xl={6} md={6} lg={6} sm={12} xs={12} sx={{ padding: 2 }}>
                        <UpdateUserForm userProfile={userSettings.profileView} />
                    </Grid>
                    <Grid item width="100%" xl={6} md={6} lg={6} sm={12} xs={12} sx={{ padding: 2 }}>
                        <UpdateCompanyForm companyProfile={userSettings.companyView} />
                    </Grid>
                </Grid>
            )}
        </Box>
    );
});


export default ProfilePage;
