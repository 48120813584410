import { BoothXError } from "../models/BoothXError";
import { IEmailData, IEmailTemplateView } from "../models/EmailTemplate";
import { BACKEND_URL_V1, deletee, get, post } from "../utils/Api";

interface IGetEmailTemplatesResponse {
    emailTemplates: IEmailTemplateView[];
}

interface ICreateEmailTemplateResponse {
    emailTemplate: IEmailTemplateView;
}

export const getEmailTemplates = async (): Promise<IEmailTemplateView[] | BoothXError> => {
    try {
        const response = await get<IGetEmailTemplatesResponse>(`${BACKEND_URL_V1}/emailTemplates`);
        if (response.parsedBody?.emailTemplates) {
            return response.parsedBody.emailTemplates;
        }

        const errorMessage = response.serverError?.error.message ?? "Email templates could not be retrieved";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}

export const deleteEmailTemplate = async (emailTemplateId: string): Promise<null | BoothXError> => {
    try {
        const response = await deletee(`${BACKEND_URL_V1}/emailTemplates/${emailTemplateId}`);
        if (Math.floor(response.status / 100) === 2) {
            return null;
        }

        const errorMessage = response.serverError?.error.message ?? "Email template could not be deleted";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}

export const createEmailTemplate = async (emailData: IEmailData, headerImage: File | null): Promise<IEmailTemplateView | BoothXError> => {
    try {
        const formData = new FormData();
        if (headerImage) {
            formData.append("headerImage", headerImage);
        }

        Object.entries(emailData).forEach(([key, value]) => formData.append(key, value));

        const response = await post<ICreateEmailTemplateResponse>(`${BACKEND_URL_V1}/emailTemplates`, formData, { method: "post", body: formData });
        if (response.parsedBody) {
            return response.parsedBody.emailTemplate;
        }

        const errorMessage = response.serverError?.error.message ?? "Email template could not be added";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}

export const verifyEmailTemplate = async (emailData: IEmailData, headerImage: File | null): Promise<null | BoothXError> => {
    try {
        const formData = new FormData();
        if (headerImage) {
            formData.append("headerImage", headerImage);
        }

        Object.entries(emailData).forEach(([key, value]) => formData.append(key, value));

        const response = await post(`${BACKEND_URL_V1}/emailTemplates/verify`, formData, { method: "post", body: formData });
        if (Math.floor(response.status / 100) === 2) {
            return null;
        }

        const errorMessage = response.serverError?.error.message ?? "Email template could not be added";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}

export const updateEmailTemplate = async (emailTemplateId: string, emailData: IEmailData, headerImage: File | null): Promise<IEmailTemplateView | BoothXError> => {
    try {
        const formData = new FormData();
        if (headerImage) {
            formData.append("headerImage", headerImage);
        }

        Object.entries(emailData).forEach(([key, value]) => formData.append(key, value));

        const response = await post<ICreateEmailTemplateResponse>(`${BACKEND_URL_V1}/emailTemplates/${emailTemplateId}`, formData, { method: "post", body: formData });
        if (response.parsedBody) {
            return response.parsedBody.emailTemplate;
        }

        const errorMessage = response.serverError?.error.message ?? "Email template could not be added";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}
