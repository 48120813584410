import { useCallback, useContext, useState } from "react";
import { IUserProfileView } from "../../models/User";
import GenericDialog from "../genericDialog/GenericDialog";
import { deleteUser } from "../../api/UsersApi";
import { ToastContext } from "../../contexts/ToastContext";
import { BoothXError } from "../../models/BoothXError";

interface IProps {
    user: IUserProfileView;
    onClose: (didDeleteUser: boolean) => void;
}

const DeleteUserConfirmationDialog: React.FC<IProps> = (props) => {
    const { user, onClose } = props;
    const [isLoading, setIsLoading] = useState(false);
    const { showToast } = useContext(ToastContext);

    const onDeleteUser = useCallback(async () => {
        setIsLoading(true);
        const response = await deleteUser(user.id);
        setIsLoading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        showToast("User deleted successfully", "success");
        onClose(true);
    }, [showToast, onClose, user.id]);

    return (
        <GenericDialog
            isOpen={true}
            onClose={() => {
                onClose(false);
            }}
            title={"Delete User"}
            primaryButtonText={"Delete"}
            primaryButtonAction={onDeleteUser}
            primaryButtonLoading={isLoading}
        >
            Are you sure you want to delete {user.displayName}?
        </GenericDialog>
    )
}

export default DeleteUserConfirmationDialog;
