import React, { useContext, useState } from "react";
import GenericDialog from "../genericDialog/GenericDialog";
import { Grid, InputAdornment, TextField } from "@mui/material";
import { getCurrencySymbol } from "../../utils/DisplayUtils";
import { Auth } from "../../utils/Auth";
import { SubmitHandler, useForm } from "react-hook-form";
import { createAddFundsSession } from "../../api/Billing";
import { BoothXError } from "../../models/BoothXError";
import { ToastContext } from "../../contexts/ToastContext";

interface IProps {
    onClose: () => void;
    isOpen: boolean;
}

interface IAddAmountRequest {
    amount: number;
} 

const AddFundsDialog: React.FC<IProps> = (props: IProps) => {
    
    const { onClose, isOpen } = props;
    let userSettings = Auth.getInstance().getUserSettings();
    
    const { showToast } = useContext(ToastContext);
    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm<IAddAmountRequest>();

    const handleOnClickAdd: SubmitHandler<IAddAmountRequest> = async (data) => {
        setIsLoading(true);
        const responseOrError = await createAddFundsSession(data.amount);
        setIsLoading(false);
        if (responseOrError instanceof BoothXError) {
            showToast(responseOrError.message, "error");
            return;
        }

        if (responseOrError.paymentSession.paymentUrl) {
            window.location.href = (responseOrError.paymentSession.paymentUrl);
            return;
        }

        showToast("Something went wrong, please try again later", "error");
    }

    return (
        <GenericDialog
            isOpen={isOpen}
            onClose={() => onClose()}
            title={"Add funds to wallet"}
            primaryButtonText={"Add Funds"}
            primaryButtonAction={handleSubmit(handleOnClickAdd)}
            width="sm"
            primaryButtonLoading={isLoading}
        >
                <Grid container spacing={0} padding={0}>
                    <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                        {userSettings && (
                            <TextField
                                {...register("amount", { required: true })}
                                variant="filled"
                                InputProps={{
                                    disableUnderline: true,
                                    startAdornment: <InputAdornment position="start">{getCurrencySymbol(userSettings?.companyView.homeCurrency)}</InputAdornment>
                                }}
                                margin="normal"
                                fullWidth
                                label="Amount"
                                name="amount"
                                autoComplete="off"
                                placeholder="Example: 10000"
                                error={errors.amount === undefined ? false : true}
                                helperText={errors.amount === undefined ? "" : "Amount is required"}
                            />
                        )}
                    </Grid>
                </Grid>
        </GenericDialog>
    );
};

export default AddFundsDialog;