import { useCallback, useContext, useState } from "react";
import GenericDialog from "../genericDialog/GenericDialog";
import { ToastContext } from "../../contexts/ToastContext";
import { BoothXError } from "../../models/BoothXError";
import { IEmailTemplateView } from "../../models/EmailTemplate";
import { deleteEmailTemplate } from "../../api/EmailTemplateApis";

interface IProps {
    emailTemplate: IEmailTemplateView;
    onClose: (didDeleteEmailTemplate: boolean) => void;
}

const DeleteEmailTemplatetConfirmationDialog: React.FC<IProps> = (props) => {
    const { emailTemplate, onClose } = props;
    const [isLoading, setIsLoading] = useState(false);
    const { showToast } = useContext(ToastContext);

    const onDeleteEmailTemplate = useCallback(async () => {
        setIsLoading(true);
        const response = await deleteEmailTemplate(emailTemplate.id);
        setIsLoading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        showToast("Email template deleted successfully", "success");
        onClose(true);
    }, [showToast, onClose, emailTemplate.id]);

    return (
        <GenericDialog
            isOpen={true}
            onClose={() => {
                onClose(false);
            }}
            title={"Delete Email Template"}
            primaryButtonText={"Delete"}
            primaryButtonAction={onDeleteEmailTemplate}
            primaryButtonLoading={isLoading}
        >
            Are you sure you want to delete email template with name "{emailTemplate.name}"?
        </GenericDialog>
    )
}

export default DeleteEmailTemplatetConfirmationDialog;
