import { Grid } from '@mui/material';
import React from 'react';
import CompanySignupForm from '../../components/companySignupForm/CompanySignupForm';

const CompanySignupPage: React.FC = React.memo(() => {

    return (
        <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}>
            <Grid item xl={5} md={5} lg={5} sm={12} xs={12}>
                <CompanySignupForm />
            </Grid>
        </Grid>
    );
});

export default CompanySignupPage;
