import { Box, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useEventsCache from "../../hooks/EventsHook";
import { BoothXError } from "../../models/BoothXError";
import { IEventView } from "../../models/Event";
import { getEvent } from "../../api/EventsApi";
import { Loading } from "../../components/loading/Loading";
import EventForm from "../../components/EventForm/EventForm";
import { ToastContext } from "../../contexts/ToastContext";

const EditEventPage: React.FC = React.memo(() => {
    const { eventId } = useParams();
    const [event, setEvent] = useState<IEventView>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { showToast } = useContext(ToastContext);
    const { getEventFromCache } = useEventsCache();

    useEffect(() => {
        const fetchEvent = async () => {
            setIsLoading(true);
            const response = await getEvent(eventId!);
            setIsLoading(false);
            if (response instanceof BoothXError) {
                showToast(response.getErrorMessage(), "error");
                return;
            }
            setEvent(response);
        };

        const eventView = getEventFromCache(eventId ?? "");
        if (eventView === undefined) { // will never be undefined since we are getting from cache, but just to be safe and to not use ? operator
            fetchEvent();
        } else {
            setEvent(eventView);
        }
    }, [getEventFromCache, eventId, showToast]);

    return (
        <Box sx={{ width: '100%' }}>
            <Loading isLoading={isLoading} />
            {event && (
                <Grid container spacing={2} sx={{ padding: 3 }}>
                    <Grid item md={12} sm={12}>
                        <EventForm event={event} />
                    </Grid>
                </Grid>
            )}
        </Box>

    );
});

export default EditEventPage;
