import React, { useEffect, useState } from "react";


interface IProps {
    initialNumber: number;
    targetNumber: number;
    totalTime?: number;
}

const CountingAnimation: React.FC<IProps> = React.memo((props) => {
    const { initialNumber, targetNumber } = props;
     // Total time for animation in milliseconds
    const totalTime = 2000;
    const [currentNumber, setCurrentNumber] = useState(initialNumber);

    useEffect(() => {
        const totalIncrements = targetNumber - initialNumber;
        const incrementSize = Math.ceil(totalIncrements / (totalTime / 100)); // Calculate increment size
        let lastNumber = initialNumber;

        const interval = setInterval(() => {
            lastNumber += incrementSize;
            if (lastNumber >= targetNumber) {
                setCurrentNumber(targetNumber);
                clearInterval(interval);
            } else {
                setCurrentNumber(lastNumber);
            }
        }, 100);

        return () => clearInterval(interval);
    }, [initialNumber, targetNumber]);

    return (
        <>{currentNumber}</>
    );
});

export default CountingAnimation;