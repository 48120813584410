export enum Capability {
    SUPER = "SUPER",
    ALL = "ALL",

    VIEW_USERS = "VIEW_USERS",
    USER_MANAGEMENT = "USER_MANAGEMENT",

    VIEW_EVENT = "VIEW_EVENT",
    MANAGE_EVENT = "MANAGE_EVENT",
    MANAGE_ALL_EVENTS = "MANAGE_ALL_EVENTS",
    CREATE_EVENT = "CREATE_EVENT",
    BUY_EVENT_ADD_ONS = "BUY_EVENT_ADD_ONS",

    MANAGE_LEADS = "MANAGE_LEADS",
    MANAGE_ALL_LEADS = "MANAGE_ALL_LEADS",
    VIEW_LEADS = "VIEW_LEADS",

    MANAGE_EXPENSES = "MANAGE_EXPENSES",
    MANAGE_ALL_EXPENSES = "MANAGE_ALL_EXPENSES",
    VIEW_EXPENSES = "VIEW_EXPENSES",

    VIEW_TASKS = "VIEW_TASKS",
    MANAGE_TASKS = "MANAGE_TASKS",

    MANAGE_DOCUMENTS = "MANAGE_DOCUMENTS",
    VIEW_DOCUMENTS = "VIEW_DOCUMENTS",

    MANAGE_EMAIL_TEMPLATES = "MANAGE_EMAIL_TEMPLATES",
    VIEW_EMAIL_TEMPLATES = "VIEW_EMAIL_TEMPLATES",
}
