import { useCallback, useContext, useEffect, useState } from "react";
import { getExpenseAttachementUrl, getExpenses, getExpensesReport } from "../../api/EventsApi";
import { BoothXError } from "../../models/BoothXError";
import { IEventView, IExpenseView } from "../../models/Event";
import { ToastContext } from "../../contexts/ToastContext";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import GenericTable from "../genericTable/GenericTable";
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { DateTime } from "luxon";
import { Loading } from "../../components/loading/Loading";
import { displayAmountWithCurrency, getSymbolForCurrency } from "../../utils/Utils";
import AddExpenseDialog from "../addExpenseDialog/AddExpenseDialog";
import { canUserAddOrUpdateOrDeleteExpenses } from "../../utils/AuthUtils";
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteExpenseConfirmationDialog from "../deleteExpenseConfirmationDialog/DeleteExpenseConfirmationDialog";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

interface IEventExpensesProps {
    event: IEventView;
}

export const EventExpenses = (props: IEventExpensesProps) => {
    const { event } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [expenses, setExpenses] = useState<IExpenseView[]>([]);
    const [isDownloading, setIsDownloading] = useState(false);
    const [selectedExpense, setSelectedExpense] = useState<IExpenseView>();
    const [showAddExpenseDialog, setShowAddExpenseDialog] = useState<boolean>(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const allowAddOrUpdateOrDeleteExpense = canUserAddOrUpdateOrDeleteExpenses();

    const { showToast } = useContext(ToastContext);

    const fetchExpenses = useCallback(async () => {

        setIsLoading(true);
        const response = await getExpenses(event.id);
        setIsLoading(false);

        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        const expensesExceedingPlan = response.expensesExceedingPlan.map(expense => {
            expense.isOverLimit = true;
            return expense;
        });


        setExpenses(response.expenses.concat(expensesExceedingPlan));
    }, [showToast, event]);

    useEffect(() => {
        fetchExpenses();
    }, [fetchExpenses]);

    const downloadExpenseReport = async () => {
        setIsDownloading(true);
        const response = await getExpensesReport(event.id);
        setIsDownloading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
        }
    }

    const handleDownloadAttachment = async (expenseId: string) => {
        setIsDownloading(true);
        const response = await getExpenseAttachementUrl(event.id, expenseId);
        setIsDownloading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        window.open(response, "_blank");
    }

    const showDeleteConfirmationDialog = () => {
        setShowConfirmationDialog(true);
    }

    const hideDeleteConfirmationDialog = (didDeleteExpense: boolean) => {
        setShowConfirmationDialog(false);
        if (didDeleteExpense) {
            fetchExpenses();
        }
    }

    const currencyFilterValue = [...new Set(expenses.map(expense => expense.currency))];
    const categoryFilterValues = [...new Set(expenses.map(expense => expense.category))];
    const userFilterValues = [...new Set(expenses.map(expense => expense.createdByUserName))];

    const handleAddExpenseDialogClose = (didAddExpense: boolean, _?: IExpenseView) => {
        didAddExpense && fetchExpenses();
        setShowAddExpenseDialog(false);
    }

    const NotesTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} arrow />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 350,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));



    return (
        <Box>
            {showConfirmationDialog && selectedExpense && <DeleteExpenseConfirmationDialog
                expense={selectedExpense}
                onClose={hideDeleteConfirmationDialog}
            />}
            {showAddExpenseDialog && <AddExpenseDialog event={event} onClose={handleAddExpenseDialogClose} />}
            <Box
                component="div"
                sx={{
                    mb: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                {allowAddOrUpdateOrDeleteExpense && (
                    <Button
                        aria-label="add"
                        variant="text"
                        onClick={() => setShowAddExpenseDialog(true)}
                        sx={{ marginRight: 2, fontWeight: "bold", textTransform: "none" }}
                    >
                        + Add Expense
                    </Button>
                )}
                <Button
                    aria-label="add"
                    variant="text"
                    onClick={downloadExpenseReport}
                    disabled={isDownloading}
                    sx={{ fontWeight: "bold", textTransform: "none" }}
                >
                    {isDownloading && <Loading isLoading={isDownloading} />}
                    {!isDownloading && (
                        <>
                            <FileDownloadOutlinedIcon />
                            Download Report
                        </>
                    )}
                </Button>
            </Box>
            <Grid container spacing={2}>
                <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    <GenericTable
                        data={expenses}
                        headers={[
                            {
                                header: "Price",
                                sortByField: "amount",
                                filter: currencyFilterValue.length === 1 ? undefined : {
                                    filterValues: currencyFilterValue,
                                    isValid: (expense: IExpenseView, selectedFilter: string) => expense.currency === selectedFilter
                                }
                            },
                            { header: "Description" },
                            {
                                header: "Category",
                                filter: categoryFilterValues.length === 1 ? undefined : {
                                    filterValues: categoryFilterValues,
                                    isValid: (expense: IExpenseView, selectedFilter: string) => expense.category === selectedFilter
                                }
                            },
                            {
                                header: "Added By",
                                filter: userFilterValues.length === 1 ? undefined : {
                                    filterValues: userFilterValues,
                                    isValid: (expense: IExpenseView, selectedFilter: string) => expense.createdByUserId === selectedFilter
                                }
                            },
                            { header: "Date Added", sortByField: "expenseTimeInUTC" },
                            { header: "Attachment" },
                            { header: "Delete" }
                        ]}
                        isLoading={isLoading}
                        pagination
                        dataRenderer={(expenseRow, column) => {
                            switch (column) {
                                case 0:
                                    if (expenseRow.isOverLimit) {
                                        return `${getSymbolForCurrency(expenseRow.currency)}***`;
                                    }

                                    return displayAmountWithCurrency(expenseRow.amount, expenseRow.currency);
                                case 1:
                                    return (
                                        <>
                                            {(!expenseRow.description || expenseRow.description.length === 0) && "N/A"}
                                            {expenseRow.description && expenseRow.description.length > 0 &&
                                                <NotesTooltip
                                                    title={<Typography>{expenseRow.description}</Typography>}
                                                    placement="bottom-start"
                                                >
                                                    <Box
                                                        sx={{
                                                            display: "display-box",
                                                            overflow: "hidden",
                                                            width: 400,
                                                            "white-space": "nowrap",
                                                            "text-overflow": "ellipsis",
                                                            "-webkit-line-clamp": 2,
                                                            "-webkit-box-orient": "vertical",
                                                        }}
                                                    >
                                                        {expenseRow.description}
                                                    </Box>
                                                </NotesTooltip >
                                            }
                                        </>
                                    );
                                case 2:
                                    return expenseRow.category;
                                case 3:
                                    return expenseRow.createdByUserName;
                                case 4:
                                    return DateTime.fromSeconds(expenseRow.expenseTimeInUTC).toLocaleString(DateTime.DATE_MED);
                                case 5:
                                    if (expenseRow.isOverLimit) {
                                        return "***";
                                    }

                                    if (!expenseRow.imagePath) {
                                        return "N/A"
                                    }

                                    return (
                                        <IconButton
                                            aria-label="edit"
                                            color="primary"
                                            sx={{
                                                transform: "rotate(45deg)"
                                            }}
                                            onClick={() => {
                                                setSelectedExpense(expenseRow);
                                                handleDownloadAttachment(expenseRow.id);
                                            }}
                                        >
                                            {selectedExpense && expenseRow.id === selectedExpense.id && isDownloading && <Loading isLoading={true} />}
                                            {(!selectedExpense || expenseRow.id !== selectedExpense.id || !isDownloading) && <AttachFileOutlinedIcon />}
                                        </IconButton>
                                    );
                                case 6:
                                    return (
                                        <IconButton aria-label="delete"
                                            onClick={() => {
                                                setSelectedExpense(expenseRow);
                                                showDeleteConfirmationDialog();
                                            }}
                                            disabled={!allowAddOrUpdateOrDeleteExpense}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    );
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </Box >
    );
}
