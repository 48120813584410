import React from 'react'
import { Typography } from '@mui/material'

function Page404() {
  return (
    <div>
        <Typography
            mt={15}
            variant="h1"
        >
            Error 404
        </Typography>
        <h1>Page Not Found!</h1>
    </div>
  )
}

export default Page404