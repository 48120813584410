import { Outlet } from "react-router-dom";
import { AppBar, Box, Toolbar } from "@mui/material";
import logo from '../assets/logo.png';

function PublicRouteWithLogo() {
    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: "white",
                    color: "black",
                    alignItems: { xs: "center", sm: "flex-start" }
                }}
                elevation={0}
            >
                <Toolbar sx={{ mr: 1, ml: 1 }}>
                    <img src={logo} alt='logo' width="170" />
                </Toolbar>
            </AppBar>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, height: `calc(100vh - 64px)`, padding: 0 }}
            >
                <Outlet />
            </Box>
        </Box>

    );
};

export default PublicRouteWithLogo;
