import { createContext, useCallback, useState } from "react";
import Toast, { IToastBasicProps } from "../components/toast/Toast";

export const ToastContext = createContext<{ showToast: (message: string, type: "success" | "error") => void }>({
    showToast() { },
});

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [alert, setAlert] = useState<IToastBasicProps>();

    const showToast = useCallback((message: string, type: "success" | "error") => {
        setAlert({
            message,
            type
        });
    }, []);

    return (
        <ToastContext.Provider value={{ showToast }}>
            {children}
            {alert && (
                <Toast
                    message={alert.message}
                    type={alert.type}
                    onClose={() => {
                        setAlert(undefined);
                    }}
                />
            )}
        </ToastContext.Provider>
    )
}
