import { useCallback, useContext, useState } from "react";
import GenericDialog from "../genericDialog/GenericDialog";
import { ToastContext } from "../../contexts/ToastContext";
import { BoothXError } from "../../models/BoothXError";
import { IEmailData, IEmailTemplateView } from "../../models/EmailTemplate";
import { createEmailTemplate, updateEmailTemplate } from "../../api/EmailTemplateApis";

interface IProps {
    emailData: IEmailData;
    headerImageFile: File | null;
    emailTemplate: IEmailTemplateView | undefined;
    onClose: (didAddEmailTemplate: boolean, emailTemplate?: IEmailTemplateView) => void;
}

const AddOrUpdateEmailTemplateConfirmationDialog: React.FC<IProps> = (props) => {
    const { emailData, headerImageFile, emailTemplate, onClose } = props;
    const [isLoading, setIsLoading] = useState(false);
    const { showToast } = useContext(ToastContext);

    const onAddOrUpdateEmailTemplate = useCallback(async () => {
        setIsLoading(true);
        let response;
        if (emailTemplate) {
            response = await updateEmailTemplate(emailTemplate.id, emailData, headerImageFile);
        } else {
            response = await createEmailTemplate(emailData, headerImageFile);
        }
        setIsLoading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        showToast(`Email template ${emailTemplate ? "updated" : "added"} successfully`, "success");
        onClose(true, response);
    }, [showToast, onClose, emailData, headerImageFile, emailTemplate]);

    return (
        <GenericDialog
            isOpen={true}
            onClose={() => {
                onClose(false);
            }}
            title={`${emailTemplate ? "Update" : "Add"} Email Template`}
            primaryButtonText={`Looks good, ${emailTemplate ? "update" : "add"}!`}
            primaryButtonAction={onAddOrUpdateEmailTemplate}
            primaryButtonLoading={isLoading}
            secondaryButtonText="Edit"
        >
            {`We've sent you a sample email for your ${emailTemplate ? "updated" : "new"} template. Can you confirm that it looks good?`}
        </GenericDialog>
    )
}

export default AddOrUpdateEmailTemplateConfirmationDialog
