import { BoothXError } from "../models/BoothXError";
import { ICompanyWallet } from "../models/Company";
import { BACKEND_URL_V1, get, post } from "../utils/Api";
import { ICompanySignupRequest } from "./Signup";

interface ICompanyWalletResponse {
    wallet: ICompanyWallet
}

export const updateCompany = async (companyId: string, request: ICompanySignupRequest): Promise<null | BoothXError> => {
    try {
        const response = await post(`${BACKEND_URL_V1}/companies/${companyId}`, request);
        if (Math.floor(response.status / 100) === 2) {
            return null;
        }

        const errorMessage = response.serverError?.error.message ?? "Company could not be updated";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}

export const getCompanyWallet = async (companyId: string): Promise<ICompanyWallet | BoothXError> => {
    try {
        const response = await get<ICompanyWalletResponse>(`${BACKEND_URL_V1}/companies/${companyId}/wallet`);
        if (Math.floor(response.status / 100) === 2 && response.parsedBody) {
            return response.parsedBody?.wallet;
        }

        const errorMessage = response.serverError?.error.message ?? "Company could not be updated";
        return new BoothXError(errorMessage);
    } catch (err) {
        console.log(err);
        return new BoothXError("Something went wrong");
    }
}
