import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import GenericTable from "../../components/genericTable/GenericTable";
import { ToastContext } from "../../contexts/ToastContext";
import { BoothXError } from "../../models/BoothXError";
import { IDocumentView } from "../../models/Document";
import { getDocumentDownloadUrl, getDocuments, uploadDocument } from "../../api/DocumentApis";
import { DateTime } from "luxon";
import { bytesToSize } from "../../utils/Utils";
import DeleteDocumentConfirmationDialog from "../../components/deleteDocumentConfirmationDialog/DeleteDocumentConfirmationDialog";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useDropzone } from 'react-dropzone';
import { Loading } from "../../components/loading/Loading";
import { canUserAddOrUpdateDocuments } from "../../utils/AuthUtils";

const DocumentsPage: React.FC = React.memo(() => {
    const [documents, setDocuments] = useState<IDocumentView[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isUploadingDocument, setIsUploadingDocument] = useState<boolean>(false);
    const [isDownloadingDocumentLoading, setIsDownloadingDocument] = useState<boolean>(false);
    const [selectedDocument, setSelectedDocument] = useState<IDocumentView>();
    const [showDeleteDocumentConfirmationDialog, setShowDeleteDocumentConfirmationDialog] = useState<boolean>(false);
    const { showToast } = useContext(ToastContext);
    const allowAddAndUpdateDocument = canUserAddOrUpdateDocuments();

    const fetchDocuments = useCallback(async () => {
        setIsLoading(true);
        const response = await getDocuments();
        setIsLoading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        const sortedDocuments = response.sort((a, b) => { return a.name.localeCompare(b.name) });
        setDocuments(sortedDocuments);
    }, [showToast]);

    const handleDeleteDocumentConfirmationDialogClose = (didDeleteDocument: boolean) => {
        setShowDeleteDocumentConfirmationDialog(false);
        setSelectedDocument(undefined);
        if (didDeleteDocument) {
            fetchDocuments();
        }
    }

    const handleDownloadDocument = async (documentId: string) => {
        setIsDownloadingDocument(true);
        const response = await getDocumentDownloadUrl(documentId);
        setIsDownloadingDocument(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        window.open(response, "_blank");
    }

    const { open } = useDropzone({
        accept: {
            'application/pdf': ['.pdf'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'video/mp4': ['.mp4'],
            'video/quicktime': ['.mov'],
        },
        onDropAccepted: (acceptedFiles) => handleAddDocument(acceptedFiles[0]),
        onDropRejected: () => showToast("File size cannot be more than 24 MB", "error"),
        multiple: false,
        maxSize: 24 * 1024 * 1024,
    });

    const handleAddDocument = async (file: File) => {
        setIsUploadingDocument(true);
        const response = await uploadDocument(file);
        setIsUploadingDocument(false);
        if (response instanceof BoothXError) {
            showToast(response.message, "error");
            return;
        }

        fetchDocuments();
    }

    useEffect(() => {
        fetchDocuments();
    }, [fetchDocuments]);

    return (
        <Box sx={{ padding: 3 }}>
            {showDeleteDocumentConfirmationDialog && selectedDocument && <DeleteDocumentConfirmationDialog document={selectedDocument} onClose={handleDeleteDocumentConfirmationDialogClose} />}
            <Box
                component="div"
                sx={{
                    mb: 2,
                    display: "flex",
                    justifyContent: { xs: "flex-end", sm: "space-between" },
                }}
            >
                <Typography
                    component="h1"
                    variant="h6"
                    sx={{
                        textAlign: "left",
                        fontWeight: "600",
                        display: { xs: "none", sm: "block" } // hide on mobile
                    }}
                >
                    All Collaterals / Documents
                </Typography>
                {allowAddAndUpdateDocument && (
                    <Button
                        aria-label="add"
                        variant="contained"
                        onClick={open}
                        disabled={isUploadingDocument}
                    >
                        <Loading isLoading={isUploadingDocument} text="Add collateral / document" />
                    </Button>
                )}
            </Box>
            <Grid container spacing={2}>
                <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    <GenericTable
                        data={documents}
                        headers={[
                            { header: "Name" },
                            { header: "Type" },
                            { header: "Date Uploaded" },
                            { header: "Size" },
                            { header: "Actions" },
                        ]}
                        isLoading={isLoading}
                        dataRenderer={(documentRow, column) => {
                            switch (column) {
                                case 0:
                                    return documentRow.name;
                                case 1:
                                    return documentRow.type.toUpperCase();
                                case 2:
                                    return DateTime.fromSeconds(documentRow.creationTimeInUTC).toLocaleString(DateTime.DATE_MED);
                                case 3:
                                    return bytesToSize(documentRow.sizeInBytes);
                                case 4:
                                    return (
                                        <>
                                            <IconButton
                                                aria-label="download"
                                                color="primary"
                                                size="small"
                                                onClick={() => {
                                                    setSelectedDocument(documentRow);
                                                    handleDownloadDocument(documentRow.id);
                                                }}
                                                disabled={isDownloadingDocumentLoading && selectedDocument?.id === documentRow.id}
                                            >
                                                {isDownloadingDocumentLoading && selectedDocument?.id === documentRow.id && <Loading isLoading={true} />}
                                                {(!isDownloadingDocumentLoading || (isDownloadingDocumentLoading && selectedDocument?.id !== documentRow.id)) && <DownloadOutlinedIcon />}
                                            </IconButton>
                                            {allowAddAndUpdateDocument && (
                                                <IconButton
                                                    aria-label="delete"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => {
                                                        setSelectedDocument(documentRow);
                                                        setShowDeleteDocumentConfirmationDialog(true);
                                                    }}
                                                >
                                                    <DeleteOutlineOutlinedIcon />
                                                </IconButton>
                                            )}
                                        </>
                                    );
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
});

export default DocumentsPage;
