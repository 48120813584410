import { Capability } from "../models/Capability";
import { Auth } from "./Auth";

export function canUserCreateEvent() {
    return Auth.getInstance().isAdminOrSuperAdmin()
        || Auth.getInstance().containsCapability(Capability.CREATE_EVENT);
}

export function canUserManageEvent() {
    return Auth.getInstance().isAdminOrSuperAdmin()
        || Auth.getInstance().containsCapability(Capability.MANAGE_ALL_EVENTS)
        || Auth.getInstance().containsCapability(Capability.MANAGE_EVENT);
}

export function canUserAddOrUpdateUsers() {
    return Auth.getInstance().isAdminOrSuperAdmin()
        || Auth.getInstance().containsCapability(Capability.USER_MANAGEMENT);
}

export function canUserAddOrUpdateDocuments() {
    return Auth.getInstance().isAdminOrSuperAdmin()
        || Auth.getInstance().containsCapability(Capability.MANAGE_DOCUMENTS);
}

export function canUserAddOrUpdateEmailTemplates() {
    return Auth.getInstance().isAdminOrSuperAdmin()
        || Auth.getInstance().containsCapability(Capability.MANAGE_EMAIL_TEMPLATES);
}

export function canUserAddOrUpdateOrDeleteExpenses() {
    return Auth.getInstance().isAdminOrSuperAdmin()
        || Auth.getInstance().containsCapability(Capability.MANAGE_ALL_EXPENSES)
        || Auth.getInstance().containsCapability(Capability.MANAGE_EXPENSES);
}

export function canUserAddOrUpdateOrDeleteTasks() {
    return Auth.getInstance().isAdminOrSuperAdmin()
        || Auth.getInstance().containsCapability(Capability.MANAGE_TASKS);
}

export function canUserUpdateCompanyProfile() {
    return Auth.getInstance().isAdminOrSuperAdmin();
}

export function canUserAddFunds() {
    return Auth.getInstance().isAdminOrSuperAdmin();
}
