import { EventPlanType } from "../models/Billing";


export const displayNameForPlan = (eventPlanType: EventPlanType) => {
    switch (eventPlanType) {
        case EventPlanType.TRIAL:
            return "Free";
        case EventPlanType.STARTER:
            return "Starter";
        case EventPlanType.PRO:
            return "Professional";
        case EventPlanType.ENTERPRISE:
            return "Enterprise";
        default:
            return "";
    }
}
