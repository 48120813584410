export interface IBoothXServerError {
    error: IBoothXServerErrorDetails;
}

export interface IBoothXServerErrorDetails {
    message: string;
    errorCode?: ErrorCode;
}

export enum ErrorCode {
    ENTITY_NOT_FOUND = "ENTITY_NOT_FOUND"
}
