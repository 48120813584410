import { useCallback, useContext, useState } from "react";
import GenericDialog from "../genericDialog/GenericDialog";
import { ToastContext } from "../../contexts/ToastContext";
import { BoothXError } from "../../models/BoothXError";
import { IEventView, ITaskView } from "../../models/Event";
import { deleteTask } from "../../api/EventsApi";

interface IProps {
    event: IEventView;
    task: ITaskView;
    isSubTask: boolean;
    onClose: (didDeleteTask: boolean) => void;
}

const DeleteTaskConfirmationDialog: React.FC<IProps> = (props) => {
    const { event, task, isSubTask, onClose } = props;
    const [isLoading, setIsLoading] = useState(false);
    const { showToast } = useContext(ToastContext);

    const onDeleteTask = useCallback(async () => {
        setIsLoading(true);
        const response = await deleteTask(event.id, task.id);
        setIsLoading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        showToast("Task deleted successfully", "success");
        onClose(true);
    }, [showToast, onClose, task.id, event.id]);

    return (
        <GenericDialog
            isOpen={true}
            onClose={() => {
                onClose(false);
            }}
            title={`Delete ${isSubTask ? "Task" : "Deliverable"}`}
            primaryButtonText={"Delete"}
            primaryButtonAction={onDeleteTask}
            primaryButtonLoading={isLoading}
        >
            Are you sure you want to delete "{task.description}"?
        </GenericDialog>
    )
}

export default DeleteTaskConfirmationDialog;
