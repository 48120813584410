export enum DashboardStatsType {
    ALL_EVENTS = "ALL_EVENTS",
    ASSIGNED_EVENTS = "ASSIGNED_EVENTS",
    SELF = "SELF"
}

export interface IDashboardStats {
    totalEvents: number;
    totalLeads: number;
    totalExpenses: number;
    type: DashboardStatsType;
    totalExpensesMap: Map<string, number>;
    salesmenStats?: ISalesmanStatsView[];
    expensesPerCategory?: Map<string, number>;
    homeCurrency: string;
    overallCostPerLead: number;
    eventsStats?: IEventStatsView[];
    averageLeadsPerEvent: number;
    averageDailyLeads?: number;
}

export interface IGetDashboardStatsResponse {
    stats: IDashboardStats;
    lastUpdated: number;
}

export interface ISalesmanStatsView {
    userId: string;
    name: string;
    totalLeads: number;
    totalExpenses: number;
    expenseCurrency: string;
}

export interface IEventStatsView {
    eventId: string;
    eventName: string;
    costPerLead: number;
    duplicateLeadsCount: number;
    leadsCount: number;
}
