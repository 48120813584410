import { Box, Button, Chip, Grid, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import AddFundsDialog from "../../components/addFundsDialog/AddFundsDialog";
import { canUserAddFunds } from "../../utils/AuthUtils";
import GenericTable from "../../components/genericTable/GenericTable";
import { displayAmountWithCurrency } from "../../utils/Utils";
import { DateTime } from "luxon";
import { BoothXError } from "../../models/BoothXError";
import { getBillingHistory } from "../../api/Billing";
import { ToastContext } from "../../contexts/ToastContext";
import { EventPlanType, IBillingItem, PaymentSessionStatus, PaymentSessionTargetAction } from "../../models/Billing";
import { displayNameForPlan } from "../../utils/BillingUtils";
import { ICompanyWallet } from "../../models/Company";
import { getCompanyWallet } from "../../api/CompanyApi";
import { Auth } from "../../utils/Auth";
import { getCurrencySymbol } from "../../utils/DisplayUtils";
import { Loading } from "../../components/loading/Loading";
import { useLocalStorage } from "../../hooks/LocalStorageHook";
import CountingAnimation from "./CountingAnimation";

function getBillingItemType(billingItem: IBillingItem) {
    if (billingItem.paymentSessionTargetAction === PaymentSessionTargetAction.ADD_FUNDS) {
        return "Funds added to wallet";
    }

    return `Event purchased - ${displayNameForPlan(billingItem.planType)}`;
}

const BillingPage: React.FC = React.memo(() => {
    
    const { showToast } = useContext(ToastContext);
    
    const [showAddFundsDialog, setShowAddFundsDialog] = useState(false);
    const [billingItems, setBillingItems] = useState<IBillingItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingWallet, setIsFetchingWallet] = useState(false);
    const [wallet, setWallet] = useState<ICompanyWallet>();

    const [oldWalletBalance, setOldWalletBalance] = useLocalStorage<number>("oldWalletBalance", 0);
    const [shouldShowCountingAnimation, setShouldShowCountingAnimation] = useState(false);

    const allowAddFunds = canUserAddFunds();

    const fetchBillingHistory = useCallback(async () => {
        setIsLoading(true);
        const response = await getBillingHistory();
        setIsLoading(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }

        setBillingItems(response.billingItems);
    }, [showToast]);

    const fetchCompanyWallet = useCallback(async () => {
        const companyId = Auth.getInstance().getUserSettings()?.companyView.id ?? "";
        setIsFetchingWallet(true);
        const response = await getCompanyWallet(companyId);
        setIsFetchingWallet(false);
        if (response instanceof BoothXError) {
            showToast(response.getErrorMessage(), "error");
            return;
        }
        setWallet(response);
    }, [showToast]);

    useEffect(() => {
        if (wallet) {
            if (wallet.balance !== oldWalletBalance) {
                setShouldShowCountingAnimation(true);
            }
            setOldWalletBalance(wallet.balance);
        }
    }, [wallet, oldWalletBalance, setOldWalletBalance])

    useEffect(() => {
        fetchBillingHistory();
        fetchCompanyWallet();
    }, [fetchBillingHistory, fetchCompanyWallet]);
    
    const getChipForPaymentStatus = (paymentStatus: PaymentSessionStatus) => {
        let chipColor: "success" | "error" | "default" | "warning" | "info" | "primary" | "secondary" | undefined = 'default';
        const chipStyle = { ml: 1, width: 120 };

        switch (paymentStatus) {
            case PaymentSessionStatus.EXPIRED:
                chipColor = 'warning';
                break;
            case PaymentSessionStatus.COMPLETED:
                chipColor = 'success';
                break;
            case PaymentSessionStatus.INITIATED:
                chipColor = 'info';
                break;
            case PaymentSessionStatus.CANCELLED:
                chipColor = 'error';
                break;
            default:
                chipColor = 'default';
                break;
        }
        return <Chip sx={chipStyle} label={paymentStatus} color={chipColor} />;
    }

    return (
        <Box sx={{ padding: 3 }}>
            <Typography
                component="h1"
                variant="h6"
                sx={{
                    mb: 2,
                    textAlign: "left",
                    fontWeight: "600",
                    display: { xs: "none", sm: "block" } // hide on mobile
                }}
            >
                Billing History
            </Typography>
            
            <Box 
                display={"flex"} justifyContent={"space-between"} alignItems={"center"} px={3} 
                sx={{
                    height: 100,
                    background: "linear-gradient(90deg, #EC1B69 0%, #F05B57 100%)",
                    borderRadius: 2
                }}
            >
                <AddFundsDialog 
                    isOpen={showAddFundsDialog} 
                    onClose={() => setShowAddFundsDialog(false)}
                />

                <Box color={"#ffffff"}>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Box>
                            <img height={40} width={40} src={"/assets/wallet.svg"} alt="Wallet Icon" />
                        </Box>
                        <Box
                            ml={2}
                            sx={{
                                textAlign: "left"
                            }}
                        >
                            <>
                                {!isFetchingWallet 
                                    ? wallet && (
                                        <Typography variant="h5">
                                            {getCurrencySymbol(wallet.baseCurrencyCode) + " "} 
                                            {shouldShowCountingAnimation
                                                ? <CountingAnimation initialNumber={0} targetNumber={wallet.balance} />
                                                : wallet.balance
                                            }
                                        </Typography>) 
                                    : (<Loading isLoading={true} />)
                                }
                            </>
                            <Typography>Current Balance</Typography>
                        </Box>
                    </Box>
                </Box>
                
                <Box>
                    {allowAddFunds && (
                        <Button
                            aria-label="add"
                            variant="contained"
                            onClick={() => {setShowAddFundsDialog(true)}}
                        >
                            + Add Money to Wallet
                        </Button>
                    )}
                </Box>
            </Box>

            <Grid container spacing={2} mt={3}>
                <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    <GenericTable
                        data={billingItems}
                        headers={[
                            { header: "Date" },
                            { header: "Amount" },
                            { header: "Type" },
                            { 
                                header: "Status",
                                filter: {
                                    filterValues: Object.values(PaymentSessionStatus),
                                    isValid: (billingItemRow: IBillingItem, selectedFilter: string) => billingItemRow.paymentStatus === selectedFilter
                                }
                            },
                            { header: "User" }
                        ]}
                        isLoading={isLoading}
                        dataRenderer={(billingItemRow, column) => {
                            switch (column) {
                                case 0:
                                    return DateTime.fromSeconds(billingItemRow.creationTime).toLocaleString(DateTime.DATE_MED);
                                case 1:
                                    if (billingItemRow.planType === EventPlanType.TRIAL) {
                                        return "N/A";
                                    }
                                    return displayAmountWithCurrency(billingItemRow.amountTotal, billingItemRow.currency);
                                case 2:
                                    return getBillingItemType(billingItemRow);
                                case 3:
                                    return getChipForPaymentStatus(billingItemRow.paymentStatus);
                                case 4:
                                    return billingItemRow.createdByUserDisplayName;
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
});

export default BillingPage;